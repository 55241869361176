<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form name="form" #form="ngForm" (ngSubmit)="onConnexion(form)" class="md-float-material">
            <div class="text-center ogo-logo-login">
              <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">
                <img src="assets/images/logo.png" alt=".OGO Security" />
              </a>
            </div>
            <div class="auth-box">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary">{{ 'Login' | translate }}</h3>
                </div>
              </div>
              <!-- <hr/> -->
              <div class="input-group">
                <input name="email" type="email" ngModel class="form-control" placeholder="Email" />
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <input
                  name="password"
                  type="password"
                  ngModel
                  class="form-control"
                  [placeholder]="'Password' | translate"
                />
                <span class="md-line"></span>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">
                    {{ 'Login' | translate }}
                  </button>
                </div>
              </div>
              <div class="row text-left">
                <div class="col-12">
                  <div class="forgot-phone text-right f-right">
                    <a [routerLink]="['/' + AUTH_PWD_FORGOT_ROUTE]" class="text-right f-w-600 text-inverse">
                      {{ 'ForgotPassword' | translate }} ?</a
                    >
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left m-b-0">
                    <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">www.ogosecurity.com</a>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
