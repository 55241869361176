import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, SHARE_ACCESS_RIGHT_ADMIN, SHARE_ACCESS_RIGHT_VIEW } from '../../../services/auth.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { debounceTime, firstValueFrom, fromEvent } from 'rxjs';

@Component({
  selector: 'app-received-shares',
  templateUrl: './received-shares.component.html',
  styleUrls: ['./received-shares.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class ReceivedSharesComponent implements OnInit {
  lang: string;

  @ViewChild('search') search: ElementRef;

  shares = { content: [], page: 0, size: 10, total: 0 };
  delegations = { content: [], page: 0, size: 10, total: 0 };

  SHARE_ACCESS_RIGHT_ADMIN = SHARE_ACCESS_RIGHT_ADMIN;
  SHARE_ACCESS_RIGHT_VIEW = SHARE_ACCESS_RIGHT_VIEW;

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    public translate: TranslateService,
    private toastr: ToastrService,
  ) {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnInit() {
    this.getReceivedShares(this.shares.page);
    this.getReceivedDelegations(this.delegations.page);
  }

  ngAfterViewInit() {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(debounceTime(500))
      .subscribe((evt: any) => {
        this.getReceivedShares(this.shares.page, evt.target.value);
      });
  }

  async getReceivedShares(page, searchTerm = '') {
    let url = `v2/organizations/${this.auth.currentOrganization.id}/site-shares/received?page=${page}&size=${this.shares.size}`;
    if (searchTerm) url += `&filter=${searchTerm}`;
    const res: any = await firstValueFrom(this.http.get(url));
    this.shares.content = res.content;
    this.shares.total = res.totalElements;
  }

  async getReceivedDelegations(page) {
    const res: any = await firstValueFrom(
      this.http.get(
        `v2/organizations/${this.auth.currentOrganization.id}/cluster-delegations/received?page=${page}&size=${this.delegations.size}`,
      ),
    );
    this.delegations.content = res.content;
    this.delegations.total = res.totalElements;
  }

  changeSharesPage(e) {
    this.shares.page = e.page - 1;
    this.getReceivedShares(this.shares.page);
  }

  changeDelegationsPage(e) {
    this.delegations.page = e.page - 1;
    this.getReceivedDelegations(this.delegations.page);
  }

  showModalDeleteShare(item) {
    return Swal.fire({
      html: this.translate.instant('DeleteShare', { item: item.site.name }),
      ...this.modalConf(),
    } as SweetAlertOptions).then(async (result) => {
      if (result.value) {
        await this.deleteShare(item);
        await this.getReceivedShares(this.shares.page);
        this.toastr.success(this.translate.instant('OperationSuccess'));
      }
    });
  }

  async deleteShare(item) {
    return await firstValueFrom(
      this.http.delete(`v2/organizations/${this.auth.currentOrganization.id}/site-shares/received/${item.site.name}`),
    );
  }

  showModalDeleteDelegation(item) {
    return Swal.fire({
      html: this.translate.instant('DeleteDelegation', { item: item.cluster.name }),
      ...this.modalConf(),
    } as SweetAlertOptions).then(async (result) => {
      if (result.value) {
        await this.deleteDelegation(item);
        await this.getReceivedDelegations(this.delegations.page);
        this.auth.currentOrganization.clusters = (await this.auth.getOrganizationClusters(
          this.auth.currentOrganization.id,
        )) as any;
        this.toastr.success(this.translate.instant('OperationSuccess'));
      }
    });
  }

  async deleteDelegation(item) {
    return await firstValueFrom(
      this.http.delete(
        `v2/organizations/${this.auth.currentOrganization.id}/cluster-delegations/received/${item.cluster.clusterId}`,
      ),
    );
  }

  modalConf() {
    return {
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    };
  }
}
