import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from '../../../services/auth.service';
import { validatePasswordRestrictions } from '../../../shared/validators/validators';

@Component({
  selector: 'app-my-parameters',
  templateUrl: './my-parameters.component.html',
  styleUrls: ['./my-parameters.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class MyParametersComponent implements OnInit {
  lang: string;

  @ViewChild('profileForm') public editProfileForm: NgForm;

  user: any = {};
  editField: boolean;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnInit() {
    this.getUser();
    this.editField = false;
  }

  onEditField = () => {
    this.editField = true;
  };

  updateUser = () => {
    const data = { ...this.user };
    data.mfa = this.user.emailAuthentication ? 'email' : 'none';
    delete data.emailAuthentication;

    this.http.put('v2/my-profile', data).subscribe({
      next: (res) => {
        this.getUser();
        this.toastr.success(this.translate.instant('SuccfChang'));
        this.editField = false;
      },
      error: (err) => {
        this.toastr.error(this.translate.instant('VeuillezRenseignerTousLesChamps'));
      },
    });
  };

  cancelEditUser = () => {
    this.getUser();
    this.editField = false;
  };

  getUser() {
    const user = this.http.get<any>('v2/my-profile').subscribe((user) => {
      this.user = {
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
        emailAuthentication: user.mfa == 'email',
        lang: user.lang,
      };
      // this.hasApiKey = user.hasApiKey;
      // this.apiKey = user.hasApiKey ? '●●●●●●●' : '';
      this.auth.updateUser({
        ...user,
        nom: user.lastName,
        prenoms: user.firstName,
        lang: this.lang,
      });
    });
  }
}
