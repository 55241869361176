import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { getPageParams, PageRequest, SpringPage } from 'app/shared/page';
import { Contract, ContractCluster, ContractOrganization, ContractSite } from './entities/contract';

@Injectable({
  providedIn: 'root',
})
export class AdminContractService {
  constructor(private http: HttpClient) {}

  async getContracts(searchTerm: string, page: PageRequest): Promise<SpringPage<Contract>> {
    let params = getPageParams(page);
    if (searchTerm && searchTerm !== '') params = params.set('filter', searchTerm);

    return firstValueFrom(this.http.get<SpringPage<Contract>>(`admin/contracts`, { params }));
  }

  async getContract(contractId: number): Promise<Contract> {
    return firstValueFrom(this.http.get<Contract>(`admin/contracts/${contractId}`));
  }

  async deleteContract(contractId) {
    return firstValueFrom(this.http.delete(`admin/contracts/${contractId}`));
  }

  addContract(contract: ContractRequest) {
    return this.http.post<Contract>(`admin/contracts`, contract);
  }

  updateContract(id: number, contract: ContractRequest) {
    return this.http.put(`admin/contracts/${id}`, contract);
  }

  // Sites

  sites(contractId: number) {
    return new CollectionApi<ContractSite>(this.http, `admin/contracts/${contractId}/sites`);
  }

  // Clusters

  clusters(contractId: number) {
    return new CollectionApi<ContractCluster>(this.http, `admin/contracts/${contractId}/clusters`);
  }
  // Organizations

  organizations(contractId: number) {
    return new CollectionApi<ContractOrganization>(this.http, `admin/contracts/${contractId}/organizations`);
  }
}

export class CollectionApi<T> {
  constructor(
    private http: HttpClient,
    private rootUri: string,
  ) {}

  getPage(page: PageRequest): Promise<SpringPage<T>> {
    return firstValueFrom(this.http.get<SpringPage<T>>(this.rootUri, { params: getPageParams(page) }));
  }

  add(item: string) {
    return firstValueFrom(this.http.put(`${this.rootUri}/${item}`, null));
  }

  remove(item: string) {
    return firstValueFrom(this.http.delete(`${this.rootUri}/${item}`));
  }
}

export interface ContractRequest {
  number: string;
  holder: {
    code: string;
  };
  startDate?: Date | string;
  endDate?: Date | string;

  millionRequestsPerMonth?: number;
  nbSites?: number;
}
