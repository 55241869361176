import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import _ from 'lodash';

@Directive({
  selector: '[appAccess]',
  standalone: false,
})
export class AccessDirective implements OnInit {
  @Input() appAccess: string[] = [];

  constructor(
    private el: ElementRef,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    const user = this.auth.getUser();
    // Item is hidden by default
    this.el.nativeElement.style.display = 'none';

    // If no ACL, show always
    if (!this.appAccess || this.appAccess.length === 0) {
      this.el.nativeElement.style.display = 'inherit';
      return;
    }

    let fonctionnalites = _.map(user.datasFonctionnalite, (p) => p.code) || [];

    // If ACL found, show
    for (const item of fonctionnalites) {
      if (_.indexOf(this.appAccess, item) > -1) {
        this.el.nativeElement.style.display = 'inherit';
        break;
      }
    }
  }
}
