import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ADMIN_CLUSTER_DELEGATION_SUFFIX,
  ADMIN_CLUSTER_IA_PARAMETERS_SUFFIX,
  ADMIN_CLUSTER_MAINTENANCE_SUFFIX,
  ADMIN_CLUSTER_METRICS_SUFFIX,
  ADMIN_CLUSTER_PREFIX,
  ADMIN_CLUSTER_SITES_SUFFIX,
  ADMIN_PREFIX,
  API_DOC_ROUTE,
  DASHBOARD_ROUTE,
  JOURNAL_ROUTE,
  MY_ACCOUNT_PREFIX,
  MY_LOGS_ROUTE,
  MY_ORGANIZATION_SUFFIX,
  MY_PROFILE_SUFFIX,
  MY_SITES_ROUTE,
} from 'app/app-routing.module';
import {
  CLUSTERS_ROUTE,
  CLUSTER_TYPE_ROUTE,
  ELASTICSEARCH_ROUTE,
  IA_RECOMMENDED_PARAMETERS_ROUTE,
  ORGANIZATIONS_ROUTE,
  SITES_ROUTE,
  USERS_ROUTE,
  CONTRACTS_ROUTE,
} from 'app/theme/admin/admin-routing.module';
import { Features, SHOW_ADMIN, SHOW_ADMIN_CLUSTER, SHOW_API_DOC } from 'app/services/auth.service';

export interface BadgeItem {
  type: string;
  value: string;
}

interface BaseItem {
  name: string;
  state: string;
  target?: boolean;
  acl?: Features[];
  beta?: boolean;
  badge?: BadgeItem[];
}

export interface ChildrenItems extends BaseItem {
  type?: string;
  children?: ChildrenItems[];
  icon?: string;
}

export interface MainMenuItems extends BaseItem {
  main_state?: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
  href?: string;
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

@Injectable()
export class MenuItems {
  MENUITEMS: Menu[];

  constructor(private translate: TranslateService) {
    this.MENUITEMS = [
      {
        label: 'Navigation',
        main: [
          {
            state: DASHBOARD_ROUTE,
            name: 'TableauDeBord',
            type: 'link',
            icon: 'icon-layout',
          },
          {
            state: MY_LOGS_ROUTE,
            name: 'Tracker',
            type: 'link',
            icon: 'icon-search',
          },
          {
            state: MY_SITES_ROUTE,
            name: 'MySites',
            type: 'link',
            icon: 'icon-settings',
          },
          {
            state: MY_ACCOUNT_PREFIX,
            name: 'MyAccount',
            type: 'sub',
            icon: 'icon-user',
            children: [
              {
                state: MY_PROFILE_SUFFIX,
                name: 'MyProfile',
              },
              {
                state: MY_ORGANIZATION_SUFFIX,
                name: 'MyOrganization',
              },
            ],
          },
          {
            state: JOURNAL_ROUTE,
            name: 'Journal',
            type: 'link',
            beta: true,
            icon: 'icon-agenda',
          },
          {
            state: API_DOC_ROUTE,
            name: 'API Doc',
            type: 'link',
            acl: [SHOW_API_DOC],
            icon: 'icon-book',
          },
          {
            state: ADMIN_PREFIX,
            name: 'admin',
            type: 'sub',
            acl: [SHOW_ADMIN],
            icon: 'icofont icofont-settings-alt',
            children: [
              {
                state: ORGANIZATIONS_ROUTE,
                name: 'Organizations',
              },
              {
                state: USERS_ROUTE,
                name: 'utilisateurs',
              },
              {
                state: CONTRACTS_ROUTE,
                name: 'Contracts',
              },
              {
                state: CLUSTERS_ROUTE,
                name: 'Clusters',
              },
              {
                state: CLUSTER_TYPE_ROUTE,
                name: 'ClusterType',
              },
              {
                state: ELASTICSEARCH_ROUTE,
                name: 'Elasticsearchs',
              },
              {
                state: IA_RECOMMENDED_PARAMETERS_ROUTE,
                name: 'RecommendedParameters',
              },
              {
                state: SITES_ROUTE,
                name: 'site',
              },
            ],
          },
          {
            state: ADMIN_CLUSTER_PREFIX,
            name: 'Admin cluster',
            type: 'sub',
            acl: [SHOW_ADMIN_CLUSTER],
            icon: 'icon-server',
            children: [
              {
                state: DASHBOARD_ROUTE,
                name: 'TableauDeBord',
                type: 'link',
                icon: 'icon-home',
              },
              {
                state: MY_LOGS_ROUTE,
                name: 'Logs',
                type: 'link',
                icon: 'icon-search',
              },
              {
                state: ADMIN_CLUSTER_SITES_SUFFIX,
                name: 'Sites',
              },
              {
                state: ADMIN_CLUSTER_DELEGATION_SUFFIX,
                name: 'Delegation',
              },
              {
                state: JOURNAL_ROUTE,
                name: 'Journal',
              },
              {
                state: ADMIN_CLUSTER_METRICS_SUFFIX,
                name: 'Metrics',
              },
              {
                state: ADMIN_CLUSTER_IA_PARAMETERS_SUFFIX,
                name: 'IAParameters',
                acl: [SHOW_ADMIN],
              },
              {
                state: ADMIN_CLUSTER_MAINTENANCE_SUFFIX,
                name: 'Maintenance',
                acl: [SHOW_ADMIN],
              },
            ],
          },
        ],
      },
    ];
  }

  getAll() {
    return this.MENUITEMS;
  }
}
