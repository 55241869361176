<div class="row mb-2">
  <h5 class="col-auto">{{ 'Contracts' | translate }}</h5>
</div>

<div class="row">
  <div class="col">
    <ngx-datatable
      class="data-table table-responsive task-list-table"
      [rows]="data?.content"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="10"
      [rowHeight]="40"
    >
      <ngx-datatable-column [flexGrow]="2" name="name">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Contract.number' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.number }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="2" name="name">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Contract.holder' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.holder.companyName }}
          <app-badge-organization [code]="row.holder.code"></app-badge-organization>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="3" name="startDate">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Contract.startDate' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.startDate | date }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="3" name="endDate">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Contract.endDate' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.endDate | date }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
          <app-table-pager [data]="data" [page]="page" [changePage]="changePage"></app-table-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>
