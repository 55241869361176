import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Highcharts from 'highcharts';
import moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { DATE_NOW } from '../../date-range-selector/calendar-data.service';
import { DateRange } from 'app/shared/utils/date-range';

export interface ClickEvent {
  category: string;
  slug: any;
}

@Component({
  selector: 'app-graph',
  templateUrl: './graph.html',
  styleUrls: ['./graph.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class GraphComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') chart: ElementRef;

  @Input() fontSize: string = '14px';
  @Input() height: string = '400px';
  @Input() exportButtonSpacing = null;
  @Input() data: any = {};
  @Input() cursor: string = null;

  @Output() zoneSelected = new EventEmitter<DateRange>();
  @Output() columnClicked = new EventEmitter<ClickEvent>();
  @Output() isRendered = new EventEmitter<void>();

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.drawChart(this.data);
    }
  }

  ngAfterViewInit() {
    this.drawChart(this.data);
  }

  columnClick = (event: any) => {
    // for unidentified reason, custom for spline in on event.point, and for column on event.point.series.options
    this.columnClicked.emit({
      category: event?.point?.custom?.category ?? event.point.series.options.custom.category,
      slug: event?.point?.custom?.slug ?? event.point.series.options.custom.slug ?? event.point.y,
    });
  };

  drawChart(data: any) {
    if (!this.chart?.nativeElement || !data?.chart) return;

    data = this.setGlobalOptions(data);

    Highcharts.chart(data);
  }

  toMoment(x) {
    return moment(Math.round(JSON.parse(JSON.stringify(x))));
  }

  setGlobalOptions(data) {
    data.chart.renderTo = this.chart.nativeElement;

    data.chart.events = {
      selection: (event) => {
        if (event.xAxis) {
          this.zoneSelected.emit(
            new DateRange(
              this.toMoment(event.xAxis[0].min),
              moment.min([DATE_NOW(), this.toMoment(event.xAxis[0].max)]),
            ),
          );
        }
        return false; // import, disable natural zooming
      },
      render: () => {
        this.isRendered.emit();
      },
      load: drawVerticalBar,
    };

    if (data?.chart?.type) {
      data.plotOptions[data.chart.type].cursor = this.cursor || 'pointer';
      data.plotOptions[data.chart.type].events = {
        click: this.columnClick,
      };
    }

    if (data?.exporting?.buttons?.contextButton && this.exportButtonSpacing) {
      data.exporting.buttons.contextButton.x = this.exportButtonSpacing.x;
      data.exporting.buttons.contextButton.y = this.exportButtonSpacing.y;
    }

    data.lang = {
      noData: this.translate.instant('NoData'),
      printChart: this.translate.instant('PrintChart'),
      downloadPNG: this.translate.instant('DownloadPNG'),
      downloadJPEG: this.translate.instant('DownloadJPEG'),
      downloadSVG: this.translate.instant('DownloadSVG'),
      downloadCSV: this.translate.instant('DownloadCSV'),
      downloadXLS: this.translate.instant('DownloadXLS'),
    };

    Highcharts.setOptions({
      lang: {
        weekdays: this.translate.instant('Calendar.weekDays'),
        months: this.translate.instant('Calendar.months'),
        shortMonths: this.translate.instant('Calendar.months.short'),
        shortWeekdays: this.translate.instant('Calendar.weekDays.short'),
      },
    });

    if (data?.title?.style) {
      data.title.style = {
        fontSize: this.fontSize,
      };
    }

    return data;
  }
}

function drawVerticalBar() {
  const chart = this;

  // Créer le crosshair en dehors de la zone visible
  chart.customCrosshair = chart.renderer
    .path(['M', -9999, 0, 'L', -9999, chart.plotHeight])
    .attr({
      'stroke-width': 2,
      stroke: '#dbd9da',
      zIndex: 0,
    })
    .add();

  let crosshairInitialized = false; // Pour vérifier si le crosshair a été déjà activé

  Highcharts.addEvent(chart.container, 'mousemove', function (e) {
    const x = chart.pointer.normalize(e).chartX;

    // Vérifier si la souris est dans la zone du graphique
    if (x >= chart.plotLeft && x <= chart.plotLeft + chart.plotWidth) {
      if (!crosshairInitialized) {
        // Initialiser le crosshair lors du premier mouvement
        crosshairInitialized = true;
      }

      // Mettre à jour la position du crosshair
      chart.customCrosshair.attr({
        d: ['M', x, chart.plotTop, 'L', x, chart.plotTop + chart.plotHeight],
      });
    }
  });

  Highcharts.addEvent(chart.container, 'mouseleave', function () {
    // Retirer le crosshair en le déplaçant hors de la zone visible
    chart.customCrosshair.attr({
      d: ['M', -9999, 0, 'L', -9999, 0],
    });
  });
}
