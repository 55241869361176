import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/services/users.service';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService, CLUSTER_DELEGATION_ADMIN, CLUSTER_DELEGATION_USE } from '../../services/auth.service';
import { Organization } from 'app/services/entities/organization';
import _ from 'lodash';
import { Page } from 'app/shared/page';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { validateEmail } from '../../shared/validators/validators';
import { AdminOrganizationService } from '../../services/admin-organization.service';
import { AdminService } from '../../services/admin.service';
import { Country, CountryService } from '../../services/country.service';
import { Location } from '@angular/common';
import { EditMode } from '../../shared/edit.mode';

@Component({
  selector: 'app-add-edit-organization',
  templateUrl: './add-edit-organization.component.html',
  styleUrls: ['./add-edit-organization.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class AddEditOrganizationComponent implements OnInit {
  @ViewChild('searchUsers') searchUsers: NgSelectComponent;

  fromAdmin = false;
  mode: EditMode;

  organization: Organization;
  countries: Country[] = [];
  clusters: any = [];
  availableClusters: any = [];
  total = 0;
  size = 10;
  page = 0;

  clustersRead = [];
  clustersAdmin = [];

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
    private users: UsersService,
    private adminService: AdminService,
    private adminOrganizationService: AdminOrganizationService,
    private countryService: CountryService,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  async ngOnInit() {
    await this.countryService.init();
    this.countries = this.countryService.getCountries();

    const routeParams = this.route.snapshot.paramMap;
    const organizationCode = routeParams.get('organizationCode');
    if (organizationCode) {
      this.mode = EditMode.UPDATE;
      this.loadOrganization(organizationCode);
    } else {
      this.mode = EditMode.CREATE;
      this.organization = this.emptyOrganization();
    }

    this.route.data.subscribe(async (v) => {
      this.fromAdmin = v.admin ?? false;
      if (this.fromAdmin) {
        combineLatest([
          this.getClusters(),
          this.mode == EditMode.UPDATE ? this.getOrganizationClustersDelegations(organizationCode) : of([]),
        ]).subscribe(([clusters, delegations]) => {
          this.clusters = clusters;
          const delegationsByRole = _.groupBy(delegations, 'role');
          this.clustersRead = delegationsByRole[CLUSTER_DELEGATION_USE] || [];
          this.clustersAdmin = delegationsByRole[CLUSTER_DELEGATION_ADMIN] || [];
          this.filterAvailableCluster();
        });

        if (this.mode == EditMode.UPDATE) {
          this.getOrganizationUsers(organizationCode);
        }
      }
    });
  }

  loadOrganization(organizationCode: string) {
    this.adminOrganizationService.getOrganization$(organizationCode).subscribe((o) => {
      this.organization = o;
    });
  }

  emptyOrganization(): Organization {
    return new Organization({
      companyName: '',
      street: '',
      city: '',
      zipCode: '',
      countryCode: 'FR',
      clusters: [],
      owner: { email: '' },
    });
  }

  changePage(evt) {
    this.page = evt.page - 1;
    this.getOrganizationUsers(this.organization.id);
  }

  async getOrganizationUsers(organizationCode) {
    const res: any = await this.adminOrganizationService.getOrganizationUsers(organizationCode, this.page, this.size);
    this.organization.users = res.content;
    this.organization.users.map(
      (user: any) =>
        (user.role = this.translate.instant(this.users.rolesMap.find((r) => r.value == user.role).libelle)),
    );
    this.total = res.totalElements;
  }

  createOrganization(organization: Organization) {
    if (this.fromAdmin && !validateEmail(organization?.owner?.email)) {
      return this.toastr.error(this.translate.instant('InvalidEmail'));
    }

    const { clusters, ...rest } = organization;

    this.http.post(this.getOrganizationUrl(), this.fromAdmin ? this.createFormatData(organization) : rest).subscribe({
      next: async () => {
        // reload organizations list
        await this.auth.loadOrganizations();
        this.back();
      },
      error: () => {
        this.toastr.error(this.translate.instant('OperationFailed'));
      },
    });
  }

  updateOrganization(organization: any) {
    if (this.fromAdmin && organization?.owner?.email) {
      organization.owner.email = organization.owner.email.trim();
      if (!validateEmail(organization.owner.email)) {
        return this.toastr.error(this.translate.instant('InvalidEmail'));
      }
    }

    this.http.put(this.getOrganizationUrl(organization.id), this.updateFormatData(organization)).subscribe({
      next: () => this.back(),
      error: () => this.toastr.error(this.translate.instant('OperationFailed')),
    });
  }

  impersonate(userId: number) {
    this.adminService.connectAsUser(userId, this.organization.id);
  }

  filterAvailableCluster() {
    const clusterNames = this.clustersRead.concat(this.clustersAdmin).map((c) => c.nom);
    this.availableClusters = this.clusters.filter((c) => !clusterNames.includes(c.nom));
  }

  createFormatData(organization) {
    return {
      ...organization,
      clusters: this.clustersFormData(),
    };
  }

  updateFormatData(organization) {
    let { owner, ...rest } = organization;

    return {
      clusters: this.clustersFormData(),
      ownerEmail: owner.email,
      ...rest,
    };
  }

  clustersFormData() {
    return _.fromPairs(
      _.concat(
        this.clustersRead.map((c) => [c.clusterId, CLUSTER_DELEGATION_USE]),
        this.clustersAdmin.map((c) => [c.clusterId, CLUSTER_DELEGATION_ADMIN]),
      ),
    );
  }

  getOrganizationClustersDelegations(organizationCode): Observable<any[]> {
    return this.http.get<Page<any>>(this.getOrganizationUrl(organizationCode, '/cluster-delegations/received')).pipe(
      map((delegations) =>
        delegations.content.map((delegation) => ({
          id: delegation.cluster.id,
          clusterId: delegation.cluster.clusterId,
          nom: delegation.cluster.name,
          role: delegation.role,
        })),
      ),
    );
  }

  private getOrganizationUrl(organizationCode = null, suffix: string = '') {
    let url = `${this.fromAdmin ? 'admin' : 'v2'}/organizations`;
    if (organizationCode !== null) {
      url += `/${organizationCode}${suffix}`;
    }
    return url;
  }

  getClusters(): Observable<any[]> {
    return this.http.post('cluster/get', { data: {} }).pipe(map((res: any) => res.items));
  }

  back() {
    this.location.back();
  }

  canValidate() {
    return this.organization.companyName && (this.organization.id ? this.organization.owner.email : true);
  }

  protected readonly EditMode = EditMode;
}
