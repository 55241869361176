import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { ClusterTypeComponent } from './cluster-type/cluster-type.component';
import { ClusterComponent } from './cluster/cluster.component';
import { ElasticsearchComponent } from './elasticsearch/elasticsearch.component';
import { SitesComponent } from './sites/sites.component';
import { IaRecommendedParametersComponent } from './ia-recommended-parameters/ia-recommended-parameters.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { UsersComponent } from './users/users.component';
import { ContractsComponent } from './contracts/contracts.component';
import { AddEditContractComponent } from './contracts/add-edit-contract/add-edit-contract.component';
import { TitleResolver } from '../../resolver/title.resolver';
import { AddEditOrganizationComponent } from '../add-organization/add-edit-organization.component';

export const USERS_ROUTE = 'users';
export const ORGANIZATIONS_ROUTE = 'organizations';
export const CONTRACTS_ROUTE = 'contracts';
export const SITES_ROUTE = 'sites';
export const CLUSTERS_ROUTE = 'clusters';
export const CLUSTER_TYPE_ROUTE = 'cluster-type';
export const ELASTICSEARCH_ROUTE = 'elasticsearch';
export const IA_RECOMMENDED_PARAMETERS_ROUTE = 'ia-recommended-parameters';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Administration',
      status: false,
    },
    children: [
      {
        path: USERS_ROUTE,
        component: UsersComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'utilisateurs' },
      },
      {
        path: ORGANIZATIONS_ROUTE,
        component: OrganizationsComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Organizations' },
      },
      {
        path: ORGANIZATIONS_ROUTE + '/add',
        component: AddEditOrganizationComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Organizations', admin: true },
      },
      {
        path: ORGANIZATIONS_ROUTE + '/:organizationCode',
        component: AddEditOrganizationComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Organizations', admin: true },
      },
      {
        path: CONTRACTS_ROUTE,
        component: ContractsComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Contracts' },
      },
      {
        path: CONTRACTS_ROUTE + '/add',
        component: AddEditContractComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Contract.add' },
      },
      {
        path: CONTRACTS_ROUTE + '/:contractId',
        component: AddEditContractComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Contracts' },
      },
      {
        path: SITES_ROUTE,
        component: SitesComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'site' },
      },
      {
        path: CLUSTERS_ROUTE,
        component: ClusterComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Clusters' },
      },
      {
        path: CLUSTER_TYPE_ROUTE,
        component: ClusterTypeComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'ClusterType' },
      },
      {
        path: ELASTICSEARCH_ROUTE,
        component: ElasticsearchComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Elasticsearchs' },
      },
      {
        path: IA_RECOMMENDED_PARAMETERS_ROUTE,
        component: IaRecommendedParametersComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'RecommendedParameters' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
