<small class="ogo-ngx-datatable-footer"> {{ 'TableTotal' | translate: { total: data?.totalElements ?? '' } }}</small>
<datatable-pager
  *ngIf="data"
  [pagerLeftArrowIcon]="'datatable-icon-left'"
  [pagerRightArrowIcon]="'datatable-icon-right'"
  [pagerPreviousIcon]="'datatable-icon-prev'"
  [pagerNextIcon]="'datatable-icon-skip'"
  [page]="page.number + 1"
  [size]="page.size"
  [count]="data.totalElements"
  (change)="changePage($event)"
>
</datatable-pager>
