<div class="card with-tabs p-3">
  <div class="row">
    <div class="col-md-12">
      <h5 class="flex-align-items">
        {{ 'Cache' | translate }}
        <label class="badge badge-sm bg-dark ml-1"
          ><a style="color: inherit" target="_blank" [href]="'ExternalHelp.cache' | translate">?</a></label
        >
      </h5>
      <hr />
      <div *ngIf="disabled" class="bg-blue mb-4" style="margin-top: -10px">
        {{ 'SubscribeToAdvanced' | translate }}
      </div>
      <div [ngStyle]="{ opacity: disabled ? '0.65' : '1' }">
        <div *ngIf="ruleUseCache !== undefined" class="row form-group">
          <label class="col col-form-label">
            {{ 'CacheDesc' | translate }}
            <a class="ogo-link" target="_blank" [href]="EXTERNAL_RFC_7234_CACHE_LINK"
              >RFC 7234<i class="icofont icofont-external-link ml-1"></i
            ></a>
          </label>
          <app-switch class="col-md-auto" [(ngModel)]="cacheEnabled" [disabled]="disabled" [click]="toggle" />
        </div>
        <div id="purge-row" class="row form-group">
          <label class="col col-form-label">{{ 'PurgeCache' | translate }}</label>
          <div class="col-xl-6">
            <div class="flex-align-items">
              <input
                [(ngModel)]="purgeRegexp"
                [disabled]="!cacheEnabled || disabled"
                class="form-control"
                placeholder="/static/.*"
              />
              <button
                class="btn btn-primary btn-sm"
                [disabled]="!cacheEnabled || disabled || !purgeRegexp?.trim()"
                (click)="purge()"
              >
                {{ 'Purge' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
