import { Component, Input, OnInit } from '@angular/core';
import { PageRequest, SpringPage } from '../../../../shared/page';
import { AdminContractService, CollectionApi } from '../../../../services/admin-contract.service';

@Component({ template: '' })
export abstract class ContractCollectionComponent<T, U extends T = T> implements OnInit {
  @Input({ required: true }) contractId: number;

  data: SpringPage<T> | undefined;
  page: PageRequest = { number: 0, size: 10 };
  addItem: U;

  constructor(public admin: AdminContractService) {}

  ngOnInit() {
    this.refreshData();
  }

  changePage(evt) {
    this.page = { ...this.page, number: evt.page - 1 };
    this.refreshData();
  }

  async refreshData() {
    this.data = await this.getData(this.page);
  }

  protected abstract getCollectionApi(): CollectionApi<U>;

  protected abstract getItemId(item: T): any;

  getData(page: PageRequest): Promise<SpringPage<T>> {
    return this.getCollectionApi().getPage(page);
  }

  async add(item: T) {
    await this.getCollectionApi().add(this.getItemId(item));
    await this.refreshData();
  }

  async remove(item: T) {
    await this.getCollectionApi().remove(this.getItemId(item));
    await this.refreshData();
  }
}
