import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getPageParams, PageRequest, SpringPage } from 'app/shared/page';
import { addHttpParams } from '../shared/utils/request-utils';

@Injectable({
  providedIn: 'root',
})
export class AdminSiteService {
  constructor(private http: HttpClient) {}

  getSites$(siteQuery: SiteQuery = null, page: PageRequest = null): Observable<SpringPage<Site>> {
    let params = addHttpParams(siteQuery, getPageParams(page));

    return this.http.get<SpringPage<Site>>(`admin/sites`, { params });
  }
}

interface Site {
  id: number;
  name: string;
}

interface SiteQuery {
  clusterId?: number;
  status?: string;
  mode?: string;
  filter?: string;
  contractIsNull?: boolean;
}
