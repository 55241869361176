import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from '../../../services/auth.service';
import { Global } from '../../../global';
import { validatePasswordRestrictions } from '../../../shared/validators/validators';
import { copyObject } from 'app/shared/utils/data-utils';
import QRCode from 'qrcode';
import { firstValueFrom } from 'rxjs';

export enum MFA {
  NONE = 'none',
  EMAIL = 'email',
  TOTP = 'totp',
}

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: [
    './authentication.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../../../../scss/code-input.scss',
  ],
  standalone: false,
})
export class AuthenticationComponent implements OnInit, AfterViewInit {
  lang: string;

  @ViewChild('profileForm') public editProfileForm: NgForm;
  @ViewChildren('codeInput') codeInput: QueryList<ElementRef<HTMLInputElement>>;

  imgSrcQrCode = null;
  totpConfig: TotpConfig;
  totpChecked: boolean = undefined;

  MFA = MFA;

  user: any = {};
  userTmp: any = {};
  pswd: any = {};
  editAuth: boolean;
  editPassword: boolean;
  hasApiKey = false;
  apiKey = '';

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnInit() {
    this.getUser();
    this.editAuth = false;
  }

  ngAfterViewInit() {
    this.codeInput.changes.subscribe(() => this.focusCodeInput());
  }

  ngOnDestroy(): void {
    delete this.apiKey;
  }

  updateAuth = () => {
    this.http.put('v2/my-profile/mfa', { method: this.user.mfa }).subscribe({
      next: (res) => {
        this.getUser();
        this.toastr.success(this.translate.instant('SuccfChang'));
        this.editAuth = false;
      },
      error: (err) => {
        this.toastr.error(this.translate.instant('Error'));
      },
    });
  };

  onEditAuth() {
    this.editAuth = true;
    this.totpChecked = undefined;
    this.userTmp = copyObject(this.userTmp);
  }

  cancelEditAuth = () => {
    this.user = this.userTmp;
    this.editAuth = false;
    this.user = this.userTmp;
  };

  resetPassword() {
    if (!validatePasswordRestrictions(this.pswd.password)) {
      this.toastr.error(this.translate.instant('MotDePasseFort'));
      return;
    }
    if (this.pswd.password !== this.pswd.password2) {
      this.toastr.error(this.translate.instant('MotDePasseNeCorrespondentPas'));
      return;
    }
    const request = {
      data: {
        email: this.user.email,
        password: this.pswd.ancienPassword,
        newPassword: this.pswd.password,
      },
    };
    this.http.post('utilisateur/resetPassword', request).subscribe((res: any) => {
      if (!res.hasError) {
        this.pswd = {};
        this.toastr.success(this.translate.instant('PasswordChanged'));
        this.editPassword = false;
      } else {
        this.toastr.error(
          res.status.message || this.translate.instant('ErreurSurvenue'),
          this.translate.instant('Erreur'),
        );
      }
    });
  }

  getUser() {
    const user = this.http.get<any>('v2/my-profile').subscribe((user) => {
      this.user = {
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
        mfa: user.mfa,
        lang: user.lang,
      };
      this.userTmp = copyObject(this.user);
      this.hasApiKey = user.hasApiKey;
      this.apiKey = user.hasApiKey ? '●●●●●●●' : '';
    });
  }

  showKey() {
    this.auth.getApiKey().then((res: any) => {
      this.apiKey = res.item;
    });
  }

  async getOtpQrCode() {
    this.totpConfig = await firstValueFrom(
      this.http.post<TotpConfig>(`v2/my-profile/totp/generate-secret`, null, {
        responseType: 'json',
      }),
    );
    let rootUrl = Global.rootUrl();
    if (rootUrl.includes(':10443')) rootUrl = 'https://d1-stg.ogosecurity.com';
    const iconUrl = `${rootUrl}/favicon.png`;
    const url = `otpauth://totp/${encodeURIComponent(this.totpConfig.label)}?secret=${this.totpConfig.secret}&image=${iconUrl}`;
    this.imgSrcQrCode = await QRCode.toDataURL(url, { width: '200', margin: 0 });
  }

  onMfaMethodChange(method: MFA) {
    if (method == MFA.TOTP && this.userTmp.mfa != MFA.TOTP) {
      this.totpConfig = null;
      this.getOtpQrCode();
    }
  }

  async onTotpCodeChange(code: string) {
    if (code.length == 6) {
      const response = await firstValueFrom(
        this.http.post<{ ok: boolean }>(`v2/my-profile/totp/verify-code`, { code }, { responseType: 'json' }),
      );
      this.totpChecked = !!response.ok;
    } else {
      this.totpChecked = undefined;
    }
    if (this.totpChecked) {
      setTimeout(() => this.updateAuth(), 2000);
    }
  }

  private focusCodeInput() {
    if (this.codeInput.length > 0) this.codeInput.first['inputs'][0].focus();
  }
}

interface TotpConfig {
  label: string;
  secret: string;
}
