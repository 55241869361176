import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TabComponent as Tab, TabComponent } from './tab.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tabs',
  styleUrls: ['../../../assets/icon/icofont/css/icofont.scss'],
  template: `
    <div class="btn-group" [hidden]="hide">
      <button
        class="btn btn-md btn-square waves-effect flex-align-items waves-light"
        [class.btn-inverse]="tab.active"
        *ngFor="let tab of tabs"
        (click)="selectTab(tab)"
      >
        <span>{{ tab.title }}</span>
        <div
          *ngIf="tab.error"
          [ngbTooltip]="tab.errorTooltip"
          container="body"
          placement="bottom"
          style="display: inline"
        >
          <i class="icon-warning icofont icofont-warning tab-warning"></i>
        </div>
        <label *ngIf="tab.beta" class="badge badge-sm bg-dark m-0 ml-2">{{ tab.betaTranslation }}</label>
      </button>
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .btn-group {
        background: white;
        border-top: 1px solid #e7e7e7;
        z-index: 2;
        position: relative;
        right: 1px;

        .badge {
          top: 0px;
        }
      }
    `,
  ],
  standalone: false,
})
export class TabsComponent implements AfterContentInit {
  @Input('hide') hide: boolean;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  constructor(
    private router: Router,
    public translate: TranslateService,
  ) {}

  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: Tab) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;

    if (tab.queryStringNav) {
      this.addNavQueryString(tab);
    }
  }

  addNavQueryString(tab) {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = { tab: tab.queryStringNav };
    this.router.navigateByUrl(urlTree);
  }
}
