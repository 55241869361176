import { Directive, Host, Input } from '@angular/core';
import { NgForOf } from '@angular/common';

@Directive({
  selector: '[ngForTrackByKey]',
  standalone: true,
})
export class TrackByKeyDirective<T> {
  @Input('ngForTrackByKey') key: string;

  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (_: number, item: T) => item[this.key];
  }
}
