import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cluster-selector',
  templateUrl: './cluster-selector.component.html',
  styleUrls: ['./cluster-selector.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class ClusterSelectorComponent {
  @Input() clusters;
  @Input() cluster;
  @Output() selected = new EventEmitter<Object>();

  change(cluster) {
    return this.selected.emit(cluster);
  }
}
