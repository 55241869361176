import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
@Component({
  templateUrl: './badge-organization.component.html',
  styleUrls: ['./badge-organization.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  selector: 'app-badge-organization',
  standalone: false,
})
export class BadgeOrganizationComponent {
  @Input() code: string;

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  copyToClipboard(value: string) {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.left = '0';
    textarea.style.top = '0';
    textarea.style.opacity = '0';
    textarea.value = value;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    document.execCommand('copy');

    document.body.removeChild(textarea);
    this.toastr.success(this.translate.instant('CodeCopied'));
  }
}
