import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

@Injectable()
export class IsoDateAdapter extends NgbDateAdapter<string> {
  fromModel(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split('-');
      return {
        year: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string {
    if (date) return moment({ year: date.year, month: date.month - 1, day: date.day }).format('Y-MM-DD');
    else return null;
  }
}
