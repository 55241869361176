import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Country, CountryService } from 'app/services/country.service';
import { ActionEnum, CauseEnum, LogEntry, LogFiltersType, LogRequest, LogsService } from 'app/services/logs.service';
import { ADMIN_CONTEXT, CONTEXT, ContextEnum, SitesService } from 'app/services/sites.service';
import { BANDWIDTH, GraphType, RESPONSE_TIME, TRAFIC } from 'app/shared/highcharts/graph/graph';
import { SiteSelectorComponent } from 'app/shared/site-selector/site-selector.component';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { DateRange } from 'app/shared/utils/date-range';
import { TooltipMenuComponent } from 'app/shared/tooltip-menu/tooltip-menu.component';
import { saveAs } from 'file-saver';
import { Moment } from 'moment';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { MyLogsFiltersComponent } from './filters/my-logs-filters.component';
import { MyLogsGraphsComponent } from './graphs/my-logs-graphs.component';
import { Filter, FiltersEnum, formatFiltersLogs } from './filters/filters';

@Component({
  selector: 'app-my-logs',
  templateUrl: './my-logs.component.html',
  styleUrls: ['./my-logs.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class MyLogsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('siteSelector') siteSelector!: SiteSelectorComponent;
  @ViewChild('filtersComponent') filtersComponent: MyLogsFiltersComponent;
  @ViewChild('graphsComponent') graphsComponent!: MyLogsGraphsComponent;
  @ViewChild('tooltipFilterMenu') tooltipFilterMenu: TooltipMenuComponent;
  @ViewChild('tooltipNewFilter') tooltipNewFilter: TooltipMenuComponent;
  @ViewChild('progressBar', { static: true }) progressBar: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('trackTemplate') trackTemplate: ElementRef;
  @ViewChild('logsTable') logsTable;

  public countries: Country[];

  LogFiltersType = LogFiltersType;

  lang: string;
  Math = Math;

  ctx: ContextEnum = CONTEXT;
  ADMIN_CONTEXT = ADMIN_CONTEXT;

  logsQueryParameters: LogQueryParam = {};

  logs: (LogEntry & { countryName?: string })[] = [];

  RESPONSE_TIME = RESPONSE_TIME;
  BANDWIDTH = BANDWIDTH;

  FiltersEnum = FiltersEnum;
  ActionEnum = ActionEnum;
  CauseEnum = CauseEnum;

  filters: Filter[] = [];

  total: number = null;
  size: number = 20;
  index: number = 0;

  graphType: GraphType = TRAFIC;

  isLoading: boolean = false;

  logsTableMustScroll: boolean = false;

  constructor(
    private logsService: LogsService,
    private auth: AuthService,
    public sites: SitesService,
    private countryService: CountryService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {
    this.lang = this.auth.getCurrentLanguage();

    this.route.data.subscribe((data) => {
      if (data.adminClusterView) {
        this.ctx = ADMIN_CONTEXT;
      }

      const previousState = this.sites[this.ctx].current;
      const queryParameters = this.router?.getCurrentNavigation()?.extras?.state;

      // check if query parameters is passed
      if (queryParameters) {
        const { ctx, sites, clusterName, graphType, graphSubType, ...logsQueryParameters } = queryParameters;
        this.sites.resetContext(ctx || this.ctx, clusterName);
        if (sites) this.sites.setSites(ctx || this.ctx, sites);
        this.logsQueryParameters = logsQueryParameters;
        // else, check if there is a previous context
      } else if (previousState.trackingFilters) {
        Object.assign(this.filters, previousState.trackingFilters);
      }
    });
  }

  async ngAfterViewInit() {
    // query parameter has been passed
    if (Object.keys(this.logsQueryParameters).length) {
      return this.createFiltersFromEvent(this.logsQueryParameters);
      // previous context has been retrieved
    } else if (this.filters.length) {
      this.filtersComponent.filters = this.filters;
    }

    await this.sites.load(this.ctx);
    this.refresh();

    this.breakpointObserver.observe(['(max-width: 1395px)']).subscribe((result) => {
      this.logsTableMustScroll = result.matches;
    });
  }

  async ngOnInit() {
    await this.countryService.init();
    this.countries = this.countryService.getCountries();
  }

  ngOnDestroy() {
    this.sites[this.ctx].current.trackingFilters = this.filters;
  }

  onSiteSelectionChanged() {
    this.sites[this.ctx].refreshPeriod();
    this.loadData();
  }

  onPeriodEmitted(range: DateRange) {
    this.sites[this.ctx].current.period.range = range;

    this.refresh();
  }

  onPeriodEmittedFromGraph(range: DateRange) {
    this.sites[this.ctx].current.period.autoRefresh = false;
    this.sites[this.ctx].current.period.range = range;

    this.refresh();
  }

  refresh() {
    this.index = 0;
    this.sites[this.ctx].refreshPeriod();

    this.loadData();
  }

  loadData() {
    if (!this.sites[this.ctx].current.cluster?.id) {
      this.graphsComponent.noSitesFallback();
      return;
    }

    this.index = 0;

    const p1 = this.loadLogsPage();
    this.graphsComponent.resetData();
    const p2 = this.graphsComponent.loadGraphs();

    Promise.all([p1, p2]).then(() => {
      if (this.sites[this.ctx].current.period.autoRefresh) this.siteSelector.onDataLoaded();
      else this.siteSelector.clearRefreshInterval();
    });
  }

  async loadLogsPage() {
    if (this.isLoading || !this.sites[this.ctx].current.cluster?.id || !this.filters) {
      return;
    }

    this.isLoading = true;
    try {
      const page = await firstValueFrom(
        this.logsService.getLogs(this.getLogRequest(), { index: this.index, size: this.size }, this.ctx),
      );
      this.siteSelector.countNextRefresh = 0;
      this.total = page.totalItems;
      this.logs = page.content.map((log) => {
        log['countryName'] = this.countryService.getCountryName(log.countryCode);
        return log;
      });
    } finally {
      this.isLoading = false;
    }
  }

  private getLogRequest(): LogRequest {
    return { ...this.sites[this.ctx].buildRequestData(), ...formatFiltersLogs(this.filters) };
  }

  downloadLogs() {
    this.toastr.info(this.translate.instant('DownloadInProgress'), '', { disableTimeOut: true, tapToDismiss: false });

    const formatDate = (m: Moment) => m.toDate().toString().substr(4, 20).replace(/\s/g, '_');
    const period = this.sites[this.ctx].current.period.range;
    const beginDate = formatDate(period.start);
    const endDate = formatDate(period.end);

    this.logsService.downloadLogs(this.getLogRequest(), this.ctx).subscribe({
      next: (data) => {
        saveAs(data, `logs-${beginDate}-${endDate}.json`);
        this.toastr.clear();
        this.toastr.success(this.translate.instant('DownloadCompleted'));
      },
      error: () => {
        this.toastr.clear();
        this.toastr.error(this.translate.instant('OperationFailed'));
      },
    });
  }

  async createFiltersFromEvent(event) {
    // filters from events is necessarily filter in
    for (const key of Object.keys(event)) {
      if (!event[key]) continue;
      await this.filtersComponent.createFilter(LogFiltersType.IN, key as FiltersEnum, event[key]);
    }
    return this.filtersComponent.emitFilters();
  }

  async createFilter(type: LogFiltersType, key: FiltersEnum, value: string | number): Promise<void> {
    const shouldRefresh = await this.filtersComponent.createFilter(type, key, value);
    if (shouldRefresh) return this.filtersComponent.emitFilters();
  }

  async filterSite(siteName: string) {
    this.index = 0;
    this.sites.setSites(this.ctx, [siteName]);
    this.loadData();
  }

  toggleExpandRow(row) {
    this.logsTable.rowDetail.toggleExpandRow(row);
  }

  changePage(evt: any) {
    this.index = evt.page - 1;
    this.loadLogsPage();
  }

  onFiltersChange(filters) {
    this.index = 0;
    this.filters = filters;
    this.cdr.detectChanges(); // force @Input filters in my-logs-graphs to detect filters changes
    this.loadData();
  }

  filter(type: LogFiltersType, slug, value) {
    if (slug == ('site' as FiltersEnum)) return this.filterSite(value);
    this.createFilter(type, slug, value);
  }

  canFilterOut(slug): boolean {
    return (
      slug != ('site' as FiltersEnum) && slug != FiltersEnum.RESPONSE_TIME_GTE && slug != FiltersEnum.CONTENT_LENGTH_GTE
    );
  }
}

type LogQueryParam = {
  [k: string]: any;
};
