<div class="card with-tabs">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-12 mt-2">
        <h4 [innerHTML]="'MyNotifications' | translate"></h4>
      </div>
    </div>
    <hr />
    <div class="form-group row flex-align-items space-between">
      <app-label
        label="ReceiveMonthlyReport"
        tooltip="ReceiveMonthlyReportTooltip"
        tooltipPlacement="top"
        class="col"
      />
      <app-switch
        [(ngModel)]="notifications.monthlyReport"
        (change)="changeNotification('monthlyReport', $event)"
        class="col-md-auto"
      />
    </div>
    <div class="form-group row">
      <app-label
        label="AvertSecurityEventsFrom"
        tooltip="AvertSecurityEventsFromTooltip"
        tooltipPlacement="top"
        class="col"
      />
      <div class="col-xl-3">
        <select
          (change)="changeEventNotification('SECURITY', $event.target.value)"
          [(ngModel)]="notifications.eventNotificationMinSeverities.SECURITY"
          class="form-control"
        >
          <option value="null">{{ 'NoAlert' | translate }}</option>
          <option value="1">LOW</option>
          <option value="2" selected>MEDIUM</option>
          <option value="3">HIGH</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <app-label
        label="AvertMonitoringEventsFrom"
        tooltip="AvertMonitoringEventsFromTooltip"
        tooltipPlacement="top"
        class="col"
      />
      <div class="col-xl-3">
        <select
          (change)="changeEventNotification('MONITORING', $event.target.value)"
          [(ngModel)]="notifications.eventNotificationMinSeverities.MONITORING"
          class="form-control"
        >
          <option value="null">{{ 'NoAlert' | translate }}</option>
          <option value="1">LOW</option>
          <option value="2" selected>MEDIUM</option>
          <option value="3">HIGH</option>
        </select>
      </div>
    </div>
  </div>
</div>
