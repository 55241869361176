<div class="app-modal-body">
  <div class="card with-tabs p-3" style="overflow-x: auto">
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <h5>
            {{ 'Blocage de pays' | translate }}
          </h5>
          <p style="margin-top: 10px">{{ 'GeoblockTooltip' | translate }}</p>
          <hr />
          <div *ngIf="disabled" class="bg-blue" style="margin-bottom: 10px; margin-top: -7px">
            {{ 'SubscribeToAdvanced' | translate }}
          </div>
          <div class="ml-4">
            <div class="row" style="margin-left: -20px; flex-wrap: nowrap !important">
              <div class="col-12 blacklisted-table" style="min-width: 1000px">
                <div class="row no-gutters">
                  <div class="col-6 country-head">
                    <h6 class="text-success">
                      {{ 'Pays autorisés' | translate }} ({{ countries.length - blockedCountries.size }})
                    </h6>
                  </div>
                  <div class="col-6 country-head" style="border-right: 1px solid #ccc">
                    <h6 class="text-danger">{{ 'Pays bloqués' | translate }} ({{ blockedCountries.size }})</h6>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6 country-head">
                    <input
                      #country
                      class="form-control form-control-sm"
                      placeholder="{{ 'Search' | translate }}..."
                      [(ngModel)]="filterUnblocked"
                      type="text"
                    />
                  </div>
                  <div class="col-6 country-head" style="border-right: 1px solid #ccc">
                    <input
                      #blockCountry
                      class="form-control form-control-sm"
                      placeholder="{{ 'Search' | translate }}..."
                      [(ngModel)]="filterBlocked"
                      type="text"
                    />
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6 country-head">
                    <button (click)="blockAllCountries()" class="btn btn-mini btn-primary">
                      {{ 'AjouterTout' | translate }}
                    </button>
                  </div>
                  <div class="col-6 country-head" style="border-right: 1px solid #ccc">
                    <button (click)="enableAllCountries()" class="btn btn-mini btn-primary">
                      {{ 'SupprimerTout' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6 country-block custom-scrollbar">
                    <div
                      class="country-item"
                      *ngFor="let c of countries | filter: filterUnblocked; trackByKey: 'code'"
                      [hidden]="blockedCountries.has(c.code)"
                      (click)="blockAvailableCountry(c.code)"
                    >
                      <span>{{ c.code }} {{ c.name }}</span>
                    </div>
                  </div>
                  <div class="col-6 country-block custom-scrollbar" style="border-right: 1px solid #ccc">
                    <div
                      class="country-item block"
                      *ngFor="let c of countries | filter: filterBlocked; trackByKey: 'code'"
                      [hidden]="!blockedCountries.has(c.code)"
                      (click)="enableBlockCountry(c.code)"
                    >
                      <span>{{ c.code }} {{ c.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="pull-right">
                  <button
                    [disabled]="provisioningInProgress"
                    (click)="createBlacklistedCountries()"
                    class="btn btn-sm btn-primary"
                    [innerHTML]="'valider' | translate"
                    style="margin-top: 10px"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
