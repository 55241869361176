import { Component } from '@angular/core';
import { ContractCollectionComponent } from '../contract-collection.component';
import { AdminContractService, CollectionApi } from 'app/services/admin-contract.service';
import { ContractOrganization } from 'app/services/entities/contract';

@Component({
  selector: 'app-contract-organizations',
  standalone: false,

  templateUrl: './contract-organizations.component.html',
  styleUrls: ['./contract-organizations.component.scss', '../../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class ContractOrganizationsComponent extends ContractCollectionComponent<ContractOrganization> {
  constructor(admin: AdminContractService) {
    super(admin);
  }

  override getCollectionApi(): CollectionApi<ContractOrganization> {
    return this.admin.organizations(this.contractId);
  }

  protected getItemId(item: ContractOrganization) {
    return item.code;
  }
}
