import { Component, Input } from '@angular/core';
import { PageRequest, SpringPage } from '../page';
import { ContractSite } from '../../services/entities/contract';

@Component({
  selector: 'app-table-pager',
  standalone: false,

  templateUrl: './table-pager.component.html',
  styleUrl: './table-pager.component.scss',
})
export class TablePagerComponent {
  @Input() data: SpringPage<any>;
  @Input() page: PageRequest;
  @Input() changePage: (evt) => void;
}
