<div id="default-fixed-menu" class="flex-align-items wrap g-10 bg-white rounded shadow p-2">
  <div class="flex-align-items wrap g-10">
    <div class="block-site-selector" *ngIf="options.showClusters">
      <label>{{ translate.instant('Cluster') }} </label>
      <ng-select
        id="cluster"
        [notFoundText]="translate.instant('NoClusterFound')"
        class="selector select-site-selector"
        [addTag]="false"
        [(ngModel)]="sites[ctx].current.cluster"
        bindLabel="name"
        [clearable]="false"
        [items]="sites[ctx].store.clusters"
        [closeOnSelect]="true"
        [searchable]="true"
        [disabled]="isLoading"
        (change)="refreshSiteList($event)"
      ></ng-select>
    </div>
    <div class="block-site-selector" *ngIf="options.showTags">
      <label>Tag</label>
      <ng-select
        [notFoundText]="translate.instant('NoTag')"
        class="selector select-site-selector"
        [addTag]="false"
        [(ngModel)]="sites[ctx].current.tag"
        [clearable]="true"
        [items]="sites[ctx].store.tags"
        [closeOnSelect]="true"
        [searchable]="true"
        [disabled]="isLoading"
        (change)="refreshSiteList($event)"
        [placeholder]="translate.instant('All')"
      ></ng-select>
    </div>
    <div class="block-site-selector" *ngIf="options.showSites">
      <label>{{ translate.instant('site') }}</label>
      <ng-select
        id="sites"
        [notFoundText]="translate.instant('NoHostFound')"
        class="selector custom-scrollbar select-site-selector sites-selector"
        bindLabel="name"
        [addTag]="false"
        [(ngModel)]="sites[ctx].current.selectedSites"
        [clearable]="true"
        [items]="sites[ctx].store.sites"
        [multiple]="true"
        [searchable]="true"
        [closeOnSelect]="false"
        (close)="hostChange($event)"
        [disabled]="isLoading"
        (clear)="hostChange($event)"
        (remove)="hostChange($event)"
        [placeholder]="translate.instant('All')"
        style="max-width: unset; width: unset"
      >
      </ng-select>
    </div>
    <ng-content></ng-content>
    <button
      *ngIf="options.showDatePicker"
      app-date-range-selector
      [disabled]="isLoading"
      [range]="sites[ctx].current.period.range"
      [minDateDays]="minDateDays"
      [ranges]="ranges"
      [ctx]="ctx"
      (rangeChange)="rangeChange($event)"
      [timePicker]="true"
      class="btn btn-primary"
      id="reportrange-{{ datePickerSlug }}{{ ctx === ADMIN_CONTEXT ? '-admin' : '' }}"
    >
      <i class="icofont icofont-ui-calendar mr-2"></i>
      <span></span>
      <div class="arrow-down"></div>
    </button>
    <button
      class="btn btn-primary btn-reload"
      title="{{ 'Refresh' | translate }}"
      (click)="onRefresh()"
      [disabled]="isLoading"
    >
      <i class="icon-reload"></i>
    </button>
    <div
      *ngIf="options.canAutoRefresh"
      class="switch-refresh flex-align-items space-between"
      [hidden]="!sites.canAutoRefresh(ctx)"
      [title]="'RefreshEveryMinute' | translate"
    >
      <div>
        <label>
          <i class="icon-reload"></i>{{ refreshDuration / 1000 | durationSeconds }}
          <div
            class="loading-bar activity"
            [ngStyle]="{ 'width.%': Math.min((countNextRefresh / countSteps) * 100, 100) }"
          ></div>
        </label>
      </div>
      <div class="switch-container ml-1">
        <label class="switch">
          <input
            type="checkbox"
            [(ngModel)]="sites[ctx].current.period.autoRefresh"
            (change)="sites[ctx].current.period.autoRefresh ? setRefreshInterval() : clearRefreshInterval()"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</div>
