<div *ngIf="showView == pageViews.LIST" class="m-t-25">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-5">
      <div class="input-group input-group-sm">
        <input
          type="text"
          #search
          class="form-control form-control-sm"
          placeholder="Nom, Organisation, Email"
          (keyup)="page = 1; getUsers()"
        />
        <span class="input-group-prepend">
          <span class="input-group-text">
            <i class="icofont icofont-search"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="table-responsive">
      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th [innerHtml]="'NomPrénom(s)' | translate"></th>
            <th [innerHtml]="'Email' | translate"></th>
            <th [innerHtml]="'role' | translate"></th>
            <th [innerHtml]="'Creation' | translate"></th>
            <th [innerHtml]="'CreatedBy' | translate"></th>
            <th class="actions" [innerHtml]="'Actions' | translate"></th>
          </tr>
        </thead>
        <tbody>
          <ng-template
            ngFor
            let-user
            [ngForOf]="
              utilisateurs
                | paginate
                  : {
                      itemsPerPage: pageSize,
                      currentPage: page,
                      totalItems: totalItems
                    }
            "
          >
            <tr>
              <td>{{ user.lastName }} {{ user.firstName }}</td>
              <td>
                {{ user.email }}
              </td>
              <td>
                {{ user.profile }}
              </td>
              <td>
                <app-timestamp-cell [value]="user.createdAt" />
              </td>
              <td>{{ user.createdBy?.lastName }} {{ user.createdBy?.firstName }}</td>
              <td class="actions">
                <i (click)="editUser(user)" class="icofont icofont-edit-alt" title="Editer"></i>
                <i (click)="modalRemove(user)" class="icofont icofont-ui-delete" title="Supprimer"></i>
                <i
                  *ngIf="!user.lastLoginDate"
                  (click)="resendRegistrationMail(user)"
                  class="icofont icofont-send-mail"
                  [title]="'ResendRegistrationEmail' | translate"
                ></i>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="float-right mr-2">
        <pagination-controls
          class="pull-right"
          (pageChange)="changePage($event)"
          previousLabel="{{ 'Previous' | translate }}"
          nextLabel="{{ 'Next' | translate }}"
        ></pagination-controls>
        <div class="pull-right mr-4 total-admin">Total : {{ totalItems }}</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showView == pageViews.EDIT" class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header" style="padding-bottom: 0">
        <h4 class="modal-title">
          {{ utilisateur.id ? ('EditUser' | translate) : ('AddUser' | translate) }}
        </h4>
        <hr />
      </div>
      <div class="card-body" style="padding-top: 0">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label [innerHtml]="'Nom' | translate"></label>
              <input [(ngModel)]="utilisateur.lastName" type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label [innerHtml]="'Prenom' | translate"></label>
              <input [(ngModel)]="utilisateur.firstName" type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label class="star" [innerHtml]="'AdresseMail' | translate"></label>
              <input [(ngModel)]="utilisateur.email" type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label [innerHtml]="'role' | translate"></label>
              <select [(ngModel)]="utilisateur.profile" class="form-control">
                <option value=""></option>
                <option *ngFor="let p of profils" value="{{ p.code }}">
                  {{ p.libelle }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Langue</label>
              <select [(ngModel)]="utilisateur.lang" class="form-control">
                <option value="fr">{{ 'FR' | translate }}</option>
                <option value="en">{{ 'EN' | translate }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ 'API Key' | translate }}</label>
              <input [(ngModel)]="utilisateur.apiKey" type="text" class="form-control" />
            </div>
          </div>
        </div>
        <div>
          <h5>{{ 'Organizations' | translate }}</h5>
          <table class="table table-hover table-fixed">
            <thead>
              <tr>
                <th>{{ 'Nom' | translate }}</th>
                <th>{{ 'role' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let org of utilisateur.organizations">
                <td>
                  {{ org.organization.companyName }}
                  <app-badge-organization [code]="org.organization.code"></app-badge-organization>
                </td>
                <td>{{ org.role }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <button
          *ngIf="!utilisateur.lastLoginDate"
          (click)="resendRegistrationMail(utilisateur)"
          class="btn btn-sm btn-success mr-2"
        >
          {{ 'ResendRegistrationEmail' | translate }}<i class="icofont icofont-send-mail"></i>
        </button>
        <button
          *ngIf="utilisateur.id !== undefined"
          (click)="updateUser(utilisateur)"
          class="btn btn-sm btn-primary mr-2"
          [innerHtml]="'Enrigistrer' | translate"
        ></button>
        <button
          (click)="showView = pageViews.LIST"
          class="btn btn-sm btn-danger"
          [innerHtml]="'Annuler' | translate"
        ></button>
      </div>
    </div>
  </div>
</div>

<!-- POPUP REMOVE USER -->
<app-modal-basic #remove [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHtml]="'Confirmation de suppression'"></small>
    </h4>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="label label-primary">Nom</label>
          <p>{{ user?.nom }}</p>
        </div>
        <div class="form-group">
          <label class="label label-primary">Prénom(s)</label>
          <p>{{ user?.prenoms }}</p>
        </div>
        <div class="form-group">
          <label class="label label-primary">Email</label>
          <p>{{ user?.email }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="removeUser()" class="btn btn-sm btn-primary" [innerHtml]="'Supprimer'"></button>&nbsp;
    <button (click)="remove.hide()" class="btn btn-sm btn-danger" [innerHtml]="'Annuler'"></button>
  </div>
</app-modal-basic>
