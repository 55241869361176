import { environment } from '../environments/environment';
import { FeatureFlags } from '../environments/feature-flags';

export class Global {
  public static baseUrl = environment.URL;
  public static featureFlags: FeatureFlags = environment.featureFlags;
  public static reloadOnError = environment.reloadOnError;
  public static rootUrl(): string {
    return this.baseUrl.replaceAll(/\/api\/ogo-backend\/?$/g, '');
  }
}
