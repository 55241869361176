<app-tabs>
  <app-tab
    [tabTitle]="'Authentication' | translate"
    [active]="activeTab == 'authentication'"
    [queryStringNav]="'authentication'"
  >
    <app-authentication></app-authentication>
  </app-tab>
  <app-tab
    [tabTitle]="'PersonalInformations' | translate"
    [active]="activeTab == 'parameters'"
    [queryStringNav]="'parameters'"
  >
    <app-my-parameters></app-my-parameters>
  </app-tab>
  <app-tab
    [tabTitle]="'MyNotifications' | translate"
    [active]="activeTab == 'notifications'"
    [queryStringNav]="'notifications'"
  >
    <app-my-notifications></app-my-notifications>
  </app-tab>
</app-tabs>
