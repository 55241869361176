<ng-container *ngIf="SHOW_VIEW === 'LIST_CLUSTER'">
  <div class="m-t-25">
    <div class="card">
      <button (click)="editCluster(undefined)" class="btn btn-sm btn-primary">
        <strong [innerHtml]="'+ajouter' | translate"></strong>
      </button>
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th [innerHtml]="'Cluster' | translate"></th>
              <th [innerHtml]="'IP' | translate"></th>
              <th [innerHtml]="'IPv6' | translate"></th>
              <th [innerHtml]="'ClusterID' | translate"></th>
              <th [innerHtml]="'Endpoint' | translate"></th>
              <th>Elasticsearch</th>
              <th>Template</th>
              <th [innerHtml]="'OrganizationOwner' | translate"></th>
              <th [innerHtml]="'Cache' | translate"></th>
              <th [innerHtml]="'Actions' | translate"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of clusters">
              <td>
                {{ row.name }}
                <span *ngIf="row.managed" class="label ml-2 mr-2 badge-outline" title="{{ 'Managed' | translate }}"
                  >M</span
                >
              </td>
              <td>{{ row.ip }}</td>
              <td>{{ row.ipv6 }}</td>
              <td>{{ row.clusterId }}</td>
              <td>{{ row.endpoint }}</td>
              <td>{{ row.elasticsearch?.name }}</td>
              <td>{{ row.type?.name }}</td>
              <td>
                {{ row.owner.companyName }} <app-badge-organization [code]="row.owner.code"></app-badge-organization>
              </td>
              <td>{{ (row.supportsCache ? 'enabled' : 'disabled') | translate }}</td>
              <td class="flex-align-items">
                <i
                  (click)="editCluster(row)"
                  style="color: #4099ff; font-size: 20px"
                  class="pointer icofont icofont-edit-alt"
                ></i
                >&nbsp;&nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="SHOW_VIEW === 'EDIT_CLUSTER'">
  <div class="row">
    <div class="col-md-6 col-xs-12 offset-md-3">
      <div class="card p-5">
        <div class="app-modal-header mb-3">
          <h4 class="modal-title" *ngIf="cluster.id !== undefined" [innerHtml]="'EditerCluster' | translate"></h4>
          <h4 class="modal-title" *ngIf="cluster.id === undefined" [innerHtml]="'AjouterCluster' | translate"></h4>
        </div>
        <div class="app-modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label [innerHtml]="'OrganizationOwner' | translate"></label>
                <ng-select
                  [items]="organizations"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  bindLabel="companyNameAndCode"
                  bindValue="id"
                  [(ngModel)]="cluster.ownerId"
                  (search)="loadOrganizations($event.term)"
                ></ng-select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Name' | translate"></label>
                <input [(ngModel)]="cluster.name" class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label [innerHtml]="'IP' | translate"></label>
                <input [(ngModel)]="cluster.ip" class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label [innerHtml]="'IPv6' | translate"></label>
                <input [(ngModel)]="cluster.ipv6" class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label [innerHtml]="'ClusterID' | translate"></label>
                <input [(ngModel)]="cluster.clusterId" class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label [innerHtml]="'Supports Cache'"></label>
                <select [(ngModel)]="cluster.supportsCache" class="form-control">
                  <option value="true">{{ 'Yes' | translate }}</option>
                  <option value="false">{{ 'No' | translate }}</option>
                </select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'CDN resource ID'"></label>
                <input [(ngModel)]="cluster.cdnResourceId" class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label [innerHtml]="'Endpoint' | translate"></label>
                <input [(ngModel)]="cluster.endpoint" class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label [innerHtml]="'Cluster twin' | translate"></label>
                <ng-select
                  [items]="clustersTwin"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  bindLabel="nom"
                  bindValue="id"
                  [(ngModel)]="cluster.clusterTwinId"
                >
                </ng-select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Elasticsearch'"></label>
                <select [(ngModel)]="cluster.elasticsearchId" class="form-control">
                  <option value=""></option>
                  <option value="{{ item.id }}" *ngFor="let item of elasticsearchs">
                    {{ item.nom }} -- {{ item.endpoint }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Template'"></label>
                <select [(ngModel)]="cluster.clusterTypeId" class="form-control">
                  <option value=""></option>
                  <option value="{{ item.id }}" *ngFor="let item of clusterTypes">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Public'"></label>
                <select [(ngModel)]="cluster.isPublic" class="form-control">
                  <option value="true">{{ 'Yes' | translate }}</option>
                  <option value="false">{{ 'No' | translate }}</option>
                </select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Managed'"></label>
                <select [(ngModel)]="cluster.managed" class="form-control">
                  <option value="true">{{ 'Yes' | translate }}</option>
                  <option value="false">{{ 'No' | translate }}</option>
                </select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Supports IPv6 origins'"></label>
                <select [(ngModel)]="cluster.supportsIpv6Origins" class="form-control">
                  <option value="true">{{ 'Yes' | translate }}</option>
                  <option value="false">{{ 'No' | translate }}</option>
                </select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Default site mode'"></label>
                <select [(ngModel)]="cluster.defaultSiteMode" class="form-control">
                  <option *ngFor="let m of hostModeService.modes" [value]="m.value">{{ m.value }}</option>
                </select>
              </div>
              <div class="form-group">
                <label [innerHtml]="'Cluster outgoing IPs'"></label>
                <ng-select
                  [multiple]="true"
                  [closeOnSelect]="true"
                  [(ngModel)]="cluster.trustedIps"
                  [addTag]="true"
                  bindValue="label"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="app-modal-footer">
          <button
            *ngIf="cluster.id === undefined"
            (click)="onCreateCluster(cluster)"
            class="btn btn-sm btn-danger mr-2"
            [innerHtml]="'ajouter' | translate"
          ></button>
          <button
            *ngIf="cluster.id !== undefined"
            (click)="onUpdateCluster(cluster)"
            class="btn btn-sm btn-danger mr-2"
            [innerHtml]="'Enregistrer' | translate"
          ></button>
          <button
            (click)="cluster = {}; SHOW_VIEW = 'LIST_CLUSTER'"
            class="btn btn-sm btn-primary mr-2"
            [innerHtml]="'Annuler' | translate"
          ></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
