import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { HostModeService } from '../../../shared/hostMode/hostMode.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';
import { EXTERNAL_RFC_7234_CACHE_LINK } from 'app/app-routing.module';
import { firstValueFrom } from 'rxjs';
import { OrganizationSiteService } from '../../../services/organization-site.service';
import { Site } from 'app/services/entities/site';

const PURGE_REGEXP = 'purge-regexp';
@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styleUrls: [
    './cache.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../add-site.component.scss',
  ],
  standalone: false,
})
export class CacheComponent implements OnInit {
  lang: string;

  @Input({ required: true }) site: Site;
  @Input() cacheEnabled: boolean;
  @Output() cacheEnabledChange = new EventEmitter<boolean>();

  ruleUseCache: boolean;

  EXTERNAL_RFC_7234_CACHE_LINK = EXTERNAL_RFC_7234_CACHE_LINK;

  disabled = true;
  purgeRegexp = '';

  constructor(
    private siteApi: OrganizationSiteService,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private auth: AuthService,
    public hostModeService: HostModeService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();
    this.purgeRegexp = localStorage.getItem(PURGE_REGEXP);
    this.disabled = !this.hostModeService.isAdvanced(this.site.mode);
    this.siteApi.getRules(this.site.name).subscribe({
      next: (rules) => {
        this.ruleUseCache = !!rules.find((rule) => rule.cache);
      },
      error: (error: any) => {
        this.toastr.error(`failed to get rules: ${error}`);
      },
    });
  }

  toggle = (event: MouseEvent) => {
    event.preventDefault();

    const checked = !this.cacheEnabled;

    if (!checked && this.ruleUseCache) {
      this.cacheEnabled = true;
      return this.toastr.error(this.translate.instant('RuleUseCache'));
    }

    Swal.fire({
      title: this.translate.instant('Confirmation'),
      html: this.translate.instant(checked ? 'EnableCache' : 'DisableCache'),
      ...this.alertOptions,
    } as SweetAlertOptions).then(async (result) => {
      if (result.value) {
        const res: any = await firstValueFrom(
          this.http.post('hote/update', { datas: [{ id: this.site.id, cacheEnabled: checked }] }),
        );
        if (!res.hasError) {
          this.cacheEnabled = checked;
          this.cacheEnabledChange.emit(checked);
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      }
    });
  };

  async purge() {
    localStorage.setItem(PURGE_REGEXP, this.purgeRegexp);
    await firstValueFrom(
      this.http.post(`v2/organizations/${this.auth.currentOrganization.id}/sites/${this.site.name}/purgeCache`, {
        path: this.purgeRegexp,
      }),
    );
    this.toastr.success(this.translate.instant('OperationSuccess'));
  }

  alertOptions = {
    showCancelButton: true,
    confirmButtonColor: '#4099ff',
    cancelButtonColor: '#d33',
    cancelButtonText: this.translate.instant('No'),
    confirmButtonText: this.translate.instant('YesConfirm'),
  };
}
