import { Component, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-timestamp-cell',
  standalone: false,

  template: '<div class="date" title="{{ value | dateFormat }}">{{ value | date: format }}</div>',
})
export class TimestampCellComponent {
  @Input() value: Date | number;

  format: string;

  constructor(translation: TranslateService) {
    this.format = translation.instant('DateFormat');
  }
}
