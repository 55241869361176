import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { Site } from '../admin-site-selector/admin-site-selector.component';
import { AdminClusterService } from '../../services/admin-cluster.service';

@Component({
  selector: 'app-admin-cluster-selector',
  standalone: false,

  templateUrl: './admin-cluster-selector.component.html',
  styleUrl: './admin-cluster-selector.component.scss',
})
export class AdminClusterSelectorComponent {
  @Input() model: Cluster;
  @Input() filters: { [k: string]: any };
  @Output() modelChange = new EventEmitter<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private clusterService: AdminClusterService) {}

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) => {
        return term.length < 2
          ? of([])
          : this.clusterService.getPage({ ...this.filters, filter: term }).pipe(
              catchError((error) => {
                console.error(error);
                return of({ content: [] as Site[] });
              }), // empty list on error
              map((page) => page.content),
            );
      }),
    );
  };

  formatter = (cluster: Cluster) => cluster.name;
}

export interface Cluster {
  name: string;
}
