import { Component } from '@angular/core';
import { AdminContractService, CollectionApi } from 'app/services/admin-contract.service';
import { ContractCluster } from 'app/services/entities/contract';
import { ContractCollectionComponent } from '../contract-collection.component';

@Component({
  selector: 'app-contract-clusters',
  standalone: false,

  templateUrl: './contract-clusters.component.html',
  styleUrls: ['./contract-clusters.component.scss', '../../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class ContractClustersComponent extends ContractCollectionComponent<ContractCluster> {
  constructor(constractService: AdminContractService) {
    super(constractService);
  }

  override getCollectionApi(): CollectionApi<ContractCluster> {
    return this.admin.clusters(this.contractId);
  }

  protected getItemId(item: ContractCluster) {
    return item.clusterId;
  }
}
