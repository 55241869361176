import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from 'app/services/auth.service';

import { Contract, EditableContract } from 'app/services/entities/contract';
import { Location } from '@angular/common';
import { AdminContractService } from 'app/services/admin-contract.service';
import { EditMode } from 'app/shared/edit.mode';
import _ from 'lodash';

@Component({
  selector: 'app-add-edit-contract',
  templateUrl: './add-edit-contract.component.html',
  styleUrls: ['./add-edit-contract.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class AddEditContractComponent implements OnInit {
  mode?: EditMode;
  contractId: number;
  contract: Partial<Contract>;
  organizations: readonly any[];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private toastr: ToastrService,
    public adminContractService: AdminContractService,
  ) {}

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    const contractId = routeParams.get('contractId');
    if (_.isNil(contractId)) {
      this.mode = EditMode.CREATE;
      this.contract = {};
    } else {
      this.mode = EditMode.UPDATE;
      this.contractId = Number(contractId);
      this.contract = await this.adminContractService.getContract(this.contractId);
    }
  }

  createContract() {
    if (!this.validateContract(this.contract)) return;

    this.adminContractService.addContract(this.updateFormatData(this.contract)).subscribe({
      next: () => this.back(),
      error: () => this.toastr.error(this.translate.instant('OperationFailed')),
    });
  }

  updateContract() {
    if (!this.validateContract(this.contract)) return;

    this.adminContractService.updateContract(this.contractId, this.updateFormatData(this.contract)).subscribe({
      next: () => this.back(),
      error: () => this.toastr.error(this.translate.instant('OperationFailed')),
    });
  }

  validateContract(contract: Partial<EditableContract>): contract is EditableContract {
    if (!contract.holder) {
      this.toastr.error(this.translate.instant('FieldRequired', { field: this.translate.instant('Contract.holder') }));
      return false;
    }
    if (!contract.name) {
      this.toastr.error(this.translate.instant('FieldRequired', { field: this.translate.instant('Contract.name') }));
      return false;
    }
    return true;
  }

  updateFormatData(contract: EditableContract) {
    let { holder, ...rest } = _.omit(contract, 'createdAt', 'updatedAt') as EditableContract;

    return { ...rest, holder: { code: holder.code } };
  }

  back() {
    this.location.back();
  }

  canValidate() {
    return this.contract?.name && this.contract?.holder?.code;
  }

  protected readonly EditMode = EditMode;
}
