import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cluster } from './sites.service';
import { getPageParams, PageRequest, SpringPage } from '../shared/page';
import { addHttpParams } from '../shared/utils/request-utils';

@Injectable({
  providedIn: 'root',
})
export class AdminClusterService {
  constructor(private http: HttpClient) {}

  getPage(query: ClusterQuery = null, page: PageRequest = null) {
    let params = addHttpParams(query, getPageParams(page));

    return this.http.get<SpringPage<Cluster>>('admin/clusters', { params });
  }
}

interface ClusterQuery {
  filter?: string;
  contractIsNull?: boolean;
}
