export function getBrowserLang(): Lang {
  return (
    navigator.languages
      .map((l) => l.slice(0, 2))
      .map(asLangOrNull)
      .find((l) => !!l) ?? DEFAULT_LANG
  );
}

export function asLangOrNull(lang: string): Lang | null {
  return LANGUAGES.includes(lang) ? (lang as Lang) : null;
}

export function asLangOrDefault(lang: string): Lang {
  return asLangOrNull(lang) ?? DEFAULT_LANG;
}

export const CONST_LANGUAGES = ['en', 'fr'] as const;
const LANGUAGES: string[] = [...CONST_LANGUAGES];
export type Lang = (typeof CONST_LANGUAGES)[number];
export const DEFAULT_LANG: Lang = 'en';
