<input
  type="text"
  class="form-control"
  [ngbTypeahead]="search"
  [multiple]="false"
  [(ngModel)]="model"
  (ngModelChange)="modelChange.emit($event)"
  [resultTemplate]="organization"
  [inputFormatter]="formatter"
  [editable]="false"
  (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)"
  #instance="ngbTypeahead"
/>
<ng-template #organization let-organization="result" let-t="term">
  <ngb-highlight [result]="formatter(organization)" [term]="t"></ngb-highlight>
</ng-template>
