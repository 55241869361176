import { HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { isMoment } from 'moment';

export function buildHttpParams(request: any): HttpParams {
  return new HttpParams({ fromObject: formatRequest(request) });
}

export function addHttpParams(request: any, params: HttpParams) {
  return params.appendAll(formatRequest(request));
}

function formatRequest(request: any) {
  return _(request)
    .omitBy((value) => (_.isArray(value) && value.length == 0) || value === '' || _.isNil(value))
    .mapValues(formatValue)
    .value();
}

function formatValue(value: any): string | number | boolean {
  if (_.isArray(value)) {
    return value.join(',');
  } else if (isMoment(value)) {
    return value.toISOString();
  } else {
    return value;
  }
}

export function buildBody(request: any): any {
  return _.mapValues((value) => (isMoment(value) ? value.toISOString() : value));
}
