import { Component, Input } from '@angular/core';
import { PageRequest, SpringPage } from '../../../shared/page';
import { Contract } from '../../../services/entities/contract';
import { AdminOrganizationService } from '../../../services/admin-organization.service';

@Component({
  selector: 'app-organization-contracts',
  standalone: false,

  templateUrl: './organization-contracts.component.html',
  styleUrl: './organization-contracts.component.scss',
})
export class OrganizationContractsComponent {
  @Input({ required: true }) organizationCode: string;

  data: SpringPage<Contract> | undefined;
  page: PageRequest = { number: 0, size: 10 };

  constructor(private organizationService: AdminOrganizationService) {}

  ngOnInit() {
    this.refreshData();
  }

  changePage(evt) {
    this.page = { ...this.page, number: evt.page - 1 };
    this.refreshData();
  }

  async refreshData() {
    this.data = await this.organizationService.getOrganizationContracts(this.organizationCode);
  }
}
