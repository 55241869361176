<div class="card with-tabs">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-12 mt-2 mb-3">
        <h4 class="flex-align-items space-between mb-0">
          <span>
            {{ 'AccountUsers' | translate }}
            <label class="badge badge-sm bg-dark"
              ><a style="color: inherit" target="_blank" [href]="'ExternalHelp.organizations' | translate">?</a></label
            >
          </span>
          <button type="button" (click)="showModalAddUser()" class="btn btn-sm btn-primary btn-sm">
            <i class="icofont icofont-ui-add"></i>
            {{ 'AddUser' | translate }}
          </button>
        </h4>
      </div>
    </div>
    <ngx-datatable
      [messages]="{ emptyMessage: translate.instant('NoData') }"
      class="data-table table-responsive mb-3"
      [limit]="20"
      [rows]="auth.currentOrganization.users"
      [columnMode]="ColumnMode.force"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="40"
    >
      <ngx-datatable-column [flexGrow]="1">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'NomPrénom(s)' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span title="{{ fullName(row.user) }}">{{ fullName(row.user) ?? '-' }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="1">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Email' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span title="{{ row.user.email }}">{{ row.user.email }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="1">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Statut' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template
          ><i>{{ row.user.isActive ? ('Actif' | translate) : ('InvitationPending' | translate) }}</i></ng-template
        >
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="2" [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader">
            {{ 'role' | translate }}
            <label class="badge badge-sm bg-dark ml-1" container="body" placement="bottom" [ngbTooltip]="role">?</label>
            <ng-template #role>
              <div [innerHTML]="'OrganizationRoleTooltip' | translate"></div>
            </ng-template>
          </p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <select
            [ngModel]="row.role"
            [disabled]="row.user.email == auth.user.email || row.role == users.roles.owner"
            (change)="showModalUpdate(row.role, row.user.email, $event)"
            class="select-role text-ellipsis"
            style="max-width: 250px"
          >
            <option *ngFor="let r of users.rolesMap" [hidden]="r.value == users.roles.owner" [value]="r.value">
              {{ r.libelle | translate }}
            </option>
          </select>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [minWidth]="100" [maxWidth]="100" [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Actions' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <i
            *ngIf="row.user.email != auth.user.email && row.role != users.roles.owner"
            title="{{ 'supprimer' | translate }}"
            [attr.name]="row.user.nom + '-delete'"
            (click)="showModalDelete(row.user.email)"
            style="color: #e74c3c; font-size: 17px; position: relative; top: -2px"
            class="pointer icofont icofont-ui-delete"
          ></i>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-curPage="index" let-pageSize="size">
          <small class="ogo-ngx-datatable-footer"
            >Total : {{ auth.currentOrganization.nbUsers }}
            {{ auth.currentOrganization.nbUsers > 1 ? ('users' | translate) : ('user' | translate) }}
          </small>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="page + 1"
            [size]="size"
            [count]="auth.currentOrganization.nbUsers"
            (change)="changePage($event)"
          >
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>

<app-modal-basic #modalAddUser [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'AddUser' | translate"></small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalAddUser.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group ng-select-no-dropdown">
          <label>Email(s)</label>
          <ng-select
            #addUserInput
            (paste)="onPasteEmails($event)"
            class="select-site-selector"
            [multiple]="true"
            [addTag]="true"
            [(ngModel)]="newUsers"
            [clearable]="false"
            [searchable]="true"
            [openOnEnter]="false"
            [closeOnSelect]="true"
            [placeholder]="'EnterEmail' | translate"
            [inputAttrs]="{ 'data-form-type': 'other' }"
            (blur)="handleAddUserBlur()"
            (search)="handleAddUserType($event)"
          ></ng-select>
        </div>
        <div style="margin-top: -10px; margin-bottom: 10px; font-size: 12px; color: #969696">
          <i>{{ 'PressEnterToAdd' | translate }}{{ 'SeparatedEmails' | translate }}</i>
        </div>
        <div class="form-group">
          <app-label label="role" tooltip="OrganizationRoleTooltip" />
          <select [(ngModel)]="newUsersRole" class="form-control">
            <option *ngFor="let r of users.rolesMap" [value]="r.value" [hidden]="r.value == users.roles.owner">
              {{ r.libelle | translate }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      (click)="addUser()"
      class="btn btn-primary btn-sm"
      [disabled]="!newUsers.length"
      [innerHTML]="'Ajouter' | translate"
    ></button>
    &nbsp;
    <button
      (click)="modalAddUser.hide()"
      class="btn btn-danger btn-sm pull-right"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>
