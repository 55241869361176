import { Component, Input } from '@angular/core';
@Component({
  template: `<label class="label text-center {{ 'delegation-' + role }}">{{
    'ClusterDelegation.' + role | translate
  }}</label>`,
  styles: [
    'label { padding-top: 6px; padding-bottom: 6px; }',
    '.delegation-USE {  background-color: #f39c12;}',
    '.delegation-ADMIN {  background-color: #2ecc71;}',
  ],
  selector: 'label-delegation-role',
  standalone: false,
})
export class LabelDelegationRoleComponent {
  @Input() role: string;
}
