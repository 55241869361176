import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

import { AuthService } from 'app/services/auth.service';
import { HostModeService } from 'app/shared/hostMode/hostMode.service';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { copyMap, sortAlphabetically } from 'app/shared/utils/data-utils';
import { firstValueFrom } from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { OgoResponseItems } from '../../../services/ogo-response';
import { Site } from 'app/services/entities/site';

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class ExpertComponent implements OnInit {
  lang: string;

  @Input({ required: true }) site: Site;

  brainParams: ParamGroup[] = [];
  brainTunables = [];
  brainOverrides: Map<number, BrainOverride> = new Map<number, BrainOverride>();
  previousBrainOverrides = new Map();
  collapsedGroups = [];
  editMode: boolean = false;
  errors = [];
  driveSearch = '';
  isLoading = false;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
    private auth: AuthService,
    public hostModeService: HostModeService,
  ) {}

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
    if (this.site.id) this.getBrainParams(this.site.id);
  }

  openAllGroups() {
    this.brainParams.forEach((group) => (group.open = true));
  }

  driveFilter = (drive: ParamsSubGroup): boolean => {
    return (
      !this.driveSearch ||
      drive.label.toLowerCase().includes(this.driveSearch.toLowerCase()) ||
      drive.desc.toLowerCase().includes(this.driveSearch.toLowerCase())
    );
  };

  getBrainParams(hoteId) {
    let brainDrivesGroups = firstValueFrom(this.http.post<OgoResponseItems<Group>>('brainDrivesGroup/get', {}));

    let brainTunables = firstValueFrom(
      this.http.post<OgoResponseItems<BrainTunableDto>>('brainTunable/get', { data: { hoteId } }),
    );

    let brainOverrides = firstValueFrom(
      this.http.post<OgoResponseItems<BrainOverrideDto>>('brainOverride/get', { data: { hoteId } }),
    );

    Promise.all([brainTunables, brainOverrides, brainDrivesGroups]).then((res) =>
      this.formatBrainParams(res[0].items || [], res[1].items || [], res[2].items || []),
    );
  }

  toggleEditMode() {
    if (this.site.mode == 'EXPERT') {
      if (this.editMode) {
        const data = this.formatData(this.brainOverrides.values());
        if (!data.length) {
          this.deleteOverrides();
        } else {
          this.saveCollapsedGroups();
          this.createOverrides(data);
        }
      } else {
        this.editMode = !this.editMode;
      }
    } else {
      this.hostModeService.promptSuscribeToMode('SuscribeToExpert');
    }
  }

  onInputChange(element, override, min, max) {
    const value = (element.value = element.value.replace(',', '.'));
    const foundIndex = this.errors.findIndex((e) => e == override.brainTunableId);

    if (/[a-zA-Z]/g.test(value) || (value != '' && (value < min || value > max || value.slice(-1) == '.'))) {
      element.style.border = '2px solid #ff7575';
      if (foundIndex == -1) {
        this.errors.push(override.brainTunableId);
      }
    } else {
      element.style.border = '';
      if (foundIndex != -1) {
        this.errors.splice(foundIndex, 1);
      }
    }

    override.modified = true;
  }

  restoreDefault() {
    this.triggerRestoreDefault().then((result) => {
      if (result.value) {
        this.deleteOverrides();
      }
    });
  }

  createOverrides(data) {
    this.isLoading = true;
    this.http
      .post('brainOverride/create', {
        datas: [...data],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('OperationSuccess'));
          this.editMode = !this.editMode;
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }

        this.isLoading = false;
        this.getBrainParams(this.site.id);
      });
  }

  deleteOverrides() {
    this.isLoading = true;
    this.http
      .post('brainOverride/delete', {
        data: {
          hoteId: this.site.id,
        },
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('OperationSuccess'));
          this.editMode = !this.editMode;
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }

        this.isLoading = false;
        this.getBrainParams(this.site.id);
      });
  }

  saveCollapsedGroups() {
    this.collapsedGroups = this.brainParams.filter((g) => g.open).map((g) => g.id);
  }

  triggerRestoreDefault() {
    return Swal.fire({
      title: 'Confirmation',
      html: this.translate.instant('RestoreDefaultAlert'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
    } as SweetAlertOptions);
  }

  formatBrainParams(brainTunables: BrainTunableDto[], brainOverrides: BrainOverrideDto[], brainDrivesGroups: Group[]) {
    this.brainTunables = brainTunables;
    this.brainOverrides = new Map<number, BrainOverride>();

    brainTunables.forEach((param) => {
      const override = brainOverrides.find((o) => o.brainTunableId == param.id);
      const value = override ? override.value : param.actor.includes('Priority') ? param.defaultValue : '';
      const overrided = !!override;
      this.brainOverrides.set(param.id, {
        brainTunableId: param.id,
        value,
        modified: false,
        overrided,
      });
    });

    const groupedByCodeAttack = _.groupBy(brainTunables, 'codesAttacks.id');
    const formattedParams = Object.keys(groupedByCodeAttack).map((index) => {
      const codeAttack = groupedByCodeAttack[index][0].codesAttacks;
      const params = groupedByCodeAttack[index].sort((a, b) => (a.actor ? a.actor.localeCompare(b.actor || '') : 0));

      const hasModifiedParam = params.some(
        (p) =>
          (!p.actor.includes('Priority') && this.brainOverrides.get(p.id).value !== '') ||
          (p.actor.includes('Priority') && p.defaultValue !== this.brainOverrides.get(p.id).value) ||
          this.brainOverrides.get(p.id).overrided,
      );

      return {
        groupId: codeAttack.brainDrivesGroup.id,
        label: codeAttack.label,
        desc: codeAttack.description,
        uuid: codeAttack.driveUuid,
        hasModifiedParam,
        params,
      };
    });

    const groupedByGroupId = _.groupBy(formattedParams, 'groupId');
    this.brainParams = Object.keys(groupedByGroupId).map((index) => {
      const group = brainDrivesGroups.find((g) => g.id == groupedByGroupId[index][0].groupId);
      const countModifiedDrives = groupedByGroupId[index].reduce(
        (total, e) => (e.hasModifiedParam ? total + 1 : total),
        0,
      );

      const drives = groupedByGroupId[index].map((d) => ({
        ...d,
        params: _.orderBy(d.params, ['type', 'label'], ['desc', 'asc']).reverse(),
      }));

      drives.sort((a, b) => sortAlphabetically(b.label, a.label));

      return {
        id: group.id,
        groupName: group.label,
        hasModifiedParam: drives.some((d) => d.hasModifiedParam),
        countModifiedDrives,
        open: group.id == 1 ? true : !!this.collapsedGroups.find((g) => g == group.id),
        drives,
      };
    });

    this.previousBrainOverrides = copyMap(this.brainOverrides);
  }

  cancelUpdate() {
    this.editMode = false;
    this.brainOverrides = copyMap(this.previousBrainOverrides);
  }

  formatData(data) {
    return Array.from(data)
      .filter((o: any) => {
        let bt = this.brainTunables.find((t) => t.id == o.brainTunableId);
        if (
          (!bt.actor.includes('Priority') && o.value !== '') ||
          (bt.actor.includes('Priority') &&
            (o.value === true || o.value === false || bt.defaultValue != o.value || o.overrided))
        ) {
          return o;
        }
      })
      .map((o: any) => {
        delete o.modified;

        // Priorities
        if (o.value === false) {
          o.value = 0;
        } else if (o.value === true) {
          o.value = this.brainTunables.find((t) => t.id == o.brainTunableId).defaultValue || 0.1;
        }

        o.hoteId = this.site.id;

        return o;
      });
  }

  labelContains(label, string) {
    return label.includes(string);
  }
}

interface BrainTunableDto {
  id: number;
  actor: string;
  type: Type;
  min: number;
  max: number;
  label: string;
  recommendedValue: number;
  codesAttacksId: number;
  visibility: Visibility;

  codesAttacks: CodesAttacks;
  defaultValue: number;
  value: number;
}

interface CodesAttacks {
  id: number;
  driveUuid: string;

  translation: string;
  label: string;
  description: string;

  brainDrivesGroup: Group;
}

interface Group {
  id: number;
  label: string;
}

type Visibility = 'expert' | 'adminCluster' | 'admin';
type Type = 'input' | 'toggleButton';

interface BrainOverrideDto {
  id: number;
  brainTunableId: number;
  value: number;
}

interface BrainOverride {
  brainTunableId: number;
  value: any;
  modified: boolean;
  overrided: boolean;
}

interface ParamGroup {
  id: number;
  groupName: string;
  open: boolean;
  hasModifiedParam: boolean;
  countModifiedDrives: number;
  drives: ParamsSubGroup[];
}

interface ParamsSubGroup {
  label: string;
  desc: string;
  groupId: number;
  uuid: string;
  hasModifiedParam: boolean;
  params: BrainTunableDto[];
}
