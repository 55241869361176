<div class="app-modal-body">
  <div class="card with-tabs p-3" style="overflow-x: auto">
    <div class="row">
      <div class="col-12">
        <h5 style="display: flex; align-items: center; justify-content: space-between">
          <div>
            {{ 'urlExceptions' | translate }}
          </div>
        </h5>
        <p style="margin-top: 10px" innerHTML="{{ 'UriExceptionAlert' | translate }}"></p>
        <hr />
      </div>
      <div class="col-md-12" style="margin-top: 10px; min-width: 1120px">
        <div class="drag-list">
          <div class="drag-container">
            <div class="add-site-table-header exceptions-header">
              <span style="margin-left: 30px"
                >{{ 'URL' | translate }}&nbsp;
                <a class="ogo-link" target="_blank" [href]="'ExternalHelp.regex' | translate"
                  >({{ 'RegexpGuide' | translate }}<i class="icofont icofont-external-link ml-1"></i>)</a
                >
              </span>
              <span>{{ 'Comments' | translate }}</span>
              <span>Actions</span>
            </div>
            <div *ngFor="let urlException of urlExceptions">
              <div class="drag-box no-drag">
                <span>
                  <span *ngIf="urlException.id && !urlException.editing">{{ urlException.path }}</span>
                  <input
                    #urlExceptionElement
                    *ngIf="!urlException.id || urlException.editing"
                    (keydown)="handleEnterUrlException($event, urlException)"
                    [(ngModel)]="urlException.path"
                    style="width: 350px; height: 33px"
                  />
                </span>
                <span>
                  <span [title]="urlException.comment" *ngIf="urlException.id && !urlException.editing">{{
                    urlException.comment
                  }}</span>
                  <textarea
                    *ngIf="!urlException.id || urlException.editing"
                    (keydown)="handleEnterUrlException($event, urlException)"
                    placeholder="{{ 'CommentsOptionnal' | translate }}"
                    [(ngModel)]="urlException.comment"
                    style="
                      height: 100%;
                      width: 100%;
                      position: relative;
                      top: -5px;
                      border: 1px solid #dfdfdf;
                      border-radius: 2px;
                    "
                    maxlength="255"
                  ></textarea>
                </span>
                <span>
                  <div class="flex-align-items">
                    <i
                      *ngIf="!urlException.editing && urlException.id"
                      (click)="
                        !isEditingUrlException() && !isCreatingUrlException() && startUrlExceptionEditing(urlException)
                      "
                      [ngStyle]="{
                        color: isEditingUrlException() || isCreatingUrlException() ? '#dbd9da' : '#4099ff',
                        cursor: isEditingUrlException() || isCreatingUrlException() ? 'default' : 'pointer'
                      }"
                      style="font-size: 19px; position: relative; top: 1px"
                      class="pointer icofont icofont-edit-alt mr-4"
                    ></i>
                    <i
                      *ngIf="urlException.editing"
                      (click)="validateUrlExceptionEditing(urlException)"
                      title="{{ 'Validate' | translate }}"
                      [ngStyle]="{
                        color: urlException.path.length == 0 ? '#dbd9da' : '#4099ff',
                        cursor: urlException.path.length == 0 ? 'default' : 'pointer'
                      }"
                      style="font-size: 27px"
                      class="pointer icofont icofont-check mr-3"
                    ></i>
                    <i
                      *ngIf="urlException.editing"
                      (click)="deleteUrlException(urlException)"
                      title="{{ 'supprimer' | translate }}"
                      style="color: #e74c3c; font-size: 22px; top: -1px"
                      class="pointer icofont icofont-close"
                    ></i>
                    <i
                      *ngIf="urlException.id && !urlException.editing"
                      (click)="deleteUrlException(urlException)"
                      title="{{ 'supprimer' | translate }}"
                      style="color: #e74c3c; font-size: 19px; top: -1px"
                      class="pointer icofont icofont-ui-delete"
                    ></i>
                    <i
                      *ngIf="!urlException.id"
                      (click)="urlException.path.length && createUrlException(urlException)"
                      title="{{ 'ajouter' | translate }}"
                      [ngStyle]="{
                        color: urlException.path.length ? '#4099ff' : '#dbd9da',
                        cursor: urlException.path.length ? 'pointer' : 'default'
                      }"
                      style="font-size: 27px"
                      class="pointer icofont icofont-check mr-3"
                    ></i>
                    <i
                      *ngIf="!urlException.id"
                      (click)="deleteUrlException(urlException)"
                      title="{{ 'Annuler' | translate }}"
                      style="color: #e74c3c; font-size: 24px"
                      class="pointer icofont icofont-close"
                    ></i>
                  </div>
                </span>
              </div>
            </div>
            <div *ngIf="!urlExceptions.length" style="text-align: center; padding: 15px">
              {{ 'NoUrlExceptions' | translate }}
            </div>
          </div>
        </div>
        <br />
        <div style="margin-bottom: 10px">
          <a *ngIf="!isCreatingUrlException() && !isEditingUrlException()" class="ogo-link" (click)="addUrlException()"
            >+ {{ 'AddUrlException' | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="card p-3" style="overflow-x: auto">
    <div class="row">
      <div class="col-12">
        <h5 style="display: flex; align-items: center; justify-content: space-between">
          <div>
            {{ 'ListeBlancheIp' | translate }}
          </div>
        </h5>
        <p style="margin-top: 10px" innerHTML="{{ 'WhitelistedIpBadge' | translate }}"></p>
        <hr />
      </div>
      <div class="col-md-12" style="margin-top: 10px; min-width: 1120px">
        <div class="drag-list">
          <div class="drag-container">
            <div class="add-site-table-header exceptions-header">
              <span style="margin-left: 30px"
                >{{ 'IPs' | translate }}&nbsp;
                <label
                  class="badge badge-sm bg-dark"
                  placement="right"
                  container="body"
                  [ngbTooltip]="whitelistedIPTempExp"
                  >?</label
                >
                <ng-template #whitelistedIPTempExp>
                  <div innerHTML="{{ 'WhitelistedIPExemple' | translate }}"></div>
                </ng-template>
              </span>
              <span>{{ 'Comments' | translate }}</span>
              <span>Actions</span>
            </div>
            <div *ngFor="let whitelistedIp of whitelistedIps">
              <div class="drag-box no-drag">
                <span>
                  <span *ngIf="!whitelistedIp.new && !whitelistedIp.editing">{{ whitelistedIp.ip }}</span>
                  <input
                    #whitelistedIpElement
                    *ngIf="whitelistedIp.new || whitelistedIp.editing"
                    (keydown)="handleEnterWhitelistedIp($event, whitelistedIp)"
                    [(ngModel)]="whitelistedIp.ip"
                    style="width: 350px; height: 33px"
                  />
                </span>
                <span>
                  <span [title]="whitelistedIp.comment" *ngIf="!whitelistedIp.new && !whitelistedIp.editing">{{
                    whitelistedIp.comment
                  }}</span>
                  <textarea
                    *ngIf="whitelistedIp.new || whitelistedIp.editing"
                    (keydown)="handleEnterWhitelistedIp($event, whitelistedIp)"
                    placeholder="{{ 'CommentsOptionnal' | translate }}"
                    [(ngModel)]="whitelistedIp.comment"
                    style="
                      height: 100%;
                      width: 100%;
                      position: relative;
                      top: -5px;
                      border: 1px solid #dfdfdf;
                      border-radius: 2px;
                    "
                    maxlength="255"
                  ></textarea>
                </span>
                <span>
                  <div class="flex-align-items">
                    <i
                      *ngIf="!whitelistedIp.editing && !whitelistedIp.new"
                      (click)="
                        !isEditingWhitelistedIp() &&
                          !isCreatingWhitelistedIp() &&
                          startWhitelistedIpEditing(whitelistedIp)
                      "
                      [ngStyle]="{
                        color: isEditingWhitelistedIp() || isCreatingWhitelistedIp() ? '#dbd9da' : '#4099ff',
                        cursor: isEditingWhitelistedIp() || isCreatingWhitelistedIp() ? 'default' : 'pointer'
                      }"
                      style="font-size: 19px; position: relative; top: 1px"
                      class="pointer icofont icofont-edit-alt mr-4"
                    ></i>
                    <i
                      *ngIf="whitelistedIp.editing"
                      (click)="validateWhitelistedIpEditing(whitelistedIp)"
                      title="{{ 'Validate' | translate }}"
                      [ngStyle]="{
                        color: whitelistedIp.ip.length == 0 ? '#dbd9da' : '#4099ff',
                        cursor: whitelistedIp.ip.length == 0 ? 'default' : 'pointer'
                      }"
                      style="font-size: 27px"
                      class="pointer icofont icofont-check mr-3"
                    ></i>
                    <i
                      *ngIf="whitelistedIp.editing"
                      (click)="deleteWhitelistedIp(whitelistedIp)"
                      title="{{ 'supprimer' | translate }}"
                      style="color: #e74c3c; font-size: 22px; top: -1px"
                      class="pointer icofont icofont-close"
                    ></i>
                    <i
                      *ngIf="!whitelistedIp.new && !whitelistedIp.editing"
                      (click)="deleteWhitelistedIp(whitelistedIp)"
                      title="{{ 'supprimer' | translate }}"
                      style="color: #e74c3c; font-size: 19px; top: -1px"
                      class="pointer icofont icofont-ui-delete"
                    ></i>
                    <i
                      *ngIf="whitelistedIp.new"
                      (click)="whitelistedIp.ip.length && createWhitelistedIp(whitelistedIp)"
                      title="{{ 'ajouter' | translate }}"
                      [ngStyle]="{
                        color: whitelistedIp.ip.length ? '#4099ff' : '#dbd9da',
                        cursor: whitelistedIp.ip.length ? 'pointer' : 'default'
                      }"
                      style="font-size: 27px"
                      class="pointer icofont icofont-check mr-3"
                    ></i>
                    <i
                      *ngIf="whitelistedIp.new"
                      (click)="deleteWhitelistedIp(whitelistedIp)"
                      title="{{ 'Annuler' | translate }}"
                      style="color: #e74c3c; font-size: 24px"
                      class="pointer icofont icofont-close mr-1"
                    ></i>
                  </div>
                </span>
              </div>
            </div>
            <div *ngIf="!whitelistedIps.length" style="text-align: center; padding: 15px">
              {{ 'NoIPExceptions' | translate }}
            </div>
          </div>
        </div>
        <br />
        <div style="margin-bottom: 10px">
          <a
            *ngIf="!isCreatingWhitelistedIp() && !isEditingWhitelistedIp()"
            class="ogo-link"
            (click)="addWhitelistedIp()"
            >+ {{ 'AddIpException' | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
