<div class="row form-group flex-align-items m-t-20">
  <app-label class="col-xl" icon="tags" label="Tags" tooltip="TagsTooltip" tooltipPlacement="top" />
  <div class="col-xl">
    <ng-select
      id="site-tags"
      [items]="tags"
      [multiple]="true"
      [closeOnSelect]="true"
      [placeholder]="'TagsPlaceholder' | translate"
      autocomplete="on"
      [(ngModel)]="selected"
      [addTagText]="'NewTag' | translate"
      [addTag]="true"
      [notFoundText]="'NoTag' | translate"
      (change)="this.selectedChange.emit($event)"
    ></ng-select>
  </div>
</div>
