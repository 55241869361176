import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent } from '@siemens/ngx-datatable';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { PageView } from 'app/enums/enums';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-elasticsearch',
  templateUrl: './elasticsearch.component.html',
  styleUrls: ['./elasticsearch.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class ElasticsearchComponent implements OnInit {
  lang: string;
  elasticsearch: any = {};
  elasticsearchs: any = [];
  pageViews = PageView;
  showView: PageView = PageView.LIST;
  @ViewChild('myTable') set datatable(table: DatatableComponent) {
    table.columnMode = ColumnMode.force; // make resizing table header working
  }
  @ViewChild('modal') modal: any;
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
    this.getElasticsearch();
  }

  editEs(p: any) {
    this.elasticsearch = {};
    this.showView = PageView.CREATE;
    this.elasticsearch = p === undefined ? {} : { ...p };
  }

  getElasticsearch() {
    this.http
      .post('elasticsearch/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.elasticsearchs = res.items;
        }
      });
  }

  onCreateEs(p: any) {
    if (p.nom === undefined || p.nom.trim() === '') {
      this.toastr.error(this.translate.instant('SaisirLeLibelle'));
      return;
    }
    if (p.endpoint === undefined || p.endpoint.trim() === '') {
      this.toastr.error(this.translate.instant('SaisirLendpoint'));
      return;
    }
    this.http
      .post('elasticsearch/create', {
        datas: [
          {
            id: p.id,
            nom: p.nom,
            endpoint: p.endpoint,
            endpointEs: p.endpointEs,
            credentialUsername: p.credentialUsername,
            credentialPassword: p.credentialPassword,
            clusterName: p.clusterName,
            indexName: p.indexName,
            defaultClauseCount: p.defaultClauseCount,
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.showView = PageView.LIST;
          this.getElasticsearch();
          this.toastr.success(this.translate.instant('EnregistrementReussi'));
        }
      });
  }

  onUpdateEs(p: any) {
    this.http
      .post('elasticsearch/update', {
        datas: [
          {
            id: p.id,
            nom: p.nom,
            endpoint: p.endpoint,
            endpointEs: p.endpointEs,
            credentialUsername: p.credentialUsername,
            credentialPassword: p.credentialPassword,
            clusterName: p.clusterName,
            indexName: p.indexName,
            defaultClauseCount: p.defaultClauseCount,
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.showView = PageView.LIST;
          this.getElasticsearch();
          this.toastr.success(this.translate.instant('MiseAJourReussie'));
        } else {
          this.toastr.error(res.status.message);
        }
      });
  }

  onDeleteEs(p: any) {
    const req = {
      datas: [p],
    };
    this.http.post('elasticsearch/delete', req).subscribe((res: any) => {
      if (!res.hasError) {
        this.getElasticsearch();
        this.toastr.success(this.translate.instant('SuppressionSucces'));
      } else {
        this.toastr.error(this.translate.instant('SuppressionEchoue'));
      }
    });
  }
}
