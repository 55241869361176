import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ContractSitesComponent } from './contracts/add-edit-contract/contract-sites/contract-sites.component';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { TablePagerComponent } from '../../shared/table-pager/table-pager.component';
import { ContractClustersComponent } from './contracts/add-edit-contract/contract-clusters/contract-clusters.component';
import { ContractOrganizationsComponent } from './contracts/add-edit-contract/contract-organizations/contract-organizations.component';
import { NgSelectComponent } from '@ng-select/ng-select';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, AdminRoutingModule, NgxDatatableModule, NgSelectComponent],
  declarations: [ContractClustersComponent, ContractSitesComponent, ContractOrganizationsComponent],
  exports: [ContractClustersComponent, ContractSitesComponent, ContractOrganizationsComponent],
})
export class AdminModule {}
