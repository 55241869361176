import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CodeInputModule } from 'angular-code-input';
import { MfaRoutingModule } from './mfa-routing.module';
import { MfaComponent } from './mfa.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MfaRoutingModule, CodeInputModule, TranslateModule],
  declarations: [MfaComponent],
})
export class MfaModule {}
