import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash';
import { ASSETS_HTTP_CLIENT } from '../app.module';

export type Country = { code: string; name: string };

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countries: { [prop: string]: CountryNames } = {};

  constructor(
    private auth: AuthService,
    @Inject(ASSETS_HTTP_CLIENT) private http: HttpClient,
  ) {}

  async init() {
    const lang = this.auth.getCurrentLanguage();
    if (!this.countries[lang]) {
      this.countries[lang] = _(
        await firstValueFrom(this.http.get<CountryRefData[]>('/assets/data/countries_' + lang + '.json')),
      )
        .map((c) => [c.alpha2.toUpperCase(), c.name])
        .fromPairs()
        .value();
    }
  }

  getCountryName(code: string): string {
    return this.countries[this.auth.getCurrentLanguage()][code] ?? code;
  }

  getCountries(): Country[] {
    return _(_.toPairs(this.countries[this.auth.getCurrentLanguage()]))
      .map(([code, name]) => ({ code, name }))
      .sortBy((c) => c.name)
      .value();
  }

  getCountryNames(): CountryNames {
    return { ...this.countries[this.auth.getCurrentLanguage()] };
  }
}

export type CountryNames = { [prop: string]: string };

interface CountryRefData {
  alpha2: string;
  name: string;
}
