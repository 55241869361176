import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionAnchorDirective } from './accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './accordion/accordionlink.directive';
import { AccordionDirective } from './accordion/accordion.directive';
import { CardComponent } from './card/card.component';
import { CardToggleDirective } from './card/card-toggle.directive';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DonutComponent } from './highcharts/donut/donut.component';
import { GaugeDirective } from './highcharts/gauge/gauge.directive';
import { GraphComponent } from './highcharts/graph/graph.component';
import { FormsModule } from '@angular/forms';
import {
  FilterArrayPipe,
  DateFormatPipe,
  DurationPipe,
  DurationSecondsPipe,
  NumberFormatPipe,
} from './pipe/filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderComponent } from './http-loader/http-loader.component';
import { AccessDirective } from './access/access.directive';
import { ReversePipe } from './utils/reverse.directive';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tabs/tab.component';
import { SiteTagsComponent } from './tags/site-tags.component';
import { JsonViewer } from './json-viewer/json-viewer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeatureFlagDirective } from './utils/feature-flag.directive';
import { BarHorizontalComponent } from './highcharts/bar-horizontal/bar-horizontal.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { BadgeOrganizationComponent } from './badge-organization/badge-organization.component';
import { TimestampCellComponent } from './timestamp-cell/timestamp-cell.component';
import { LabelComponent } from './label/label.component';
import { SwitchComponent } from './switch/switch.component';
import { AdminOrganizationSelectorComponent } from './admin-organization-selector/admin-organization-selector.component';
import { AdminSiteSelectorComponent } from './admin-site-selector/admin-site-selector.component';
import { AdminClusterSelectorComponent } from './admin-cluster-selector/admin-cluster-selector.component';
import { TablePagerComponent } from './table-pager/table-pager.component';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { SiteStatusComponent } from './site-status/site-status.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ClickOutsideModule,
    FormsModule,
    NgSelectModule,
    HighchartsChartModule,
    TranslateModule,
    NgxDatatableModule,
  ],
  exports: [
    NgbModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    BadgeOrganizationComponent,
    CardToggleDirective,
    AccessDirective,
    FeatureFlagDirective,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    HttpLoaderComponent,
    ClickOutsideModule,
    DonutComponent,
    GaugeDirective,
    GraphComponent,
    DateFormatPipe,
    DurationPipe,
    DurationSecondsPipe,
    NumberFormatPipe,
    TranslateModule,
    ReversePipe,
    TabComponent,
    TabsComponent,
    SiteTagsComponent,
    JsonViewer,
    BarHorizontalComponent,
    FilterArrayPipe,
    LabelComponent,
    SwitchComponent,
    TimestampCellComponent,
    AdminOrganizationSelectorComponent,
    AdminSiteSelectorComponent,
    AdminClusterSelectorComponent,
    SiteStatusComponent,
    TablePagerComponent,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    BadgeOrganizationComponent,
    CardToggleDirective,
    AccessDirective,
    FeatureFlagDirective,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    HttpLoaderComponent,
    DonutComponent,
    GaugeDirective,
    GraphComponent,
    DateFormatPipe,
    DurationPipe,
    DurationSecondsPipe,
    NumberFormatPipe,
    ReversePipe,
    TabComponent,
    TabsComponent,
    SiteTagsComponent,
    JsonViewer,
    BarHorizontalComponent,
    FilterArrayPipe,
    LabelComponent,
    SwitchComponent,
    TimestampCellComponent,
    AdminOrganizationSelectorComponent,
    AdminSiteSelectorComponent,
    AdminClusterSelectorComponent,
    SiteStatusComponent,
    TablePagerComponent,
  ],
  providers: [],
})
export class SharedModule {}
