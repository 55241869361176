<div class="col-12">
  <div class="card">
    <div class="card-header" style="padding-bottom: 0">
      <h4 class="modal-title">
        {{ (mode == EditMode.UPDATE ? 'Contract.edit' : 'Contract.add') | translate }}
      </h4>
      <hr />
    </div>
    <div *ngIf="contract" class="card-body" style="padding-top: 0">
      <div class="row">
        <div class="col-xl-3">
          <div class="form-group">
            <label class="required" [innerHtml]="'Contract.name' | translate"></label>
            <input class="form-control" [(ngModel)]="contract.name" name="name" />
          </div>
          <div *ngIf="mode == EditMode.UPDATE" class="form-group">
            <label [innerHtml]="'Contract.number' | translate"></label>
            <input class="form-control" [(ngModel)]="contract.number" disabled />
          </div>
          <div class="form-group">
            <label class="required" [innerHtml]="'Contract.holder' | translate"></label>
            <app-admin-organization-selector [(model)]="contract.holder" />
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label [innerHtml]="'Contract.startDate' | translate"></label>
            <div class="input-group date">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="d"
                #c="ngModel"
                [(ngModel)]="contract.startDate"
                [navigation]="'arrows'"
                ngbDatepicker
                #d="ngbDatepicker"
              />
              <button
                class="btn btn-outline-secondary bi bi-calendar3 icofont icofont-ui-calendar mr-2"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
          <div class="form-group">
            <label>{{ 'Contract.millionRequestsPerMonth' | translate }}</label>
            <input class="form-control" [(ngModel)]="contract.millionRequestsPerMonth" type="number" min="0" />
          </div>
          <div class="form-group">
            <label>{{ 'Contract.nbSites' | translate }}</label>
            <input class="form-control" [(ngModel)]="contract.nbSites" type="number" min="0" />
          </div>
        </div>
        <app-contract-organizations *ngIf="mode == EditMode.UPDATE" [contractId]="contract.id" class="col-xl-6" />
        <app-contract-sites *ngIf="mode == EditMode.UPDATE" [contractId]="contract.id" class="col-xl-12 mt-3" />
        <app-contract-clusters *ngIf="mode == EditMode.UPDATE" [contractId]="contract.id" class="col-xl-12 mt-3" />
      </div>
    </div>
    <div class="card-footer">
      <button
        *ngIf="mode == EditMode.CREATE"
        [disabled]="!canValidate()"
        (click)="createContract()"
        class="btn btn-sm btn-primary mr-2"
        [innerHtml]="'ajouter' | translate"
      ></button>
      <button
        *ngIf="mode == EditMode.UPDATE"
        [disabled]="!canValidate()"
        (click)="updateContract()"
        class="btn btn-sm btn-primary mr-2"
        [innerHtml]="'Enregistrer' | translate"
      ></button>
      <button
        (click)="contract = null; back()"
        class="btn btn-sm btn-danger"
        [innerHtml]="'Annuler' | translate"
      ></button>
    </div>
  </div>
</div>
