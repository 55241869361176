<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form name="form" #form="ngForm" (ngSubmit)="resetPassword(form)" class="md-float-material">
            <div class="text-center" class="ogo-logo-login">
              <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">
                <img src="assets/images/logo.png" alt=".OGO Security" />
              </a>
            </div>
            <div class="auth-box" *ngIf="tokenFound">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary">{{ 'NouveauMotDePasse' | translate }}</h3>
                </div>
              </div>
              <div class="input-group">
                <input
                  name="password"
                  type="password"
                  ngModel
                  class="form-control"
                  [placeholder]="'Password' | translate"
                  data-form-type="password,new"
                />
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <input
                  name="confirm"
                  type="password"
                  ngModel
                  class="form-control"
                  [placeholder]="'ConfirmationNouveauMotdePasse' | translate"
                  data-form-type="password,confirmation"
                />
                <span class="md-line"></span>
              </div>
              <div class="row m-t-25 text-left text-inverse">
                <div class="col-12">{{ 'PasswordRestrictions' | translate }}</div>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">
                    {{ 'Login' | translate }}
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left m-b-0">
                    <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">www.ogosecurity.com</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="login-card card-block auth-body mr-auto ml-auto" *ngIf="!tokenFound">
              <div class="auth-box txt-primary expiry">
                <p>{{ 'URLExpireResetPassword' | translate }}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
