<div class="card with-tabs">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-8 mt-2">
        <h4 class="mb-0">
          {{ 'OrganizationParameters' | translate }}
          <label class="badge badge-sm bg-dark"
            ><a style="color: inherit" target="_blank" [href]="'ExternalHelp.organizations' | translate">?</a></label
          >
        </h4>
      </div>
      <div *ngIf="auth.userHasFeature(ACL_ORGANIZATION_MANAGE)" class="col-md-4">
        <button
          type="button"
          (click)="updateOrganization()"
          [hidden]="!editField"
          class="btn btn-primary btn-sm pull-right"
          [innerHTML]="'Enregistrer' | translate"
        ></button>
        <button
          type="button"
          (click)="organization = previousOrganization; editField = false"
          [hidden]="!editField"
          class="btn btn-danger btn-sm pull-right mr-2"
          [innerHTML]="'Annuler' | translate"
        ></button>
        <button
          type="button"
          (click)="onEditField()"
          [hidden]="editField"
          class="btn btn-primary btn-sm pull-right"
          [innerHTML]="'Modifier' | translate"
        ></button>
      </div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col-md-8 mt-2 mb-3">
        <h5 [innerHTML]="'Informations' | translate"></h5>
      </div>
      <div class="col-md-12">
        <div class="form-group row">
          <app-label label="OrganizationCode" tooltip="OrganizationCodeTooltip" class="col" />
          <div class="col-sm-6 flex-align-items">
            <app-badge-organization [code]="organization.code"></app-badge-organization>
          </div>
        </div>
        <div class="form-group row">
          <app-label label="OrganizationName" class="col" />
          <div class="col-md-6">
            <input
              type="text"
              style="font-weight: bold"
              [(ngModel)]="organization.companyName"
              name="companyName"
              [disabled]="!editField"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <app-label label="Owner" class="col" />
          <div class="col-md-6">
            <input
              [hidden]="editField && auth.userHasFeature(ACL_ORGANIZATION_OWNER_MANAGE)"
              type="text"
              [(ngModel)]="organization.owner.email"
              name="email"
              disabled
              class="form-control"
            />
            <ng-select
              [hidden]="!editField || !auth.userHasFeature(ACL_ORGANIZATION_OWNER_MANAGE)"
              #searchUsers
              bindLabel="email"
              bindValue="email"
              [items]="users"
              [multiple]="false"
              [closeOnSelect]="true"
              [(ngModel)]="organization.owner.email"
              notFoundText="{{ 'Min3Chars' | translate }}"
            >
            </ng-select>
          </div>
        </div>
        <div class="form-group row">
          <app-label label="Rue" class="col" />
          <div class="col-md-6">
            <input
              type="text"
              [(ngModel)]="organization.street"
              name="rue"
              [disabled]="!editField"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <app-label label="CodePostal" class="col" />
          <div class="col-md-6">
            <input
              type="text"
              [(ngModel)]="organization.zipCode"
              name="codepostal"
              [disabled]="!editField"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <app-label label="Ville" class="col" />
          <div class="col-md-6">
            <input
              [(ngModel)]="organization.city"
              type="text"
              name="ville"
              [disabled]="!editField"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <app-label label="Pays" class="col" />
          <div class="col-md-6">
            <select
              [(ngModel)]="organization.countryCode"
              name="countries"
              class="form-control"
              [disabled]="!editField"
            >
              <option *ngFor="let p of countries" [value]="p.code">{{ p.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
