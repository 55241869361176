import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { AdminSiteService } from '../../services/admin-site.service';

@Component({
  selector: 'app-admin-site-selector',
  standalone: false,

  templateUrl: './admin-site-selector.component.html',
  styleUrl: './admin-site-selector.component.scss',
})
export class AdminSiteSelectorComponent {
  @Input() model: Site;
  @Input() filters: { [k: string]: any };
  @Output() modelChange = new EventEmitter<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private adminSiteService: AdminSiteService) {}

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) => {
        return term.length < 2
          ? of([])
          : this.adminSiteService.getSites$({ ...this.filters, filter: term }).pipe(
              catchError((error) => {
                console.error(error);
                return of({ content: [] as Site[] });
              }), // empty list on error
              map((page) => page.content),
            );
      }),
    );
  };

  formatter = (site: Site) => site.name;
}

export interface Site {
  name: string;
}
