import { Component, Input } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'app-label',
  standalone: false,

  template: `<label class="col-form-label"
      ><i *ngIf="icon" [class]="['icofont', 'icofont-' + icon]"></i>{{ label | translate }}
      <span *ngIf="tooltip" class="badge badge-sm bg-dark" [placement]="tooltipPlacement" [ngbTooltip]="labelTooltip"
        >?</span
      ></label
    >
    <ng-template #labelTooltip>
      <div innerHTML="{{ tooltip | translate }}"></div>
    </ng-template>`,
  styleUrls: ['../../../assets/icon/icofont/css/icofont.scss'],
  styles: `
    label {
      padding-right: 1ex;
    }
    .icofont {
      font-size: 20px;
      position: relative;
      top: 3px;
    }
  `,
})
export class LabelComponent {
  @Input({ required: true }) label: string;
  @Input() icon?: string;
  @Input() tooltip?: string;
  @Input() tooltipPlacement?: PlacementArray = 'right';
}
