import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import 'daterangepicker';
import $ from 'jquery';
import moment from 'moment';
import { CalendarDataService, DATE_NOW, RANGE_PRESETS, RangePreset } from './calendar-data.service';
import { ContextEnum } from 'app/services/sites.service';
import { TranslateService } from '@ngx-translate/core';
import { DateRange, DynamicRange } from '../utils/date-range';

@Directive({
  selector: '[app-date-range-selector]',
  standalone: false,
})
export class DateRangeSelectorDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() alwaysShowCalendars = true;
  @Input() detectChanges = true;
  @Input() timePicker: boolean;
  @Input() ranges: RangePreset[] = ['T', 'Y', '7d', '30d', '60d', '90d'];
  @Input() minDateDays;
  @Input() range: DateRange = RANGE_PRESETS['T'];
  @Output() rangeChange = new EventEmitter<DateRange>();
  @Input() ctx: ContextEnum;

  calendarId: string;
  open: boolean = false;

  constructor(
    private calendarData: CalendarDataService,
    private el: ElementRef,
    private translate: TranslateService,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.detectChanges) {
      if (changes.range) {
        this.range = changes?.range?.currentValue;
        this.showRange();
        const picker = this.getElement()?.data('daterangepicker');
        if (picker) {
          if (this.range instanceof DynamicRange) {
            picker.setChosenLabel(this.range.code);
          } else {
            picker.setStartDate(this.range.start);
            picker.setEndDate(this.range.end);
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.calendarId = '#' + this.el.nativeElement.id;
    const element = this.getElement();

    let options = {
      timePicker: this.timePicker,
      timePicker24Hour: true,
      startDate: this.range.start,
      endDate: this.range.end,
      opens: 'left',
      maxDate: {}, // now
      showDropdowns: true,
      alwaysShowCalendars: this.alwaysShowCalendars,
      ranges: this.calendarData.getRangesTranslations(this.ranges),
      locale: this.calendarData.getOptions(),
      showCustomRangeLabel: false,
    };

    if (this.minDateDays) {
      options['minDate'] = DATE_NOW().subtract(this.minDateDays, 'days');
    }

    element.daterangepicker(options);

    this.showRange();

    $(this.calendarId).on('click', function (e) {
      this.open = !this.open;
      if (this.open) this.getElement?.remove();
    });

    $(this.calendarId).on('apply.daterangepicker', (_: any, picker: any) => {
      this.handleChange(picker.startDate, picker.endDate, picker.chosenLabel);
    });
  }

  private getElement() {
    return $(this.calendarId);
  }

  handleChange(start, end, chosenLabel) {
    this.range = chosenLabel ? RANGE_PRESETS[chosenLabel] : new DateRange(start, end);
    this.showRange();
    this.rangeChange.emit(this.range);
  }

  sameMinute(d1, d2) {
    return Math.abs(moment(d1).diff(d2, 'minutes')) <= 1;
  }

  showRange(): void {
    let dateToPrint = null;

    if (this.range instanceof DynamicRange) {
      dateToPrint = this.translate.instant('Calendar.range.' + this.range.code);
    } else {
      dateToPrint =
        this.range.start.format('DD MMM YYYY HH:mm') +
        this.translate.instant('Calendar.separator') +
        this.range.end.format('DD MMM YYYY HH:mm') +
        '&nbsp;';
    }
    $(this.calendarId + ' span').html(dateToPrint);
  }
}
