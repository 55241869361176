import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'app/shared/toastr/toastr.service';

@Component({
  selector: 'app-my-notifications',
  templateUrl: './my-notifications.component.html',
  styleUrls: ['./my-notifications.component.scss'],
  standalone: false,
})
export class MyNotificationsComponent implements OnInit {
  notifications = {
    monthlyReport: false,
    eventNotificationMinSeverities: {
      SECURITY: null,
      MONITORING: null,
    },
  };

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.http.post('notifications/get', {}).subscribe((res: any) => {
      if (!res.hasError) {
        this.notifications.monthlyReport = res.items[0].monthlyReport || false;
        this.notifications.eventNotificationMinSeverities = res.items[0].eventNotificationMinSeverities;
      }
    });
  }

  changeNotification(notification, event) {
    const data = {
      data: {},
    };

    data.data[notification] = event;

    this.http.post('notifications/update', data).subscribe((res: any) => {
      if (!res.hasError) {
        this.toastr.success(this.translate.instant('OperationSuccess'));
      }
    });
  }

  changeEventNotification(eventType, value) {
    const data = {
      data: { eventNotificationMinSeverities: { [eventType]: this.handleNull(value) } },
    };

    this.http.post('notifications/update', data).subscribe((res: any) => {
      if (!res.hasError) {
        this.toastr.success(this.translate.instant('OperationSuccess'));
      }
    });
  }

  handleNull(value: any) {
    return value === 'null' ? null : value;
  }
}
