import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat, formatDuration, formatHumanReadableDate, formatNumber } from '../utils/data-utils';
import { AuthService } from 'app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'duration',
  pure: true,
  standalone: false,
})
export class DurationPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(value: number): any {
    return formatDuration(value, this.auth.getCurrentLanguage());
  }
}

@Pipe({
  name: 'insertSpaces',
  pure: true,
  standalone: true,
})
export class InsertSpacesPipe implements PipeTransform {
  transform(value: string): string {
    return value.match(/.{1,4}/g).join(' ');
  }
}

@Pipe({
  name: 'durationSeconds',
  pure: true,
  standalone: false,
})
export class DurationSecondsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): any {
    if (!value || isNaN(value)) {
      return value;
    }

    if (value < 60) {
      return Math.round(value) + ' ' + this.translate.instant('Duration.abbrev.second');
    } else if (value < 3600) {
      return (value / 60).toFixed(2).replace(/\.?0+$/, '') + ' ' + this.translate.instant('Duration.abbrev.minute');
    } else if (value < 86400) {
      return (value / 3600).toFixed(2).replace(/\.?0+$/, '') + ' ' + this.translate.instant('Duration.abbrev.hour');
    } else {
      return (value / 86400).toFixed(2).replace(/\.?0+$/, '') + ' ' + this.translate.instant('Duration.abbrev.day');
    }
  }
}
@Pipe({
  name: 'dateFormat',
  pure: true,
  standalone: false,
})
export class DateFormatPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(date: any, format?: DateFormat): string {
    return formatHumanReadableDate(date, this.auth.getCurrentLanguage(), format || 'long');
  }
}

@Pipe({
  name: 'numberFormat',
  pure: true,
  standalone: false,
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(size: any): string {
    return formatNumber(size, this.auth.getCurrentLanguage());
  }
}

@Pipe({
  name: 'filterArray',
  pure: false,
  standalone: false,
})
export class FilterArrayPipe implements PipeTransform {
  transform<T>(items: T[], callback: (item: T) => boolean): T[] {
    if (!items || !callback) {
      return items;
    }
    return items.filter((item) => callback(item));
  }
}
