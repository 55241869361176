import { SiteMode } from '../../shared/hostMode/hostMode.service';

export class Organization {
  id: number;
  companyName: string;
  createdAt: number;
  updatedAt: number;
  street: string;
  zipCode: string;
  city: string;
  countryCode: string;
  owner: {
    email: string;
  };
  code: string;
  clusters: OrganizationCluster[];
  users: [];
  role: string;
  privileges: string[];
  selected: boolean;
  pushLog: any;
  nbUsers: number;

  constructor(organization) {
    this.id = organization.id;
    this.companyName = organization.companyName;
    this.createdAt = organization.createdAt;
    this.updatedAt = organization.updatedAt;
    this.street = organization.street;
    this.zipCode = organization.zipCode;
    this.city = organization.city;
    this.countryCode = organization.countryCode;
    this.owner = { email: organization?.owner?.email };
    this.clusters = organization.clusters;
    this.users = organization.users;
    this.code = organization.code;
  }
}

export interface Cluster {
  id: number;
  clusterId: string;
  name: string;
  ip: string;
  ipv6?: string;
  defaultSiteMode: SiteMode;
  supportsIpv6Origins: boolean;
  supportsCache: boolean;
  ipsToWhitelist: string[];
}

export interface OrganizationCluster {
  cluster: Cluster;
  role: ClusterRole;
}

export type ClusterRole = 'USE' | 'ADMIN' | 'OWNER';
