import { HttpParams } from '@angular/common/http';

export interface Page<T> {
  content: Array<T>;
  totalItems: number;
}

export type PageRequest = { [key in 'number' | 'size']?: number };

export interface SpringPage<T> {
  content: Array<T>;
  totalElements: number;
}

export function getPageParams(page: PageRequest): HttpParams {
  return new HttpParams({ fromObject: { page: page?.number ?? 0, size: page?.size ?? 10 } });
}
