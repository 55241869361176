import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { firstValueFrom, fromEvent, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CONTEXT, SitesService } from 'app/services/sites.service';
import { HostModeService, SiteMode } from 'app/shared/hostMode/hostMode.service';
import {
  ACL_SITE_ACTIONS,
  ACL_SITE_EDIT,
  AuthService,
  SHARE_ACCESS_RIGHT_ADMIN,
  SHARE_ACCESS_RIGHT_VIEW,
} from '../../services/auth.service';
import { ADMIN_CLUSTER_LOGS_ROUTE, MY_LOGS_ROUTE } from 'app/app-routing.module';
import { validateEmail, validateOrganizationCode } from '../../shared/validators/validators';
import { KeyOfType } from '../../shared/utils/type-utils';
import { OrganizationSiteService, SiteShare } from '../../services/organization-site.service';
import { ModalBasicComponent } from '../../shared/modal-basic/modal-basic.component';

@Component({
  selector: 'app-my-sites',
  templateUrl: './my-sites.component.html',
  styleUrls: ['./my-sites.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [style({ opacity: 0 }), animate('400ms ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ transform: 'translate(0)' }), animate('400ms ease-in-out', style({ opacity: 0 }))]),
    ]),
  ],
  standalone: false,
})
export class MySitesComponent implements OnInit, AfterViewInit {
  @ViewChild('AddSiteModal') addSiteModal: any;
  @ViewChild('addDomainModal') addDomainModal: any;
  @ViewChild('modalShare') modalShare: ModalBasicComponent;
  @ViewChild('tagInput') tagInput: any;
  @ViewChild('tagInputUrlExcept') tagInputUrlExcept: any;
  @ViewChild('modalDelete') modalDelete: any;
  @ViewChild('modalMultipleAuditPanic') modalMultipleAuditPanic: any;
  @ViewChild('modalMultipleShares') modalMultipleShares: any;
  @ViewChild('modalMultipleDeletes') modalMultipleDeletes: any;
  @ViewChild('modalMultipleCertificat') modalMultipleCertificat: any;
  @ViewChild('modalMultipleTags') modalMultipleTags: any;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('loadPkcs12') loadPkcs12: any;
  @ViewChild('addMultiShareInput') addMultiShareInput: NgSelectComponent;
  @ViewChild('addShareInput') addShareInput: NgSelectComponent;

  lang: string;

  adminClusterView: boolean;

  currentShares: SiteShare[] = [];
  shareInput = [];
  lastShareInput = '';
  shareTooltip = false;
  sharedRole = SHARE_ACCESS_RIGHT_VIEW;
  multipleSharedRole = SHARE_ACCESS_RIGHT_VIEW;

  hosts: Site[] = [];
  user: any = {};
  clusters: any = [];
  selectedSites: Map<string, Site> = new Map<string, Site>();
  hostsList: any = [];
  tags = [];
  selectedTags = [];
  selectedSite: Site;
  cluster: any = {};

  certificat: any = [];
  p12File: any = null;
  p12Password = '';
  makeCertificateActive = true;

  index = 0;
  pageSize = 20;
  total = 0;

  sitesInRefresh = false;
  refreshingSite: string;

  confirmDelete = false;

  sub: any;

  statutHotes: any = [];

  ACL_SITE_EDIT = ACL_SITE_EDIT;

  shareRoles = [SHARE_ACCESS_RIGHT_ADMIN, SHARE_ACCESS_RIGHT_VIEW];

  get isSameCluster() {
    return (
      _([...this.selectedSites.values()])
        .map((s) => s.cluster.id)
        .uniq()
        .size() == 1
    );
  }

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    private toastr: ToastrService,
    private sites: SitesService,
    private translate: TranslateService,
    public hostModeService: HostModeService,
    private organizationSiteService: OrganizationSiteService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();
    this.lang = this.auth.getCurrentLanguage();

    this.route.data.subscribe(async (v) => {
      this.adminClusterView = v.adminClusterView;
      if (this.adminClusterView && v.clusters) {
        this.clusters = v.clusters;
        this.cluster.clusterId = this.clusters[0].id;
      } else {
        if (!this.sites[CONTEXT].store.sitesList.length) {
          await this.sites.load(CONTEXT);
        }
        this.tags = this.sites[CONTEXT].store.tags;
        this.clusters = this.sites[CONTEXT].store.clusters;
      }
    });

    this.loadHosts();
    this.getHoteStatus();
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(200))
      .subscribe((evt: any) => {
        this.index = 0;
        this.loadHosts();
      });
  }

  getHoteStatus() {
    this.http
      .post('statutHote/get', {
        data: {},
      })
      .subscribe((res: any) => {
        this.statutHotes =
          res.items.map((p: any) => {
            p.libelle = this.lang === 'en' ? p.libelleEn : p.libelle;
            return p;
          }) || [];
      });
  }

  resetPagination() {
    this.index = 0;
    this.selectedSites = new Map<string, Site>();
    this.loadHosts();
  }

  async allUpdateHost(prop: KeyOfType<Site, boolean>, value: boolean) {
    let siteInError: Site;

    for (const site of this.selectedSites.values()) {
      try {
        const res: any = await firstValueFrom(this.updateHost(site, prop, value, false));

        if (res.hasError || !res.items || !res.items[0]) {
          siteInError = site;
          break; // Arrête la boucle si une erreur est rencontrée
        }
      } catch (error) {
        siteInError = site;
        break;
      }
    }

    if (!siteInError) {
      this.toastr.success(this.translate.instant('ModifiedSitesSuccess'));
      this.selectedSites.forEach((host) => {
        host[prop] = value;
      });
    } else {
      this.toastr.error(this.translate.instant('ImpossProv', { siteName: siteInError.name }));
    }
  }

  async allDelete() {
    try {
      for (const host of this.selectedSites.values()) {
        await firstValueFrom(this.deleteClient(host));
      }

      this.toastr.success(this.translate.instant('DomainsSupp'));
      this.modalMultipleDeletes.hide();
      this.resetPagination();
    } catch (error) {
      this.toastr.error(this.translate.instant('Erreur'));
    }
  }

  handleShareType(event) {
    this.lastShareInput = event.term;
  }

  handleShareBlur() {
    if (this.lastShareInput) {
      this.shareInput = [...this.shareInput, { label: this.lastShareInput }];
      this.lastShareInput = '';
    }
  }

  validateSharesInput(emails) {
    return emails.every((e) => validateEmail(e) || validateOrganizationCode(e));
  }

  closeMultipleShares() {
    this.modalMultipleShares.hide();
    this.shareInput = [];
  }

  updateHost(site: Site, prop: KeyOfType<Site, boolean>, value: boolean | null, callback) {
    const req = {
      datas: [
        {
          ...site,
          [prop]: value ?? !site[prop],
        },
      ],
    };

    if (callback) {
      this.http.post('hote/update', req).subscribe((res: any) => {
        if (!res.hasError && res.items && res.items[0]) {
          site[prop] = value ? value : !site[prop];
          this.toastr.success(this.translate.instant('SiteModifieSucc'));
        } else {
          this.toastr.error(this.translate.instant('ImpossProv', { siteName: site.name }));
        }
      });
    } else {
      return this.http.post('hote/update', req);
    }
  }

  getHoteByCluster(c: any) {
    let data = this.getSearchParameters(c);

    this.http.post('hote/getByCluster', { index: this.index, size: this.pageSize, data }).subscribe((res: any) => {
      this.hosts = res.items || [];
      this.hostsList = res.items || [];
      this.total = res.count;
    });
  }

  getSearchParameters(cluster = null) {
    let data = {};
    let c = cluster || this.cluster;

    if (this.searchInput) {
      const searchTerm = this.searchInput.nativeElement.value;
      data['utilisateurEmail'] = searchTerm;
      data['destHost'] = searchTerm;
      data['nom'] = searchTerm;
      data['utilisateurEmailParam'] = { operator: '%%' };
      data['destHostParam'] = { operator: '%%' };
      data['nomParam'] = { operator: '%%' };
    }

    if (c.clusterId !== 'undefined') {
      data['clusterId'] = c.clusterId;
    }

    if (c.statutHoteId !== 'undefined') {
      data['statutHoteId'] = c.statutHoteId;
    }

    if (c.tag && c.tag !== 'undefined') {
      data['tags'] = [{ tag: c.tag }];
    }

    return data;
  }

  changePage(evt: any) {
    this.index = evt - 1;
    this.loadHosts();
  }

  loadHosts() {
    if (this.adminClusterView) {
      this.getHoteByCluster(this.cluster);
    } else {
      let data = this.getSearchParameters();
      this.http
        .post('hote/get', {
          index: this.index,
          size: this.pageSize,
          data: { isDeleted: false, ...data },
        })
        .subscribe((res: any) => {
          this.total = res.count;
          this.hosts = res.items || [];
        });
    }
  }

  showSharedHosts(site: Site) {
    if (!this.hostModeService.isAdvanced(site.mode)) {
      return this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
    }

    this.selectedSite = site;
    this.currentShares = site.shares;
    this.modalShare.show();
    setTimeout(() => this.addShareInput.focus(), 1);
  }

  toggleSelected(event, site: Site) {
    if (event.target.checked) {
      this.selectedSites.set(site.name, site);
    } else {
      this.selectedSites.delete(site.name);
    }
  }

  allEnabled<K extends keyof Site>(prop: K, value: Site[K]) {
    return [...this.selectedSites.values()].every((site) => site[prop] == value);
  }

  showModalDelete(site: Site) {
    this.selectedSite = site;
    this.modalDelete.show();
  }

  showModalMultipleShares() {
    this.modalMultipleShares.show();
    setTimeout(() => this.addMultiShareInput.focus(), 1);
  }

  canMultipleShare(): boolean {
    return [...this.selectedSites.values()].some((site) => this.canShare(site));
  }

  canMultipleDelete(): boolean {
    return [...this.selectedSites.values()].some((site) => site.accessRights.includes('DELETE'));
  }

  canMultipleEdit(): boolean {
    return [...this.selectedSites.values()].some((site) => site.accessRights.includes('EDIT'));
  }

  onPasteShare(event) {
    const value = event.clipboardData.getData('text/plain');

    if (value != null && value.match(/[,;]/)) {
      event.preventDefault();
      const split = value.split(/[,;]/);
      split.forEach((s) => {
        if (s != '') {
          return (this.shareInput = [...this.shareInput, { label: s.trim() }]);
        }
      });
      event.target.value = '';
    }
  }

  async allCreateShare() {
    let emails = [...new Set(this.shareInput.map((e) => e.label.trim()))];

    if (!this.validateSharesInput(emails)) {
      return this.toastr.error(this.translate.instant('OneOfInvalidEmailOrCode'));
    }

    const sitesToShare = [...this.selectedSites.values()].filter((site) => this.canShare(site));

    for (const site of sitesToShare) {
      for (const email of emails) {
        try {
          await this.postCreateShare(site.name, email, this.multipleSharedRole);
        } catch (error) {
          return this.toastr.error(email + ' : ' + this.translate.instant(error?.error?.error || 'Erreur'));
        }
      }
      this.loadHosts();
      this.shareInput = [];
    }

    this.toastr.success(this.translate.instant('Les sites ont été partagés avec succès'));
  }

  async confirmCreateShare() {
    let recipients = [...new Set(this.shareInput.map((e) => e.label.trim()))];

    if (!this.validateSharesInput(recipients)) {
      return this.toastr.error(this.translate.instant('OneOfInvalidEmailOrCode'));
    }

    for (const recipient of recipients) {
      let res: any = null;
      try {
        res = await this.postCreateShare(this.selectedSite.name, recipient, this.sharedRole);
      } catch (error) {
        return this.toastr.error(this.translate.instant(error?.error?.error || 'Erreur'));
      }
      this.currentShares.push(res);
    }
    this.shareInput = [];
    this.toastr.success(this.translate.instant('Les sites ont été partagés avec succès'));
  }

  async postCreateShare(siteName: string, recipient, role) {
    return await firstValueFrom(this.organizationSiteService.share(siteName, recipient, role));
  }

  async deleteShare(siteName, recipient, index) {
    await firstValueFrom(
      this.http.delete(`v2/organizations/${this.auth.currentOrganization.id}/sites/${siteName}/shares/${recipient}`),
    );
    this.loadHosts();
    this.currentShares.splice(index, 1);
    this.toastr.success(this.translate.instant('PartageSuppressionSucces'));
  }

  deleteHost() {
    this.deleteClient(this.selectedSite).subscribe({
      next: (res) => {
        this.sites.purgeContext();
        this.loadHosts();
        this.modalDelete.hide();
        this.toastr.success(this.translate.instant('DomainSupp'));
      },
      error: (err) => this.toastr.error(this.translate.instant('ImpossibleSuppressionDomaine')),
    });
  }

  deleteClient(site: Site): Observable<void> {
    return this.http.delete<void>(`v2/organizations/${this.auth.currentOrganization.id}/sites/${site.name}`);
  }

  async multipleRefreshStatus() {
    this.sitesInRefresh = true;
    let hasError = false;

    for (const site of this.selectedSites.values()) {
      try {
        const res: any = await firstValueFrom(this.refresh(site.id));
        if (res.hasError) {
          hasError = true;
          break;
        }
      } catch (error) {
        hasError = true;
        break;
      }
    }

    if (!hasError) {
      this.toastr.success(this.translate.instant('StatusesUpdated'));
    } else {
      this.toastr.error(this.translate.instant('OperationFailed'));
    }

    this.loadHosts();
    this.sitesInRefresh = false;
  }

  multipleSetSites() {
    const [first] = this.selectedSites.values();
    this.track(first.cluster.name, [...this.selectedSites.keys()]);
  }

  refreshStatus(site: Site) {
    this.refreshingSite = site.name;

    this.refresh(site.id)
      .pipe(
        map((res: any) => {
          if (res.hasError) {
            this.toastr.error(site.name);
            this.refreshingSite = null;
          }
        }),
      )
      .subscribe(() => {
        this.toastr.success(this.translate.instant('StatusUpdated'));
        this.loadHosts();
        this.refreshingSite = null;
      });
  }

  refresh(id) {
    return this.http.post('hote/refreshStatus', {
      data: {
        id: id,
      },
    });
  }

  handlePkcs12(files: FileList) {
    const file = files.item(0) as File;
    this.p12File = file;
  }

  handleKeyEnterP12(event) {
    event.preventDefault();
    this.saveP12Certif();
  }

  async saveP12Certif() {
    const file = this.p12File;
    let hasError = false;

    for (const host of this.selectedSites.values()) {
      const formData = new FormData();
      formData.append('passwordForP12File', this.p12Password);
      formData.append('fileP12ToUpload', file, file.name);
      formData.append('hoteId', host.id.toString());
      formData.append('makeCertificateActive', this.makeCertificateActive ? 'true' : 'false');

      try {
        const res: any = await this.http
          .post('certificat/uploadP12File', formData, {
            headers: { formData: 'true' },
            reportProgress: true,
            observe: 'events',
          })
          .toPromise();

        if (res.body.hasError) {
          hasError = true;
          if (res.body.status.message === 'WRONG_P12_PASSWORD') {
            this.toastr.error(this.translate.instant('WrongP12Password'));
            break;
          }
          if (res.body.status.message === 'CERT_EXPIRED') {
            this.toastr.error(this.translate.instant('CertificatExpire'));
            break;
          }
          if (res.body.status.message.includes('NOT_SUITABLE')) {
            this.toastr.error(
              this.translate.instant('CertificateDoesNotFit') + res.body.status.message.split(':')[1].trim(),
            );
          }
        } else if (res.body.items && res.body.items[0]) {
          this.toastr.success(this.translate.instant('CertificatAjoutSucces') + ' : ' + res.body.items[0].hoteNom);
        }
      } catch (error) {
        console.error(error);
        hasError = true;
        this.toastr.error(this.translate.instant('Erreur'));
        break;
      }
    }

    if (!hasError) {
      this.toastr.success(this.translate.instant('CertificatsAjoutSucces'));
    }

    this.modalMultipleCertificat.hide();
    this.loadHosts();
    this.p12Password = '';
    this.loadPkcs12.nativeElement.value = '';
    this.p12File = null;
  }

  isValidIpv6(string) {
    return string.match(
      /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/,
    );
  }

  pushTags(event) {
    this.selectedTags = event;
  }

  createTags() {
    this.sites.createTags(this.selectedSites, this.selectedTags).then((res: any) => {
      if (!res.hasError) {
        this.toastr.success(this.translate.instant('OperationSuccess'));
        this.modalMultipleTags.hide();
        this.tags = [];
        this.sites.purgeContext();
        this.refreshTags();
        this.loadHosts();
      } else {
        this.toastr.error(this.translate.instant('OperationFailed'));
      }
    });
  }

  async refreshTags() {
    const newTags = await this.sites.loadTags();
    this.tags = _.uniq([...this.tags, ...newTags]);
  }

  selectAll() {
    let hosts = this.adminClusterView ? this.hosts : this.hosts.filter((h) => this.auth.canDoMoreThanView(h));
    hosts.forEach((site) => this.selectedSites.set(site.name, site));
  }

  toggleMode(id, previous, event) {
    this.http
      .post('hote/update', {
        datas: [{ id, mode: event.target.value }],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.loadHosts();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          event.target.value = previous;
          if (res.status.code == '938') {
            this.toastr.error(res.status.message);
          } else {
            this.toastr.error(this.translate.instant('OperationFailed'));
          }
        }
      });
  }

  toggleDdosProtection(site: Site) {
    this.updateSite({ id: site.id, ddosProtection: !site.ddosProtection });
  }

  private updateSite(site) {
    this.http.post('hote/update', { datas: [site] }).subscribe((res: any) => {
      if (!res.hasError) {
        this.loadHosts();
        this.toastr.success(this.translate.instant('OperationSuccess'));
      } else {
        if (res.status.code == '938') {
          this.toastr.error(res.status.message);
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      }
    });
  }

  track(clusterName: string, sites: string[]) {
    this.router.navigate([this.adminClusterView ? ADMIN_CLUSTER_LOGS_ROUTE : MY_LOGS_ROUTE], {
      state: { sites, clusterName },
    });
  }

  canShare(site: Site): boolean {
    return this.hostModeService.isAdvanced(site.mode) && site.accessRights.includes('SHARE');
  }
}

interface Site {
  id: number;
  name: string;
  mode: SiteMode;
  status: string;

  cluster: Cluster;

  destHost: string;
  destHostScheme: string;
  port: number;

  dryRun: boolean;
  panicMode: boolean;
  ddosProtection: boolean;
  managed: boolean;

  isACertificateInError: boolean;

  tags: { tag: string }[];
  accessRights: string[];
  shares: SiteShare[];

  nbShares: number;
  nbCertificates: number;
  nbUrlExceptions: number;
  nbRules: number;
  nbRewriteRules: number;
  nbWhitelistedIps: number;

  owner: {
    code: string;
    companyName: string;
    owner: {
      email: string;
    };
  };

  createdAt: number;
}

interface Cluster {
  id: number;
  name: string;
}
