import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Highcharts, { Point, PointClickEventObject } from 'highcharts';
import _ from 'lodash';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss'],
  standalone: false,
})
export class DonutComponent implements OnChanges {
  @Input() data: any;
  @Input() fontSize: string = '14px';
  @Input() exportButtonSpacing = null;
  @Input() title: string = '';
  @Input() print = false;

  @Output() sliceClicked = new EventEmitter<Point>();

  Highcharts: typeof Highcharts = Highcharts;
  options: Highcharts.Options;

  constructor(private translate: TranslateService) {}

  async ngOnInit() {
    if (!this.options) {
      this.buildOptions();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.data?.currentValue) {
      return;
    }

    this.buildOptions();
  }

  buildOptions() {
    if (!this.data) {
      return;
    }

    this.options = { ...this.data };

    this.options.plotOptions.pie.cursor = 'pointer';
    this.options.plotOptions.pie.point.events.click = (event) => this.sliceClicked.emit(event.point);
    if (this.print) {
      this.options.plotOptions.series.animation = false;
    }

    if (this.options?.exporting?.buttons?.contextButton && this.exportButtonSpacing) {
      this.options.exporting.buttons.contextButton.x = this.exportButtonSpacing.x;
      this.options.exporting.buttons.contextButton.y = this.exportButtonSpacing.y;
    }

    this.options.lang = {
      noData: this.translate.instant('NoData'),
      printChart: this.translate.instant('PrintChart'),
      downloadPNG: this.translate.instant('DownloadPNG'),
      downloadJPEG: this.translate.instant('DownloadJPEG'),
      downloadSVG: this.translate.instant('DownloadSVG'),
      downloadCSV: this.translate.instant('DownloadCSV'),
      downloadXLS: this.translate.instant('DownloadXLS'),
    };

    this.options.title = {
      y: 20,
      align: 'center',
      text: this.title,
      style: {
        fontSize: this.fontSize,
      },
    };
  }
}
