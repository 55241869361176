import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { HostModeService } from '../../shared/hostMode/hostMode.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OrganizationSiteService } from '../../services/organization-site.service';
import { Site } from '../../services/entities/site';
import { Cluster } from '../../services/entities/organization';
import { isEnum } from '../../shared/utils/type-utils';
import { MY_SITES_ROUTE } from '../../app-routing.module';
import { EditMode } from '../../shared/edit.mode';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class AddSiteComponent implements OnInit {
  mode?: EditMode;
  site: Site;
  cluster: Cluster;
  activeTab: Tab = Tab.GENERAL;
  url: any = {};
  rules = [];
  isACertificateInError: boolean = false;

  constructor(
    private route: ActivatedRoute,
    protected location: Location,
    private siteApi: OrganizationSiteService,
    public auth: AuthService,
    private toastr: ToastrService,
    public hostModeService: HostModeService,
  ) {}

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    const siteName = routeParams.get('siteName');
    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      this.activeTab = isEnum(Tab, tab) ? tab : Tab.GENERAL;
    });

    if (!siteName) {
      this.mode = EditMode.CREATE;
    } else {
      this.mode = EditMode.UPDATE;
      this.loadSite(siteName);
    }
  }

  loadSite(sitename: string) {
    return this.siteApi.getSite(sitename).subscribe({
      next: (site) => {
        this.site = site;
        this.isACertificateInError = site.isACertificateInError;
        this.cluster = this.site.cluster;
      },
      error: (error) => {
        console.error(error);
        this.toastr.error('Failed to load site');
      },
    });
  }

  protected readonly EditMode = EditMode;
  protected readonly Tab = Tab;
}

enum Tab {
  GENERAL = 'general',
  CERTIFICATES = 'certificates',
  EXCEPTIONS = 'exceptions',
  GEO_BLOCKING = 'geoBlocking',
  ACCESS_CONTROL_RULES = 'accessControlRules',
  REWRITE_RULES = 'rewriteRules',
  CACHE = 'cache',
  EXPERT = 'expert',
}
