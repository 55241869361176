import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HostModeService } from '../../../shared/hostMode/hostMode.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { copyObject } from 'app/shared/utils/data-utils';
import { Site } from '../../../services/entities/site';

@Component({
  selector: 'app-rewrite-rules',
  templateUrl: './rewrite-rules.component.html',
  styleUrls: [
    './rewrite-rules.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../add-site.component.scss',
    '../activable-and-ordered.scss',
  ],
  standalone: false,
})
export class RewriteRulesComponent implements OnInit {
  @Input({ required: true }) site: Site;

  @ViewChild('rewriteRuleSourceElement') rewriteRuleSourceElement: ElementRef;
  @ViewChild('rewriteTest') rewriteTest: ElementRef;

  rewriteRules: any = [];
  disabled = true;
  tmpRewriteRule: any = {};
  rewriteRulesInProgress = false;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    public hostModeService: HostModeService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.disabled = !this.hostModeService.isAdvanced(this.site.mode);
    this.getRewriteRules();
  }

  getRewriteRules() {
    this.http
      .post('rewriteRule/get', {
        data: {
          hoteId: this.site.id,
        },
      })
      .subscribe(
        (res: any) => {
          if (res.items && res.items.length) {
            res.items.map((r) => {
              if (r.rewriteSource.charAt(0) === '^') {
                r.rewriteSource = r.rewriteSource.substring(1);
              }
              delete r.isActive;
              delete r.comments;
            });
            this.rewriteRules = (res.items || []).reverse();
            this.sortRewriteRules();
          }
        },
        (error: any) => {
          this.toastr.error('Failed to load rewrite rules');
          console.error('Error : ', error);
        },
      );
  }

  sortRewriteRules() {
    this.rewriteRules.sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority));
  }

  startRewriteEditing(rule) {
    this.tmpRewriteRule = copyObject(rule); // deep copy in order to restore if cancelling editing
    rule.editing = true;
  }

  validateRewriteEditing(rule) {
    if (rule.rewriteSource && rule.rewriteDestination) {
      rule.editing = false;

      if (rule.hasOwnProperty('new')) {
        delete rule.new;
      }

      const { editing, createdAt, updatedAt, ...data } = rule;

      data.rewriteSource = `^${data.rewriteSource}`;

      if (!rule.id) {
        this.rewriteRulesInProgress = true;
        this.http
          .post('rewriteRule/create', {
            datas: [
              {
                hoteId: this.site.id,
                ...data,
              },
            ],
          })
          .subscribe(
            (res: any) => {
              if (!res.hasError) {
                if (res.items[0].rewriteSource.charAt(0) === '^') {
                  res.items[0].rewriteSource = res.items[0].rewriteSource.substring(1);
                }
                this.rewriteRules[this.rewriteRules.findIndex((r) => r == rule)] = res.items[0];
                this.toastr.success(this.translate.instant('OperationSuccess'));
              } else {
                this.rewriteRules.splice(
                  this.rewriteRules.findIndex((r) => r == rule),
                  1,
                );
                this.toastr.error(this.translate.instant('OperationFailed'));
              }
              this.rewriteRulesInProgress = false;
            },
            (error: any) => {
              this.toastr.error('Failed to save rewrite rule');
              console.error('Error : ', error);
            },
          );
      } else {
        this.updateRewriteRules([{ hoteId: this.site.id, ...data }]);
      }
    }
  }

  updateRewriteRules(datas) {
    this.rewriteRulesInProgress = true;
    this.http
      .post('rewriteRule/update', {
        datas: datas,
      })
      .subscribe(
        (res: any) => {
          if (!res.hasError) {
            res.items.forEach((item) => {
              if (item.rewriteSource.charAt(0) === '^') {
                item.rewriteSource = item.rewriteSource.substring(1);
              }
              this.rewriteRules[this.rewriteRules.findIndex((r) => r.id == item.id)] = item;
            });
            this.toastr.success(this.translate.instant('OperationSuccess'));
          } else {
            this.getRewriteRules();
            this.toastr.error(this.translate.instant('OperationFailed'));
          }
          this.rewriteRulesInProgress = false;
        },
        (error: any) => {
          this.toastr.error('Failed to save rewrite rule');
          console.error('Error : ', error);
        },
      );
  }

  cancelRewriteEditing(rule) {
    rule.editing = false;
    this.rewriteRules[this.rewriteRules.findIndex((r) => r.priority == rule.priority)] = this.tmpRewriteRule;
  }

  addRewriteRule() {
    if (this.disabled) {
      return this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
    }

    this.rewriteRules.push({
      new: true, // front only
      editing: true, // front only
      priority: this.rewriteRules.length
        ? Math.max.apply(
            Math,
            this.rewriteRules.map((o) => o.priority),
          ) + 1
        : 1,
      active: true,
      rewriteSource: '/',
      rewriteDestination: '/',
      comment: '',
    });
    setTimeout(() => this.rewriteRuleSourceElement.nativeElement.focus(), 1);
  }

  deleteRewriteRule(rule) {
    return Swal.fire({
      html: this.translate.instant(
        rule.id ? 'VoulezVousAbandonnerCetteNouvelleRegle' : 'VoulezVousSupprimerCetteRegle',
      ),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        if (rule.id) {
          this.rewriteRulesInProgress = true;
          this.http
            .post('rewriteRule/delete', {
              datas: [
                {
                  id: rule.id,
                },
              ],
            })
            .subscribe(
              (res: any) => {
                if (!res.hasError) {
                  this.toastr.success(this.translate.instant('OperationSuccess'));
                  this.rewriteRules.splice(
                    this.rewriteRules.findIndex((r) => r.id == rule.id),
                    1,
                  );
                  this.rewriteRules.filter((r) => r.priority > rule.priority).map((r) => (r.priority -= 1));
                } else {
                  this.getRewriteRules();
                  this.toastr.error(this.translate.instant('OperationFailed'));
                }
                this.rewriteRulesInProgress = false;
              },
              (error: any) => {
                this.toastr.error('Failed to delete rewrite rule');
                console.error('Error : ', error);
              },
            );
        } else {
          this.rewriteRules.splice(
            this.rewriteRules.findIndex((r) => r.new),
            1,
          );
        }
      }
    });
  }

  isCreatingRewriteRule() {
    return this.rewriteRules.find((rule) => rule.new);
  }

  isEditingRewriteRule() {
    return this.rewriteRules.some((rule) => rule.editing);
  }

  dropRewrite(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rewriteRules, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.rewriteRules.map((rule, index) => (rule.priority = index + 1));
      this.rewriteRules[event.currentIndex].rewriteSource = `^${this.rewriteRules[event.currentIndex].rewriteSource}`;
      this.updateRewriteRules([this.rewriteRules[event.currentIndex]]);
    }
  }

  toggleRegExpTester(rewrite) {
    rewrite.tester = !rewrite.tester;
  }

  computeRegExp(rule) {
    let value = this.rewriteTest.nativeElement.value;
    let queryString = '';

    if (value.includes('?')) {
      queryString = value.split('?')[1];
      value = value.split('?')[0];
    }

    const sourceURL = new RegExp(`^${rule.rewriteSource}`, 'i');

    let tmp = rule.rewriteDestination.replace(/\${(\d+)}/g, '$$$1');

    rule.resultTest = `${value.replace(sourceURL, tmp)}${queryString ? `?${queryString}` : ''}`;

    let matches = value.match(sourceURL);

    if (!matches && value == '') {
      rule.resultTest = undefined;
    } else if (!matches) {
      rule.resultTest = this.translate.instant('NoMatch');
    }
  }

  isValidRewriteRegExp(regExp) {
    let isValid = true;
    try {
      new RegExp(regExp);
    } catch (e) {
      isValid = false;
    }

    if (regExp.includes('?')) {
      isValid = false;
    }

    return isValid;
  }
}
