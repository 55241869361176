import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

export abstract class AbstractOrganizationService {
  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
  ) {}

  protected getOrganizationEndpoint(): string {
    return 'v2/organizations/' + this.auth.currentOrganization.id;
  }
}
