import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, CLUSTER_DELEGATION_ADMIN } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationClustersResolver implements Resolve<any> {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {}

  resolve(): Observable<any> {
    return this.http.get('v2/organizations/' + this.auth.currentOrganization.id + '/clusters').pipe(
      map((clusters: any) => {
        // Filtrer et manipuler les clusters ici
        return clusters
          .filter((c) => c.role == CLUSTER_DELEGATION_ADMIN)
          .map((item) => {
            item.cluster.nom = item.cluster.name;
            return item.cluster;
          });
      }),
    );
  }
}
