import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
@Component({
  template: `<span class="label {{ 'siteStatus-' + status }}">{{ 'SiteStatus.' + status | translate }}</span>`,
  styleUrls: ['./site-status.component.scss'],
  selector: 'app-site-status',
  standalone: false,
})
export class SiteStatusComponent {
  @Input() status: string;
}
