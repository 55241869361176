<div class="card with-tabs">
  <form #profileForm="ngForm">
    <div class="col-md-12">
      <div class="row" style="padding-top: 15px">
        <h4 class="col-md-8 mt-2" [innerHTML]="'Authentication' | translate"></h4>
        <div class="col-md-4">
          <button
            type="button"
            (click)="updateAuth()"
            [disabled]="user.mfa == MFA.TOTP && !totpChecked"
            [hidden]="!editAuth"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Enregistrer' | translate"
          ></button>
          <button
            type="button"
            (click)="cancelEditAuth()"
            [hidden]="!editAuth"
            class="btn btn-danger btn-sm pull-right mr-2"
            [innerHTML]="'Annuler' | translate"
          ></button>
          <button
            type="button"
            (click)="onEditAuth()"
            [hidden]="editAuth"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Modifier' | translate"
          ></button>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <app-label label="Authentication2FA" class="col-xl-9" />
        <div class="col-xl-3">
          <select
            [(ngModel)]="user.mfa"
            (ngModelChange)="onMfaMethodChange($event)"
            name="mfaMethod"
            [disabled]="!editAuth"
            class="form-control"
          >
            <option [value]="MFA.NONE">{{ 'No' | translate }}</option>
            <option [value]="MFA.EMAIL">Email</option>
            <option [value]="MFA.TOTP">{{ 'AuthenticatorApp' | translate }}</option>
          </select>
        </div>
        <div
          class="w-100"
          style="border: 1px solid #dbd9da; margin: 20px; border-radius: 5px"
          *ngIf="editAuth && userTmp?.mfa != MFA.TOTP && user.mfa == MFA.TOTP"
        >
          <div class="col-md-12 totp-config" *ngIf="totpConfig">
            <div class="row" style="padding-top: 15px">
              <div class="col-md-8 mt-2">
                <h5>{{ 'TOTP.Title' | translate }}</h5>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-xl-4">
                <h6>{{ 'TOTP.AuthenticatorApp' | translate }}</h6>
                <p>{{ 'TOTP.AuthenticatorAppDesc' | translate }}</p>
              </div>
              <div class="col-xl-4">
                <h6>{{ 'TOTP.ScanQRCode' | translate }}</h6>
                <div>{{ 'TOTP.PleaseScan' | translate }}</div>
                <div class="row ml-0 mr-0 my-4" style="gap: 15px">
                  <div class="col-xs-6">
                    <img *ngIf="imgSrcQrCode" [src]="imgSrcQrCode" />
                  </div>
                  <div class="col-xs-6">
                    <div class="bg-blue text-left mt-0" style="max-width: 400px">
                      <b>{{ 'TOTP.Cantscan' | translate }}</b>
                      <div>{{ 'TOTP.AddManually' | translate }}</div>
                      <div>{{ 'TOTP.AccountName' | translate }} : {{ totpConfig.label }}</div>
                      <div>
                        {{ 'Key' | translate }} : <code>{{ totpConfig.secret | insertSpaces }}</code>
                      </div>
                      <div>Type : {{ 'TOTP.TimeBased' | translate }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <h6>{{ 'TOTP.VerifyCode' | translate }}</h6>
                <div>{{ 'TOTP.VerifyCodeDesc' | translate }}</div>
                <div class="form-group" style="max-width: 400px">
                  <code-input #codeInput [codeLength]="6" (codeChanged)="onTotpCodeChange($event)" />
                  <div *ngIf="totpChecked === false" class="totp-validation mt-2 error-msgs">
                    <i class="icofont icofont-warning"></i>
                    <label> {{ 'TOTP.InvalidCode' | translate }}</label>
                  </div>
                  <div *ngIf="totpChecked === true" class="totp-validation mt-2" style="color: #0fb10f">
                    <i class="icofont icofont-verification-check"></i>
                    <label> {{ 'TOTP.ValidCode' | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h4 class="mt-2 mb-4">{{ 'API' | translate }}</h4>
      <div class="form-row">
        <app-label label="API Key" tooltip="APIKeyTooltip" tooltipPlacement="left" class="col-xl-9" />
        <div class="col-xl-3">
          <div class="input-group">
            <input type="text" [(ngModel)]="apiKey" name="apiKey" [disabled]="true" class="form-control" />
            <div
              *ngIf="hasApiKey"
              class="pointer input-group-append"
              (click)="showKey()"
              ngbTooltip="{{ 'ShowKey' | translate }}"
              placement="top"
              container="body"
            >
              <div class="input-group-text"><i class="icofont icofont-eye"></i></div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-8 mt-2 mb-4">
          <h4>{{ 'ChangPassw' | translate }}</h4>
        </div>
        <div class="col-md-4">
          <button
            type="button"
            (click)="editPassword = true"
            [hidden]="editPassword"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Modifier' | translate"
          ></button>
          <button
            *ngIf="editPassword"
            type="button"
            (click)="resetPassword()"
            [hidden]="!editPassword"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Enregistrer' | translate"
            [disabled]="pswd.password !== pswd.password2 || !pswd.ancienPassword || !pswd.password || !pswd.password2"
          ></button>
          <button
            type="button"
            (click)="editPassword = false"
            [hidden]="!editPassword"
            class="btn btn-danger btn-sm pull-right mr-2"
            [innerHTML]="'Annuler' | translate"
          ></button>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-lg-4">
          <div class="form-group">
            <label [innerHTML]="'AncienMotDePasse' | translate"></label>
            <input
              type="password"
              [(ngModel)]="pswd.ancienPassword"
              name="ancienPpassword"
              class="form-control"
              [disabled]="!editPassword"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label [innerHTML]="'NouveauMotDePasse' | translate"></label>
            <input
              type="password"
              [(ngModel)]="pswd.password"
              name="password"
              class="form-control"
              [disabled]="!editPassword"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label [innerHTML]="'ConfirmationNouveauMotdePasse' | translate"></label>
            <input
              type="password"
              [(ngModel)]="pswd.password2"
              name="password2"
              class="form-control"
              [disabled]="!editPassword"
            />
            <div *ngIf="editPassword">
              <div *ngIf="pswd.password == pswd.password2; else passwordNot">
                <small [hidden]="pswd.password == undefined || pswd.password2 == undefined">{{
                  'PasswordsOK' | translate
                }}</small>
              </div>
              <ng-template #passwordNot>
                <small>{{ 'PasswordsNOK' | translate }}</small>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="editPassword" class="row">
        <div class="col-md-12 form-group">
          {{ 'PasswordRestrictions' | translate }}
        </div>
      </div>
    </div>
  </form>
</div>
