import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from '../../../../services/auth.service';
import { SitesService } from 'app/services/sites.service';
import { AUTH_LOGIN_MFA_ROUTE, AUTH_PWD_FORGOT_ROUTE, EXTERNAL_COMPANY_WEBSITE_LINK } from 'app/app-routing.module';
import { getBrowserLang } from '../../../../shared/utils/lang';

@Component({
  selector: 'app-basic-login',
  templateUrl: './basic-login.component.html',
  styleUrls: ['./basic-login.component.scss'],
  standalone: false,
})
export class BasicLoginComponent implements OnInit {
  user: any = {};
  AUTH_PWD_FORGOT_ROUTE = AUTH_PWD_FORGOT_ROUTE;
  EXTERNAL_COMPANY_WEBSITE_LINK = EXTERNAL_COMPANY_WEBSITE_LINK;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
    private sites: SitesService,
  ) {}

  ngOnInit(): void {
    this.translate.use(getBrowserLang());
  }

  async onConnexion(user: NgForm) {
    const value = user.value;
    let res: any = await this.auth.login(value.email, value.password);

    if (!res.hasError) {
      if (res?.item?.mfa != 'none') {
        return this.router.navigate([AUTH_LOGIN_MFA_ROUTE], {
          state: { email: value.email, token: res.item.token, method: res.item.mfa },
        });
      } else {
        this.sites.purgeContext();
        return this.auth.loginSuccess(res.item);
      }
    } else {
      this.toastr.error(this.translate.instant('LoginOuMotDePasseIncorrect'), this.translate.instant('Erreur'));
    }
  }
}
