<div class="theme-loader" *ngIf="isLoading">
  <div class="loader-track">
    <div class="loader-bar"></div>
  </div>
</div>

<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form [formGroup]="registerForm" (ngSubmit)="endSubscription()" class="md-float-material">
            <div class="text-center" class="ogo-logo-login">
              <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">
                <img src="assets/images/logo.png" alt=".OGO Security" />
              </a>
            </div>
            <div class="auth-box" *ngIf="tokenFound">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary">{{ 'CompleteYourRegistration' | translate }}</h3>
                </div>
              </div>
              <div class="input-group">
                <label class="star">{{ 'Nom' | translate }}</label>
                <input formControlName="lastname" type="text" class="form-control" placeholder="DOE" />
                <span *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="error-msgs">
                  <span *ngIf="lastname.errors.required">{{ 'NameRequired' | translate }}</span>
                </span>
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <label class="star">{{ 'Prenom' | translate }}</label>
                <input
                  name="firstname"
                  formControlName="firstname"
                  type="text"
                  class="form-control"
                  placeholder="John"
                />
                <span *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="error-msgs">
                  <span *ngIf="firstname.errors.required"> {{ 'SurnameRequired' | translate }} </span>
                </span>
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <label class="">{{ 'Phone' | translate }}</label>
                <input
                  name="telephone"
                  formControlName="telephone"
                  type="tel"
                  class="form-control"
                  placeholder="+336 12 34 56 78 90"
                />
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <label class="star">{{ 'MotDePasse' | translate }}</label>
                <input
                  name="password"
                  formControlName="password"
                  type="password"
                  class="form-control"
                  autocomplete="off"
                  data-form-type="password,new"
                />
                <span *ngIf="password.invalid && (password.dirty || password.touched)" class="error-msgs">
                  <span *ngIf="password.errors.required">{{ 'PasswordRequired' | translate }}</span>
                  <span *ngIf="password.errors.pattern || password.errors.minlength">
                    {{ 'PasswordRestrictions' | translate }}
                  </span>
                </span>
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <label class="star">{{ 'ConfirmPassword' | translate }}</label>
                <input
                  name="confirm"
                  formControlName="confirm_password"
                  type="password"
                  class="form-control"
                  data-form-type="password,confirmation"
                />
                <span *ngIf="confirm_password.dirty || confirm_password.touched" class="error-msgs">
                  <span *ngIf="confirm_password.errors?.confirm">{{ 'PasswordsNOK' | translate }} </span>
                </span>
                <span class="md-line"></span>
              </div>
              <div class="input-group">
                <div class="flex-align-items" style="align-items: baseline">
                  <input
                    id="emailAuthentication"
                    name="emailAuthentication"
                    formControlName="email_authentication"
                    type="checkbox"
                    checked
                  />
                  <label
                    for="emailAuthentication"
                    style="margin-left: 5px; color: #000; position: relative; top: -2px"
                    >{{ 'Authentication2FA' | translate }}</label
                  >
                </div>
                <span class="md-line"></span>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button
                    type="submit"
                    [disabled]="!tokenFound"
                    class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                  >
                    {{ 'Terminer' | translate }}
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left m-b-0">
                    <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">www.ogosecurity.com</a>
                  </p>
                </div>
              </div>
            </div>

            <div class="login-card card-block auth-body mr-auto ml-auto" *ngIf="!tokenFound">
              <div class="auth-box txt-primary expiry">
                <p>This URL has expired. Please contact OGO administration to receive a new link.</p>
                <hr />
                <p>Cette URL a expiré. Veuillez contacter l'administration d'OGO pour recevoir un nouveau lien.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
