<div class="row">
  <div class="col-md-12">
    <app-card>
      <div class="row">
        <div class="col-md-12 col-xl-12 md-tabs">
          <div class="mb-1">
            <div class="row flex-align-items">
              <div
                [hidden]="
                  adminClusterView || !auth.userHasFeature(ACL_SITE_EDIT) || !auth.currentOrganization.clusters.length
                "
                style="margin: 0 15px"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-primary waves-effect"
                  [routerLink]="'add'"
                  style="margin: 10px 0"
                >
                  <i class="icofont icofont-plus"></i>
                  <span [innerHTML]="'AjouterUnSite' | translate"></span>
                  <br />
                </button>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Cluster</label>
                  <select
                    [(ngModel)]="cluster.clusterId"
                    (ngModelChange)="cluster.tag = undefined; resetPagination()"
                    class="form-control form-control-sm"
                  >
                    <option *ngIf="!adminClusterView" [value]="undefined">{{ 'All' | translate }}</option>
                    <option *ngFor="let item of clusters" value="{{ item.id }}">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>{{ 'Statut' | translate }}</label>
                  <select
                    [(ngModel)]="cluster.statutHoteId"
                    (ngModelChange)="resetPagination()"
                    class="form-control form-control-sm"
                  >
                    <option [value]="undefined">
                      {{ 'All' | translate }}
                    </option>
                    <option *ngFor="let item of statutHotes" value="{{ item.id }}">
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2" *ngIf="!adminClusterView">
                <div class="form-group">
                  <label>Tag</label>
                  <select
                    [(ngModel)]="cluster.tag"
                    (ngModelChange)="resetPagination()"
                    class="form-control form-control-sm"
                  >
                    <option [value]="undefined">
                      {{ 'All' | translate }}
                    </option>
                    <option *ngFor="let tag of tags" value="{{ tag }}">
                      {{ tag }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ 'Search' | translate }}</label>
                  <input
                    id="search"
                    #searchInput
                    type="text"
                    placeholder="Site, Destination, Email"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-condensed" style="border: 1px solid #ccc">
              <thead>
                <tr>
                  <th class="select"></th>
                  <th class="status">
                    {{ 'Statut' | translate }}
                    <label
                      class="badge badge-sm bg-dark"
                      placement="top"
                      container="body"
                      ngbTooltip="{{ 'StatusIs' | translate }}"
                      >?</label
                    >
                  </th>
                  <th class="name">
                    {{ 'Site' | translate }}
                  </th>
                  <th [hidden]="adminClusterView" class="cluster">
                    {{ 'Cluster' | translate }}
                    <label
                      class="badge badge-sm bg-dark"
                      placement="top"
                      container="body"
                      ngbTooltip="{{ 'ClusterIs' | translate }}"
                      >?</label
                    >
                  </th>
                  <th class="destHost">
                    {{ 'DestinationIp' | translate }}
                    <label
                      class="badge badge-sm bg-dark"
                      placement="top"
                      container="body"
                      ngbTooltip="{{ 'DestinIs' | translate }}"
                      >?</label
                    >
                  </th>
                  <th *ngIf="adminClusterView" class="owner">
                    {{ 'Owner' | translate }}
                  </th>
                  <th class="createdAt">
                    {{ 'Creation' | translate }}
                  </th>
                  <th *ngIf="adminClusterView" [innerHtml]="'Site.TableHeader.DdosProtection' | translate"></th>
                  <th *ngIf="adminClusterView">
                    {{ 'Mode' | translate }}
                    <label class="badge badge-sm bg-dark" placement="bottom" container="body" [ngbTooltip]="modeTooltip"
                      >?</label
                    >
                    <ng-template #modeTooltip>
                      <div innerHTML="{{ 'ModeTooltip' | translate }}"></div>
                    </ng-template>
                  </th>
                  <th class="actions">
                    {{ 'Actions' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="!hosts.length">
                  <td [attr.colspan]="adminClusterView ? 9 : 8" style="text-align: center">
                    {{ 'NoData' | translate }}
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let row of hosts
                      | paginate
                        : {
                            itemsPerPage: pageSize,
                            currentPage: index + 1,
                            totalItems: total
                          }
                  "
                >
                  <td>
                    <label class="ogo-checkbox-container" *ngIf="adminClusterView || auth.canDoMoreThanView(row)">
                      <input type="checkbox" [checked]="selectedSites.has(row.name)" (click)="toggleSelected($event, row)" />
                      <span class="ogo-checkbox-checkmark"></span>
                    </label>
                  </td>
                  <td>
                    <app-site-status [status]="row.status" />
                    <span *ngIf="row.dryRun === true" class="label" [style.background-color]="'#333'">{{
                      'ModeAudit' | translate
                    }}</span>
                    <span *ngIf="row.panicMode === true" class="label" [style.background-color]="'#333'">{{
                      'Mode Panique' | translate
                    }}</span>
                    <span *ngIf="row.isACertificateInError" [ngbTooltip]="'CertificateError' | translate"
                      ><i class="icon-warning icofont icofont-warning" style="top: 3px; cursor: default"></i
                    ></span>
                  </td>
                  <td>
                    <span
                      *ngIf="row.mode === hostModeService.expert"
                      class="label"
                      [style.background-color]="'#42389e'"
                      title="Expert"
                      >E</span
                    >
                    <span
                      *ngIf="row.mode === hostModeService.advanced"
                      class="label"
                      [style.background-color]="'#4099ff'"
                      title="{{ 'Advanced' | translate }}"
                      >A</span
                    >
                    <span *ngIf="row.managed" class="label mr-2 badge-outline" title="{{ 'Managed' | translate }}"
                      >M</span
                    >
                    <a *ngIf="auth.canEdit(row)" [routerLink]="[row.name]">{{ row.name }}</a>
                    <span *ngIf="!auth.canEdit(row)">{{ row.name }}</span>
                    <a class="ogo-link" target="_blank" [href]="row.destHostScheme + '://' + row.name">
                      <i class="icofont icofont-external-link ml-2"></i>
                    </a>
                  </td>
                  <td [hidden]="adminClusterView">
                    <a>{{ row.cluster.name }}</a>
                  </td>
                  <td>
                    <a
                      >{{ row.destHostScheme }}://{{
                        isValidIpv6(row.destHost) ? '[' + row.destHost + ']' : row.destHost
                      }}{{ row.port ? ':' + row.port : '' }}</a
                    >
                  </td>
                  <td
                    *ngIf="adminClusterView"
                    [title]="row.owner.companyName + ' (' + row.owner.owner.email + ') ' + row.owner.code"
                  >
                    <span> {{ row.owner.companyName }} </span>
                    <app-badge-organization [code]="row.owner.code"></app-badge-organization>
                  </td>
                  <td>
                    <app-timestamp-cell [value]="row.createdAt" />
                  </td>
                  <th *ngIf="adminClusterView">
                    <label class="ogo-checkbox-container">
                      <input type="checkbox" [(ngModel)]="row.ddosProtection" (click)="toggleDdosProtection(row)" />
                      <span class="ogo-checkbox-checkmark"></span>
                    </label>
                  </th>
                  <td *ngIf="adminClusterView" style="padding: 5px 10px !important">
                    <select
                      [ngModel]="row.mode"
                      (change)="toggleMode(row.id, row.mode, $event)"
                      class="form-control"
                      style="font-weight: normal; min-width: 105px"
                    >
                      <option *ngFor="let m of hostModeService.modes" [value]="m.value">
                        {{ m.libelle | translate }}
                      </option>
                    </select>
                  </td>
                  <td class="actions">
                    <i
                      [ngClass]="
                        ['pointer', 'icon-reload'].concat(auth.canEdit(row) && row.name != refreshingSite ? [] : ['disabled'])
                      "
                      style="transition: color 0.5s linear"
                      title="{{ 'RafraichirStatutHote' | translate }}"
                      (click)="auth.canEdit(row) && row.name != refreshingSite && refreshStatus(row)"
                    ></i>
                    <span [ngClass]="['icon-container'].concat(auth.canShare(row) ? [] : ['disabled'])"
                          (click)="auth.canShare(row) && showSharedHosts(row)">
                      <i
                        title="{{ 'MesPartages' | translate }}"
                        [ngClass]="['pointer', 'icofont', 'icofont-share']"
                      ></i>
                      <sup *ngIf="row.nbShares && auth.canShare(row)"
                           class="badge1"
                        >{{ row.nbShares <= 9 ? row.nbShares : 9
                        }}<sup *ngIf="row.nbShares > 9">+</sup></sup
                      >
                    </span>
                    <a
                      [ngClass]="auth.canEdit(row) ? [] : ['disabled']"
                      [routerLink]="auth.canEdit(row) ? [row.name] : []"
                    >
                      <i
                        id="{{ row.name + '-settings' }}"
                        title="{{ 'Configuration' | translate }}"
                        class="icon-settings"
                      ></i
                    ></a>
                    <i
                      title="{{ 'SeeSiteLogs' | translate }}"
                      (click)="track(row.cluster.name, [row.name])"
                      class="pointer icon-search"
                    ></i>
                    <i
                      title="{{ 'supprimer' | translate }}"
                      [attr.name]="row.name + '-delete'"
                      (click)="auth.canDelete(row) && showModalDelete(row)"
                      [ngClass]="
                        ['pointer', 'icofont', 'icofont-ui-delete'].concat(auth.canDelete(row) ? [] : ['disabled'])
                      "
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="hosts.length" class="flex-align-items float-left" style="margin-top: 10px; margin-left: 10px">
              <a class="ogo-link mr-3" style="font-weight: bold" (click)="selectAll()">{{ 'SelectAll' | translate }}</a>
              <div class="icon-container">
                <i
                  title="{{ 'AuditPanic' | translate }}"
                  [ngClass]="canMultipleEdit() ? [] : ['disabled']"
                  (click)="canMultipleEdit() && modalMultipleAuditPanic.show()"
                  class="icon-settings"
                ></i>
                <i
                  title="{{ 'PartageMultiple' | translate }}"
                  [ngClass]="canMultipleShare() ? [] : ['disabled']"
                  (click)="canMultipleShare() && showModalMultipleShares()"
                  class="icofont icofont-share"
                ></i>
                <i
                  title="{{ 'SuppressionMultiple' | translate }}"
                  [ngClass]="canMultipleDelete() ? [] : ['disabled']"
                  (click)="canMultipleDelete() && modalMultipleDeletes.show()"
                  class="icofont icofont-ui-delete"
                ></i>
                <i
                  title="{{ 'CertificatMultiple' | translate }}"
                  [ngClass]="canMultipleEdit() ? [] : ['disabled']"
                  (click)="canMultipleEdit() && modalMultipleCertificat.show()"
                  class="icofont icofont-papers"
                ></i>
                <i
                  title="{{ 'TagsMultiple' | translate }}"
                  [ngClass]="canMultipleEdit() ? [] : ['disabled']"
                  (click)="canMultipleEdit() && modalMultipleTags.show()"
                  class="icofont icofont-tags"
                ></i>
                <i
                  title="{{ 'RefreshMultiple' | translate }}"
                  [ngClass]="selectedSites.size && !sitesInRefresh ? [] : ['disabled']"
                  (click)="selectedSites.size && !sitesInRefresh && multipleRefreshStatus()"
                  class="icon-reload"
                ></i>
                <i
                  title="{{ 'SeeSiteLogsMultiple' | translate }}"
                  [ngClass]="selectedSites.size && isSameCluster ? [] : ['disabled']"
                  (click)="selectedSites.size && isSameCluster && multipleSetSites()"
                  class="icon-search"
                ></i>
              </div>
            </div>
            <div class="flex-align-items pull-right">
              <div class="sites-total mr-3">Total : {{ total }}</div>
              <pagination-controls
                style="margin-top: 10px"
                *ngIf="hosts.length"
                (pageChange)="changePage($event)"
                previousLabel="{{ 'Previous' | translate }}"
                nextLabel="{{ 'Next' | translate }}"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>

<app-modal-basic #modalShare>
  <div class="app-modal-header">
    <h4 class="modal-title">
      {{ 'MesPartages' | translate }} /
      <small class="text-primary">{{ selectedSite?.name }}</small>
      <a class="ml-2" target="_blank" [href]="'ExternalHelp.share' | translate">
        <label class="badge badge-sm bg-dark pointer">?</label>
      </a>
    </h4>
    <button type="button" class="close basic-close" (click)="modalShare.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <p class="mb-0" [innerHTML]="'PartagerSite' | translate"></p>
        <div class="row">
          <div class="col-md-12 p-3">
            <div class="group flex-align-items space-between mb-3 ng-select-no-dropdown">
              <div style="flex-basis: 80%">
                <ng-select
                  #addShareInput
                  (paste)="onPasteShare($event)"
                  class="select-site-selector"
                  [multiple]="true"
                  [addTag]="true"
                  [(ngModel)]="shareInput"
                  [clearable]="false"
                  [searchable]="true"
                  [openOnEnter]="false"
                  [closeOnSelect]="true"
                  [placeholder]="'EnterEmailOrOrganizationCode' | translate"
                  [inputAttrs]="{ 'data-form-type': 'other' }"
                  (focus)="shareTooltip = true"
                  (blur)="shareTooltip = false; handleShareBlur()"
                  (search)="handleShareType($event)"
                ></ng-select>
              </div>
              <button
                class="create-share"
                (click)="confirmCreateShare()"
                [disabled]="!shareInput.length"
                class="btn btn-sm btn-primary"
              >
                {{ 'Partager' | translate }}
              </button>
            </div>
            <div *ngIf="shareTooltip" style="margin-top: -10px; margin-bottom: 10px; font-size: 12px; color: #969696">
              <i>{{ 'PressEnterToAdd' | translate }}{{ 'SeparatedEmailsOrOrganizationsCodes' | translate }}</i>
            </div>
            <div class="form-radio">
              <form>
                <ng-container *ngFor="let role of shareRoles">
                  <div class="radio radiofill radio-inline">
                    <label class="mt-1">
                      <input [(ngModel)]="sharedRole" [value]="role" name="radio" type="radio" />
                      <i class="helper"></i>
                      <label-share-role [role]="role" />
                      {{ 'ShareSite.' + role + '.description' | translate }}
                    </label>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
        <br />
        <b>{{ 'Liste des partages actifs' | translate }}</b>
        <div class="share-list custom-scrollbar">
          <table *ngIf="currentShares.length" class="table table-xs table-bordered" style="margin-top: 10px">
            <tbody>
            <tr *ngFor="let share of currentShares; let i = index">
              <td class="flex-align-items">
                <div class="text-ellipsis" style="max-width: 235px">
                  <div class="flex-align-items">
                    <div [title]="share.recipient.companyName" class="text-ellipsis" style="max-width: 50%">
                      {{ share.recipient.companyName }}
                    </div>
                    <app-badge-organization [code]="share.recipient.code"></app-badge-organization>
                  </div>
                </div>
                <div class="ml-2">
                  <label-share-role [role]="share.role" />
                </div>
                <i
                  (click)="deleteShare(selectedSite.name, share.recipient.code, i)"
                  style="color: #e74c3c; font-size: 20px; margin-left: auto"
                  class="icofont icofont-ui-delete"
                ></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p *ngIf="!currentShares.length" class="grey-disable">
          {{ 'AucunPartageActifPourLeMoment' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="modalShare.hide()" class="btn btn-sm btn-danger">
      {{ 'Fermer' | translate }}
    </button>
  </div>
</app-modal-basic>

<app-modal-basic #modalDelete>
  <div class="app-modal-header">
    <h4 class="modal-title">{{ 'suprimerHote' | translate }}</h4>
    <button type="button" class="close basic-close" (click)="modalDelete.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="form-group">
            <label>{{ 'Site' | translate }} :</label>
            <input type="text" value="{{ selectedSite?.name }}" class="form-control form-control-sm" readonly />
          </div>
          <div class="form-group">
            <label>Cluster :</label>
            <input type="text" value="{{ selectedSite?.cluster?.name }}" class="form-control form-control-sm"
                   readonly />
          </div>
          <div class="form-group">
            <label>Destination / IP :</label>
            <input type="text" value="{{ selectedSite?.destHost }}" class="form-control form-control-sm" readonly />
          </div>
          <div *ngIf="selectedSite?.nbCertificates > 0" class="form-group">
            <label>{{ 'CertificatSsl' | translate }} : </label>
            &nbsp;<strong>{{ selectedSite?.nbCertificates }}</strong>
          </div>
          <div *ngIf="selectedSite?.nbUrlExceptions > 0" class="form-group">
            <label>{{ 'URLExceptions' | translate }} : </label>
            &nbsp;<strong>{{ selectedSite?.nbUrlExceptions }}</strong>
          </div>
          <div *ngIf="selectedSite?.nbShares > 0" class="form-group">
            <label>{{ 'Partages' | translate }} : </label>
            &nbsp;<strong>{{ selectedSite?.nbShares }}</strong>
          </div>
          <div *ngIf="selectedSite?.nbWhitelistedIps > 0" class="form-group">
            <label>{{ 'WhitelistedIps' | translate }} : </label>
            &nbsp;<strong>{{ selectedSite?.nbWhitelistedIps }}</strong>
          </div>
          <div *ngIf="selectedSite?.nbRules > 0" class="form-group">
            <label>{{ 'RulesManagement' | translate }} : </label>
            &nbsp;<strong>{{ selectedSite?.nbRules }}</strong>
          </div>
          <div *ngIf="selectedSite?.nbRewriteRules > 0" class="form-group">
            <label>{{ 'RewriteRules' | translate }} : </label>
            &nbsp;<strong>{{ selectedSite?.nbRewriteRules }}</strong>
          </div>
          <div class="form-group form-check">
            <input
              id="confirm-delete"
              type="checkbox"
              name="check-delete"
              [(ngModel)]="confirmDelete"
              style="width: inherit"
              class="form-check-input" />
            <label for="confirm-delete" class="form-check-label">{{ 'ConfirmSuppreSite' | translate }}</label>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      [disabled]="!confirmDelete"
      name="validate-delete"
      (click)="deleteHost()"
      class="btn btn-primary btn-sm"
      [innerHTML]="'delete' | translate"
    ></button>
    &nbsp;
    <button (click)="modalDelete.hide()" class="btn btn-danger btn-sm" [innerHTML]="'Fermer' | translate"></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleAuditPanic>
  <div class="app-modal-header">
    <h4 class="modal-title">{{ 'AuditPanic' | translate }}</h4>
    <button type="button" class="close basic-close" (click)="modalMultipleAuditPanic.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered">
              <tbody>
                <tr *ngFor="let entry of selectedSites | keyvalue">
                  @let row = entry.value;
                  <td>
                    {{ row.name }}
                    <b
                      class="mode-letter"
                      title="{{ 'PanicMode' | translate }}"
                      (click)="updateHost(row, 'panicMode', null, true)"
                      [ngStyle]="{
                        color: row.panicMode ? '#0e6efd' : '#8c8e92'
                      }"
                      >{{ 'Panic' | translate }}</b
                    >
                    <b
                      class="mode-letter"
                      title="{{ 'AuditMode' | translate }}"
                      (click)="updateHost(row, 'dryRun', null, true)"
                      [ngStyle]="{
                        color: row.dryRun ? '#0e6efd' : '#8c8e92'
                      }"
                      >Audit</b
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="margin-bottom: 30px">
            <button
              [disabled]="allEnabled('dryRun', false)"
              (click)="allUpdateHost('dryRun', false)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutEnleverModeAudit' | translate"
              style="margin: 5px"
            ></button>
            <button
              [disabled]="allEnabled('dryRun', true)"
              (click)="allUpdateHost('dryRun', true)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutPasserModeAudit' | translate"
              style="margin: 5px"
            ></button>
          </div>
          <div>
            <button
              [disabled]="allEnabled('panicMode', false)"
              (click)="allUpdateHost('panicMode', false)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutEnleverModePanic' | translate"
              style="margin: 5px"
            ></button>
            <button
              [disabled]="allEnabled('panicMode', true)"
              (click)="allUpdateHost('panicMode', true)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutPasserModePanic' | translate"
              style="margin: 5px"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      (click)="modalMultipleAuditPanic.hide()"
      style="margin-top: 5px; margin-right: 5px"
      class="btn btn-danger btn-sm pull-right"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleShares>
  <div class="app-modal-header">
    <h4 class="modal-title">
      {{'PartageMultiple' | translate}}
      <a class="ml-2" target="_blank" [href]="'ExternalHelp.share' | translate">
        <label class="badge badge-sm bg-dark pointer">?</label>
      </a>
    </h4>
    <button type="button" class="close basic-close" (click)="closeMultipleShares()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p [innerHTML]="'PartagerSites' | translate"></p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered">
              <tbody>
                <tr *ngFor="let entry of selectedSites | keyvalue">
                  @let row = entry.value;
                  <td *ngIf="hostModeService.isAdvanced(row.mode) && row.accessRights.includes('SHARE')">
                    {{ row.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="group flex-align-items space-between mb-3 ng-select-no-dropdown">
            <div style="flex-basis: 80%">
              <ng-select
                #addMultiShareInput
                (paste)="onPasteShare($event)"
                class="select-site-selector"
                [multiple]="true"
                [addTag]="true"
                [(ngModel)]="shareInput"
                [clearable]="false"
                [openOnEnter]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [placeholder]="'EnterEmailOrOrganizationCode' | translate"
                [inputAttrs]="{ 'data-form-type': 'other' }"
                (focus)="shareTooltip = true"
                (blur)="$event.preventDefault(); shareTooltip = false; handleShareBlur()"
                (search)="handleShareType($event)"
              ></ng-select>
            </div>
            <button (click)="allCreateShare()" [disabled]="!shareInput.length" class="btn btn-sm btn-primary">
              {{ 'Partager' | translate }}
            </button>
          </div>
          <div *ngIf="shareTooltip" style="margin-top: -10px; margin-bottom: 10px; font-size: 12px; color: #969696">
            <i>{{ 'PressEnterToAdd' | translate }}{{ 'SeparatedEmailsOrOrganizationsCodes' | translate }}</i>
          </div>
          <div class="form-radio" style="margin-top: 20px">
            <form>
              <ng-container *ngFor="let role of shareRoles">
                <div class="radio radiofill radio-inline">
                  <label class="mt-1">
                    <input [(ngModel)]="multipleSharedRole" [value]="role" name="radio" type="radio" />
                    <i class="helper"></i>
                    <span class="label {{ 'share-' + role }}" style="padding: 4px 9px">{{
                      'ShareSite.' + role + '.label' | translate
                    }}</span>
                    {{ 'ShareSite.' + role + '.description' | translate }}
                  </label>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      (click)="closeMultipleShares()"
      style="margin-top: 5px; margin-right: 5px"
      class="btn btn-danger btn-sm pull-right"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleDeletes>
  <div class="app-modal-header">
    <h4 class="modal-title">{{'SuppressionMultiple' | translate}}</h4>
    <button
      type="button"
      class="close basic-close"
      (click)="modalMultipleDeletes.hide(); confirmDelete = false"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p>{{ 'DeleteSites' | translate }}</p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered">
              <tbody>
                <tr *ngFor="let entry of selectedSites | keyvalue">
                  <td>
                    {{ entry.value.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group form-check">
            <input
              id="confirm-multiple-delete"
              type="checkbox"
              [(ngModel)]="confirmDelete"
              style="width: inherit"
              class="form-check-input"
            />
            <label for="confirm-multiple-delete" class="form-check-label">{{ 'ConfirmSuppreSites' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      [disabled]="!confirmDelete"
      (click)="allDelete()"
      class="btn btn-primary btn-sm"
      [innerHTML]="'DeleteDomains' | translate"
    ></button>
    &nbsp;
    <button
      (click)="modalMultipleDeletes.hide(); confirmDelete = false"
      class="btn btn-danger btn-sm"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleCertificat>
  <div class="app-modal-header">
    <h4 class="modal-title">{{'CertificatMultiple' | translate}}</h4>
    <button type="button" class="close basic-close" (click)="modalMultipleCertificat.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p>{{ 'ImportForSites' | translate }}</p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered">
              <tbody>
                <tr *ngFor="let entry of selectedSites | keyvalue">
                  <td>
                    {{ entry.value.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group bg-orange p-2">
            {{ 'DoNotForgetConcatIntermediates' | translate }}
          </div>
          <form>
            <div class="form-group">
              <label>{{ 'PKCS12File' | translate }}</label>
              <div class="">
                <button (click)="loadPkcs12.click()" class="btn btn-block btn-primary btn-sm">
                  {{ 'ImportPKCS12' | translate }}
                  <input
                    #loadPkcs12
                    id="loadPkcs12"
                    type="file"
                    name="loadPkcs12"
                    accept=".p12,.pfx"
                    hidden
                    (change)="handlePkcs12($event.target.files)"
                    multiple="false"
                  />
                </button>
                <p *ngIf="p12File">
                  <small class="text-primary">{{ p12File.name }}</small>
                </p>
              </div>
            </div>
            <div class="form-group">
              <label>{{ 'MotDePasse' | translate }}</label>
              <input
                type="password"
                [(ngModel)]="p12Password"
                (keydown.enter)="handleKeyEnterP12($event)"
                name="p12Password"
                class="form-control form-control-sm"
              />
            </div>
            <div class="form-group flex-align-items">
              <input
                type="checkbox"
                [(ngModel)]="makeCertificateActive"
                id="makeCertificateActive"
                name="makeCertificateActive"
              />
              <label class="form-check-label" for="makeCertificateActive" style="margin-left: 5px">{{
                'MakeCertificateActive' | translate
              }}</label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer pull-right">
    <button
      (click)="saveP12Certif()"
      class="btn btn-primary btn-sm"
      [disabled]="!p12File || !p12Password"
      [innerHTML]="'Import' | translate"
    ></button>
    &nbsp;
    <button
      (click)="modalMultipleCertificat.hide()"
      class="btn btn-danger btn-sm"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleTags>
  <div class="app-modal-header">
    <h4 class="modal-title">{{'TagsMultiple' | translate}}</h4>
    <button type="button" class="close basic-close" (click)="modalMultipleTags.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p>{{ 'TagsMultipleDesc' | translate }}</p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered">
              <tbody>
                <tr *ngFor="let entry of selectedSites | keyvalue">
                  <td>
                    {{ entry.value.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-site-tags [tags]="tags" (selectedChange)="pushTags($event)"></app-site-tags>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer pull-right">
    <button
      (click)="createTags()"
      class="btn btn-primary btn-sm"
      [disabled]="!selectedTags.length"
      [innerHTML]="'ToTag' | translate"
    ></button>
    <button
      (click)="modalMultipleTags.hide()"
      class="btn btn-danger btn-sm"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>
