import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PageView } from 'app/enums/enums';
import { AuthService } from '../../../services/auth.service';
import { Contract } from '../../../services/entities/contract';
import { PageRequest } from '../../../shared/page';
import { AdminContractService } from 'app/services/admin-contract.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class ContractsComponent implements OnInit {
  @ViewChild('remove') remove: any;
  @ViewChild('search') search: ElementRef;
  lang: string;

  contract: Partial<Contract> | null;
  contracts: Contract[] = [];

  page: any = 1;
  pageSize: any = 20;
  totalItems: any = 0;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    public auth: AuthService,
    private admin: AdminContractService,
  ) {}

  async ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
    await this.getContracts();
  }

  ngAfterViewInit(): void {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(debounceTime(300))
      .subscribe((evt: any) => {
        this.page = 1;
        this.getContracts(evt.target.value);
      });
  }

  modalRemove(p: any) {
    this.remove.show();
    this.contract = { ...p };
  }

  async changePage(evt: any) {
    this.page = evt;
    await this.getContracts(this.search?.nativeElement?.value);
  }

  async getContracts(searchTerm?: string) {
    let res: any = null;
    try {
      res = await this.admin.getContracts(searchTerm, this.getPage());
    } catch (error) {
      return this.toastr.error(error.error);
    }

    this.contracts = res?.content;
    this.totalItems = res?.totalElements;
  }

  async removeContract(id) {
    try {
      await this.admin.deleteContract(id);
    } catch (error) {
      return this.toastr.error(this.translate.instant(error?.error?.error || 'Erreur'));
    }

    this.toastr.success(this.translate.instant('SuppressionSucces'));
    this.remove.hide();
    await this.getContracts(this.search?.nativeElement?.value);
  }

  private getPage(): PageRequest {
    return { number: this.page - 1, size: this.pageSize };
  }
}
