import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class HostModeService {
  public basic = 'BASIC';
  public advanced = 'ADVANCED';
  public expert = 'EXPERT';

  public modes = [
    { libelle: 'Expert', value: this.expert },
    { libelle: 'Advanced', value: this.advanced },
    { libelle: 'Basic', value: this.basic },
  ];

  constructor(private translate: TranslateService) {}

  promptSuscribeToMode(msg) {
    return Swal.fire({
      title: `<h4>${this.translate.instant(msg)}</h4>`,
      html: '',
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('NotNow'),
      confirmButtonText: this.translate.instant('ContactUs'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        window['groove'].widget.open();
      }
    });
  }

  isAdvanced(mode: SiteMode) {
    return mode == this.advanced || mode == this.expert;
  }

  isExpert(mode: SiteMode) {
    return mode == this.expert;
  }
}

export type SiteMode = 'BASIC' | 'EXPERT' | 'ADVANCED';
