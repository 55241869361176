import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from '../../../../services/auth.service';
import { PasswordValidator } from '../../../../shared/validators/password.validator';
import { PasswordRestrictions } from 'app/shared/validators/validators';
import { AUTH_LOGIN_MFA_ROUTE, EXTERNAL_COMPANY_WEBSITE_LINK } from 'app/app-routing.module';

@Component({
  selector: 'app-register-password',
  templateUrl: './register-password.component.html',
  styleUrls: ['./register-password.component.scss'],
  standalone: false,
})
export class RegisterPasswordComponent implements OnInit {
  user: any = {};
  _email: string;
  _token: string;
  registerForm: UntypedFormGroup;
  flag_link: string;
  tokenFound = true;
  isLoading = false;
  EXTERNAL_COMPANY_WEBSITE_LINK = EXTERNAL_COMPANY_WEBSITE_LINK;

  constructor(
    private router: Router,
    private http: HttpClient,
    private actRoute: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private auth: AuthService,
  ) {
    this.registerForm = this.formBuilder.group(
      {
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telephone: [''],
        password: ['', [Validators.minLength(8), Validators.required, Validators.pattern(PasswordRestrictions)]],
        confirm_password: [
          '',
          [Validators.minLength(8), Validators.required, Validators.pattern(PasswordRestrictions)],
        ],
        email_authentication: [''],
      },
      (formGroup: UntypedFormGroup) => {
        return PasswordValidator.areEqual(formGroup);
      },
    );
    this.registerForm.controls.confirm_password.valueChanges.subscribe((v) => {
      this.confirm_password.errors.confirm = false;
      if (!v || v !== this.registerForm.controls.password.value) {
        this.confirm_password.errors.confirm = true;
      }
    });
  }

  ngOnInit() {
    this._email = this.actRoute.snapshot.paramMap.get('email');
    this._token = this.actRoute.snapshot.paramMap.get('token');

    this.validateToken();
  }

  validateToken() {
    this.isLoading = true;
    this.http
      .post('utilisateur/validateToken/registration', {
        data: {
          email: this._email,
          token: this._token,
        },
      })
      .subscribe((res: any) => {
        if (res.hasError) {
          this.tokenFound = false;
        } else {
          this.tokenFound = true;
          this.registerForm.controls.firstname.setValue(res.item.prenoms);
          this.registerForm.controls.lastname.setValue(res.item.nom);
          this.registerForm.controls.email.setValue(res.item.email);
          this.user = res.item;
          if (res.item.lang) {
            this.translate.use(res.item.lang);
          }
        }
        this.isLoading = false;
      });
  }

  endSubscription() {
    if (this.registerForm.controls.password.invalid) {
      this.toastr.error(this.translate.instant('MotDePasseFort'));
      return;
    }
    if (this.registerForm.controls.password.value !== this.registerForm.controls.confirm_password.value) {
      this.toastr.error(this.translate.instant('MotDePasseNeCorrespondentPas'));
      return;
    }
    this.isLoading = true;
    this.http
      .post('utilisateur/registration', {
        data: {
          lang: this.user.lang,
          nom: this.registerForm.controls.lastname.value,
          prenoms: this.registerForm.controls.firstname.value,
          email: this.registerForm.controls.email.value,
          mobile: this.registerForm.controls.telephone.value,
          password: this.registerForm.controls.password.value,
          mfa: this.registerForm.controls.email_authentication.value ? 'email' : 'none',
          token: this._token,
        },
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('InscriptionFin'), this.translate.instant('Succes'));
          this.onConnexion();
        } else {
          const errorMsg = res.status.message || this.translate.instant('ErreurSurvenue');
          this.toastr.error(errorMsg, this.translate.instant('Erreur'));
        }
        this.isLoading = false;
      });
  }

  onConnexion() {
    this.http
      .post('utilisateur/connexion', {
        data: {
          email: this.registerForm.controls.email.value,
          password: this.registerForm.controls.password.value,
        },
      })
      .subscribe(
        (res: any) => {
          this.auth.purgeSession();
          if (res?.item?.mfa != 'none') {
            return this.router.navigate([AUTH_LOGIN_MFA_ROUTE], {
              state: { email: this.registerForm.controls.email.value, token: res.item.token },
            });
          } else {
            return this.auth.loginSuccess(res.item);
          }
        },
        (err: any) => {
          this.toastr.error(this.translate.instant('LaConnexionAEchoue'));
        },
      );
  }

  /**
   * Getters
   */
  get lastname() {
    return this.registerForm.get('lastname');
  }
  get firstname() {
    return this.registerForm.get('firstname');
  }
  get email() {
    return this.registerForm.get('email');
  }
  get telephone() {
    return this.registerForm.get('telephone');
  }
  get password() {
    return this.registerForm.get('password');
  }
  get confirm_password() {
    return this.registerForm.get('confirm_password');
  }
  get email_authentication() {
    return this.registerForm.get('email_authentication');
  }
}
