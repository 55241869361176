import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdminOrganizationService } from '../../services/admin-organization.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-organization-selector',
  standalone: false,

  templateUrl: './admin-organization-selector.component.html',
  styleUrl: './admin-organization-selector.component.scss',
})
export class AdminOrganizationSelectorComponent {
  @Input() model: Organization;
  @Output() modelChange = new EventEmitter<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private adminOrganizationService: AdminOrganizationService) {}

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) => {
        return term.length < 2
          ? of([])
          : this.adminOrganizationService.getOrganizations$({ searchTerm: term }).pipe(
              catchError((error) => {
                console.error(error);
                return of({ content: [] as Organization[] });
              }), // empty list on error
              map((page) => page.content),
            );
      }),
    );
  };

  formatter = (organization: Organization) => organization.companyName + ' / ' + organization.code;
}

interface Organization {
  code: string;
  companyName: string;
}
