<div class="switch-container form-control">
  <span class="switch-label">{{ 'Désactivé' | translate }}</span>
  <div class="switch" (click)="onClick($event)">
    <input
      type="checkbox"
      [checked]="isChecked"
      (change)="onChanged($event)"
      (blur)="onBlur($event)"
      [disabled]="disabled"
    />
    <span class="slider round"></span>
  </div>
  <span class="switch-label">{{ 'Activé' | translate }}</span>
</div>
