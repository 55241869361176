import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TitleResolver implements Resolve<string> {
  constructor(private translate: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<string> {
    return Promise.resolve(
      this.translate.instant('Title') + (route.data?.title ? ' - ' + this.translate.instant(route.data?.title) : ''),
    );
  }
}
