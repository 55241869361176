export type MakeOptional<T, K extends keyof T> = {
  [P in K]?: T[P];
} & Pick<T, Exclude<keyof T, K>>;

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P];
};

export function isEnum<T extends { [s: string]: unknown }>(enumType: T, token: unknown): token is T[keyof T] {
  return Object.values(enumType).includes(token as T[keyof T]);
}

export type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V? P: never]: any
}
