<div class="row mb-2">
  <h5 class="col-auto">{{ 'Clusters' | translate }}</h5>
  <app-admin-cluster-selector [(model)]="addItem" [filters]="{ contractIsNull: true }" class="col" />
  <div class="col col-auto">
    <button class="btn btn-primary btn-sm" [disabled]="!addItem" (click)="add(addItem)">
      {{ 'Add' | translate }}
    </button>
  </div>
</div>

<div class="row">
  <div class="col">
    <ngx-datatable
      class="data-table table-responsive task-list-table"
      [rows]="data?.content"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="10"
      [rowHeight]="40"
    >
      <ngx-datatable-column [flexGrow]="2" name="name">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Nom' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.name }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="3" name="organization">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Organization' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.owner.companyName }}
          <app-badge-organization [code]="row.owner.code"></app-badge-organization>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [flexGrow]="3" name="user">
        <ng-template let-column="column" ngx-datatable-header-template>
          <p class="dataheader" [innerHtml]="'Utilisateur' | translate"></p>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.owner.owner.email }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [maxWidth]="40" name="actions" cellClass="actions">
        <ng-template let-column="column" ngx-datatable-header-template></ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <i
            title="{{ 'supprimer' | translate }}"
            (click)="remove(row)"
            style="color: #e74c3c"
            class="pointer icofont icofont-ui-delete"
          ></i>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
          <app-table-pager [data]="data" [page]="page" [changePage]="changePage"></app-table-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>
