import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import {
  EXTERNAL_RFC_5424_SYSLOG_LINK,
  STATIC_LINK_OGO_CA_CERT,
  STATIC_LINK_OGO_LOG_EXAMPLE,
} from 'app/app-routing.module';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-push-log',
  templateUrl: './push-log.component.html',
  styleUrls: ['./push-log.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class PushLogComponent implements OnInit {
  lang: string;

  STATIC_LINK_OGO_CA_CERT = STATIC_LINK_OGO_CA_CERT;
  STATIC_LINK_OGO_LOG_EXAMPLE = STATIC_LINK_OGO_LOG_EXAMPLE;
  EXTERNAL_RFC_5424_SYSLOG_LINK = EXTERNAL_RFC_5424_SYSLOG_LINK;

  TCP = ExportType.TCP;
  SEKOIA = ExportType.SEKOIA;
  SPLUNK = ExportType.SPLUNK;
  MICROSOFTSENTINEL = ExportType.MICROSOFTSENTINEL;
  SYSLOG = ExportType.SYSLOG;

  isLoading = true;
  statsError = false;
  stats = null;

  exportType = undefined;
  stepExportLog: Step;
  pushLogConf = {
    tcp: {
      host: null,
      port: null,
      sslEnabled: false,
    },
    sekoia: {
      intakeKey: null,
    },
    splunk: {
      url: null,
      hecToken: null,
      sslVerificationMode: 'full',
    },
    microsoftSentinel: {
      clientAppId: null,
      clientAppSecret: null,
      tenantId: null,
      dataCollectionEndpoint: null,
      dcrImmutableId: null,
      dcrStreamName: null,
    },
    syslog: {
      appName: null,
      host: null,
      port: null,
      protocol: null,
      sslEnabled: false,
    },
  };

  @ViewChild('profileForm') public editProfileForm: NgForm;

  constructor(
    private toastr: ToastrService,
    public auth: AuthService,
    private translate: TranslateService,
    private http: HttpClient,
  ) {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnInit() {
    if (this.auth.currentOrganization.pushLog?.configuration) {
      this.parseConf(this.auth.currentOrganization.pushLog);
      this.getStats(this.auth.currentOrganization.id);
      this.stepExportLog = Step.CONFIGURED;
    } else {
      this.stepExportLog = Step.NOT_CONFIGURED;
    }
  }

  async getStats(id) {
    this.statsError = false;
    this.isLoading = true;
    this.stats = null;
    let res: any = null;
    try {
      res = await this.http.get(`v2/organizations/${id}/push-log/stats`).toPromise();
    } catch (e) {
      this.statsError = true;
    }
    this.stats = res;
    this.isLoading = false;
  }

  async saveUpdatePushLogConf() {
    let data = {};
    switch (this.exportType) {
      case 'syslog':
        let { sslEnabled, ...rest } = this.pushLogConf.syslog;
        data = {
          type: 'syslog',
          configuration: JSON.stringify({
            ...rest,
            protocol: sslEnabled ? 'ssl-tcp' : 'tcp',
          }),
        };
        break;
      default:
        data = {
          type: this.exportType,
          configuration: JSON.stringify(this.pushLogConf[this.exportType]),
        };
        break;
    }

    await this.auth.setOrganizationPushLog(this.auth.currentOrganization.id, data);
    this.auth.currentOrganization.pushLog = data;
    this.toastr.success(this.translate.instant('OperationSuccess'));
    this.stepExportLog = Step.CONFIGURED;
    this.parseConf(this.auth.currentOrganization.pushLog);
  }

  async deletePushLogConf() {
    await this.auth.deleteOrganizationPushLog(this.auth.currentOrganization.id);
    this.toastr.success(this.translate.instant('OperationSuccess'));
    this.stepExportLog = Step.NOT_CONFIGURED;
    this.stats = null;
  }

  triggerDeletePushLogConf() {
    return Swal.fire({
      html: this.translate.instant('DeletePushLogAlert'),
      ...this.commonAlertOptions(),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.deletePushLogConf();
      }
    });
  }

  triggerModifyPushLogConf() {
    return Swal.fire({
      html: this.translate.instant('ModifyPushLogConf'),
      ...this.commonAlertOptions(),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.saveUpdatePushLogConf();
      }
    });
  }

  parseConf(configuration) {
    const c = JSON.parse(configuration.configuration);
    const t = configuration.type;
    // Order conf attributes alphabetically
    // Have to pass from a tmp variable, otherwise angular does not refresh
    let parsedConf = {};
    for (const key of Object.keys(c).sort()) {
      if (key == 'protocol' && t == 'syslog') {
        parsedConf['sslEnabled'] = c[key] == 'ssl-tcp';
        continue;
      }
      parsedConf[key] = c[key];
    }

    this.pushLogConf[t] = parsedConf;
  }

  selectLogExportType(type) {
    this.exportType = type;
    this.stepExportLog = Step.EDITING;
  }

  commonAlertOptions() {
    return {
      title: 'Confirmation',
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
    };
  }

  protected readonly Step = Step;
}

enum Step {
  CONFIGURED,
  NOT_CONFIGURED,
  EDITING,
}

enum ExportType {
  TCP = 'tcp',
  SEKOIA = 'sekoia',
  SPLUNK = 'splunk',
  MICROSOFTSENTINEL = 'microsoftSentinel',
  SYSLOG = 'syslog',
}
