import { Component, Input } from '@angular/core';

@Component({
  template: `<span class="label text-center {{ 'share-' + role }}">{{
    'ShareSite.' + role + '.label' | translate
  }}</span>`,
  styles: [
    'span { display: inline-block; min-width: 10ex; padding: 6px 0; }',
    '.share-VIEW {  background-color: #f39c12;}',
    '.share-ADMIN {  background-color: #2ecc71;}',
  ],
  selector: 'label-share-role',
  standalone: false,
})
export class LabelShareRoleComponent {
  @Input() role: string;
}
