import moment, { Moment, unitOfTime } from 'moment/moment';
import { RangePreset } from '../date-range-selector/calendar-data.service';

export class DateRange {
  constructor(
    readonly start: Moment,
    readonly end: Moment,
  ) {}

  duration(unit?: unitOfTime.Diff): moment.Duration {
    return moment.duration(this.end.diff(this.start, unit));
  }

  length(unit: unitOfTime.Diff): number {
    return this.end.diff(this.start, unit);
  }

  durationMs(): number {
    return this.end.diff(this.start);
  }

  toString() {
    return `${this.start?.toISOString()} -> ${this.end?.toISOString()}`;
  }
}

export class DynamicRange extends DateRange {
  constructor(
    readonly code: RangePreset,
    private readonly fStart: () => Moment,
    private readonly fEnd: () => Moment,
    readonly endIsNow: boolean,
  ) {
    super(fStart(), fEnd());
  }

  refresh(): DynamicRange {
    return new DynamicRange(this.code, this.fStart, this.fEnd, this.endIsNow);
  }

  toString() {
    return `${this.code}: ${this.start?.toISOString()} -> ${this.end?.toISOString()}`;
  }
}
