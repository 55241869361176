import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ASSETS_HTTP_CLIENT } from '../app.module';

@Injectable({
  providedIn: 'root',
})
export class WorldMapService {
  private worldMap: any;

  constructor(@Inject(ASSETS_HTTP_CLIENT) private http: HttpClient) {}

  async init() {
    if (!this.worldMap) {
      this.worldMap = await firstValueFrom(this.http.get('assets/extensions/world-map.json'));
    }
  }

  getMap(): any {
    return this.worldMap;
  }
}
