import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-site-tags',
  templateUrl: './site-tags.component.html',
  styleUrls: ['./site-tags.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class SiteTagsComponent {
  @Input('tags') tags: string[];
  @Output() selectedChange = new EventEmitter<string[]>();
  @Input('selected') selected = [];

  constructor() {}
}
