import { Component } from '@angular/core';
import { AdminContractService, CollectionApi } from 'app/services/admin-contract.service';
import { ContractSite } from 'app/services/entities/contract';
import { ContractCollectionComponent } from '../contract-collection.component';
import { Site } from '../../../../../shared/admin-site-selector/admin-site-selector.component';

@Component({
  selector: 'app-contract-sites',
  standalone: false,
  inputs: ['contractId'],
  templateUrl: './contract-sites.component.html',
  styleUrls: ['./contract-sites.component.scss', '../../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class ContractSitesComponent extends ContractCollectionComponent<Site, ContractSite> {
  constructor(admin: AdminContractService) {
    super(admin);
  }

  override getCollectionApi(): CollectionApi<ContractSite> {
    return this.admin.sites(this.contractId);
  }

  protected getItemId(item: ContractSite) {
    return item.name;
  }
}
