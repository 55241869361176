import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HostModeService } from '../../../shared/hostMode/hostMode.service';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from 'app/services/auth.service';
import { OrganizationSiteService } from '../../../services/organization-site.service';
import { Country, CountryService } from '../../../services/country.service';
import { Site } from '../../../services/entities/site';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-geoblock',
  templateUrl: './geoblock.component.html',
  styleUrls: [
    './geoblock.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../add-site.component.scss',
  ],
  standalone: false,
})
export class GeoblockComponent implements OnInit {
  @Input({ required: true }) site: Site;

  @ViewChild('country') country: ElementRef;
  @ViewChild('blockCountry') blockCountry: ElementRef;

  disabled = true;
  blockedCountries: Set<string> = new Set();
  countries: Country[] = [];
  provisioningInProgress: boolean = false;
  filterUnblocked: string;
  filterBlocked: string;
  lang: string;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
    private auth: AuthService,
    private hostModeService: HostModeService,
    private siteApi: OrganizationSiteService,
    private countryService: CountryService,
  ) {}

  async ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
    this.disabled = !this.hostModeService.isAdvanced(this.site.mode);
    this.loadCountries();
    this.getBlacklistedCountries();
  }

  async loadCountries() {
    await this.countryService.init();
    this.countries = this.countryService.getCountries();
  }

  getBlacklistedCountries() {
    this.siteApi.getBlacklistedCountries(this.site.name).subscribe({
      next: (blacklistedCountries) => {
        this.blockedCountries = new Set(blacklistedCountries);
      },
    });
  }

  blockAllCountries() {
    this.blockedCountries = new Set(this.countries.map((c) => c.code));
  }

  enableAllCountries() {
    this.blockedCountries = new Set();
  }

  blockAvailableCountry(countryCode: string) {
    this.blockedCountries.add(countryCode);
  }

  enableBlockCountry(countryCode: string) {
    this.blockedCountries.delete(countryCode);
  }

  createBlacklistedCountries() {
    if (this.disabled) {
      return this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
    }

    this.provisioningInProgress = true;

    this.siteApi
      .setBlacklistedCountries(this.site.name, [...this.blockedCountries])
      .pipe(
        finalize(() => {
          this.provisioningInProgress = false;
        }),
      )
      .subscribe({
        next: (res) => this.toastr.success(this.translate.instant('OperationSuccess')),
        error: (err) => this.toastr.error(this.translate.instant('OperationFailed')),
      });
  }
}
