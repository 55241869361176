<button [routerLink]="['../']" class="btn btn-round btn-primary btn-mini mb-3">
  < {{ 'BackToAllSites' | translate }}
</button>

<div *ngIf="mode" class="add-site-title">
  @switch (mode) {
    @case (EditMode.CREATE) {
      <span [innerHTML]="'NouveauSite' | translate"></span>
    }
    @case (EditMode.UPDATE) {
      <span [innerHTML]="'ConfigurationSite' | translate"></span>
      <ng-container *ngIf="site?.name">
        <span>&nbsp;/&nbsp;</span><span class="text-primary">{{ site.name }}</span>
      </ng-container>
    }
  }
  <hr />
</div>

@if (mode === EditMode.CREATE || site) {
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" [hidden]="mode != EditMode.UPDATE">
    <li id="{{ 'tab-' + Tab.GENERAL }}" [ngbNavItem]="Tab.GENERAL">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.GENERAL }">{{ 'General' | translate }}</button>
      <ng-template ngbNavContent>
        <app-general-config *ngIf="mode" [site]="mode == EditMode.UPDATE ? site : {}" [mode]="mode" />
      </ng-template>
    </li>

    <li id="{{ 'tab-' + Tab.CERTIFICATES }}" [ngbNavItem]="Tab.CERTIFICATES">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.CERTIFICATES }">
        {{ 'Certificat' | translate }}
        <i
          *ngIf="isACertificateInError"
          class="icon-warning icofont icofont-warning tab-warning"
          [ngbTooltip]="'CertificateError' | translate"
          placement="bottom"
        ></i>
      </button>
      <ng-template ngbNavContent>
        <app-certificates *ngIf="site" [site]="site" (isACertificateInError)="isACertificateInError = $event" />
      </ng-template>
    </li>

    <li id="{{ 'tab-' + Tab.EXCEPTIONS }}" [ngbNavItem]="Tab.EXCEPTIONS">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.EXCEPTIONS }">{{ 'Exceptions' | translate }}</button>
      <ng-template ngbNavContent>
        <app-exceptions *ngIf="site" [site]="site" />
      </ng-template>
    </li>

    <li id="{{ 'tab-' + Tab.GEO_BLOCKING }}" [ngbNavItem]="Tab.GEO_BLOCKING">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.GEO_BLOCKING }">
        {{ 'Geoblocking' | translate }}
      </button>
      <ng-template ngbNavContent>
        <app-geoblock *ngIf="site" [site]="site" />
      </ng-template>
    </li>

    <li id="{{ 'tab-' + Tab.ACCESS_CONTROL_RULES }}" [ngbNavItem]="Tab.ACCESS_CONTROL_RULES">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.ACCESS_CONTROL_RULES }">
        {{ 'AccessControl' | translate }}
      </button>
      <ng-template ngbNavContent>
        <app-access-control-rules
          *ngIf="site"
          [site]="site"
          [clusterSupportsCache]="cluster?.supportsCache"
          [cacheEnabled]="site.cacheEnabled"
        />
      </ng-template>
    </li>

    <li id="{{ 'tab-' + Tab.REWRITE_RULES }}" [ngbNavItem]="Tab.REWRITE_RULES">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.REWRITE_RULES }">{{ 'Rewrite' | translate }}</button>
      <ng-template ngbNavContent>
        <app-rewrite-rules *ngIf="site" [site]="site" />
      </ng-template>
    </li>

    <li id="{{ 'tab-' + Tab.CACHE }}" *ngIf="cluster?.supportsCache" [ngbNavItem]="Tab.CACHE">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.CACHE }">
        {{ 'Cache' | translate }}
        <label class="badge badge-sm bg-dark m-0 ml-2">{{ 'BETA' | translate }}</label>
      </button>
      <ng-template ngbNavContent>
        <app-cache *ngIf="site" [site]="site" [(cacheEnabled)]="site.cacheEnabled" />
      </ng-template>
    </li>

    <li id="{{ 'tab-' + Tab.EXPERT }}" [ngbNavItem]="Tab.EXPERT">
      <button ngbNavLink routerLink="." [queryParams]="{ tab: Tab.EXPERT }">{{ 'Expert' | translate }}</button>
      <ng-template ngbNavContent>
        <app-expert *ngIf="site" [site]="site" />
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
}
