import { Injectable } from '@angular/core';
import moment from 'moment';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DynamicRange } from '../utils/date-range';

export const DATE_NOW = () => moment();
export const DATE_LAST_15_MINUTES = () => moment().subtract(15, 'minutes');
export const DATE_LAST_HOUR = () => moment().subtract(1, 'hours');
export const DATE_LAST_4_HOURS = () => moment().subtract(4, 'hours');
export const DATE_TODAY = () => moment().startOf('day');
export const DATE_YESTERDAY_START = () => moment().subtract(1, 'days').startOf('day');
export const DATE_YESTERDAY_END = () => moment().subtract(1, 'days').endOf('day');
export const DATE_LAST_7_DAYS = () => moment().subtract(6, 'days');
export const DATE_LAST_30_DAYS = () => moment().subtract(29, 'days');
export const DATE_LAST_60_DAYS = () => moment().subtract(59, 'days');
export const DATE_LAST_90_DAYS = () => moment().subtract(89, 'days');
export const DATE_ALL = () => moment.unix(0).startOf('day');

export const RANGE_PRESETS: { [k in RangePreset]: DynamicRange } = _.keyBy(
  [
    new DynamicRange('15M', DATE_LAST_15_MINUTES, DATE_NOW, true),
    new DynamicRange('1H', DATE_LAST_HOUR, DATE_NOW, true),
    new DynamicRange('4H', DATE_LAST_4_HOURS, DATE_NOW, true),
    new DynamicRange('T', DATE_TODAY, DATE_NOW, true),
    new DynamicRange('Y', DATE_YESTERDAY_START, DATE_YESTERDAY_END, false),
    new DynamicRange('7d', DATE_LAST_7_DAYS, DATE_NOW, true),
    new DynamicRange('30d', DATE_LAST_30_DAYS, DATE_NOW, true),
    new DynamicRange('60d', DATE_LAST_60_DAYS, DATE_NOW, true),
    new DynamicRange('90d', DATE_LAST_90_DAYS, DATE_NOW, true),
    new DynamicRange('All', DATE_ALL, DATE_NOW, true),
  ],
  (r) => r.code,
) as { [k in RangePreset]: DynamicRange };

export type RangePreset = '15M' | '1H' | '4H' | 'T' | 'Y' | '7d' | '30d' | '60d' | '90d' | 'All';

@Injectable({
  providedIn: 'root',
})
export class CalendarDataService {
  constructor(private translate: TranslateService) {}

  getRangesTranslations(ranges: RangePreset[]) {
    return Object.fromEntries(
      ranges.map((r) => [
        r,
        {
          label: this.translate.instant('Calendar.range.' + r),
          start: RANGE_PRESETS[r].start,
          end: RANGE_PRESETS[r].end,
        },
      ]),
    );
  }

  getOptions() {
    return {
      format: this.translate.instant('Calendar.format'),
      separator: this.translate.instant('Calendar.separator'),
      applyLabel: this.translate.instant('Calendar.apply'),
      cancelLabel: this.translate.instant('Calendar.cancel'),
      fromLabel: this.translate.instant('Calendar.from'),
      toLabel: this.translate.instant('Calendar.to'),
      customRangeLabel: this.translate.instant('Calendar.edit'),
      weekLabel: 'S',
      daysOfWeek: this.translate.instant('Calendar.weekDays.short'),
      monthNames: this.translate.instant('Calendar.months'),
      firstDay: 1,
    };
  }
}
